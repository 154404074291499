/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { firebaseFunctions } from "../firebase";
import { DATE } from "../constants/dates";
import { Button } from "../components/buttons";
import { ALLOWED_CITIES } from "../constants/zipcodes";
import { DropdownFormControl } from "./forms";
import { Spacer1, Spacer2, Spacer3 } from "./spacers";
import { AdminJobsTable } from "./admin-job-table";

interface AdminJobsTabProps {
  isActiveTab: boolean;
}

export const AdminJobsTab: React.FC<AdminJobsTabProps> = ({ isActiveTab }) => {
  const [isLoading, setIsLoaging] = useState<boolean>(false);
  const [jobs, setJobs] = useState<any[]>();
  const [queryResult, setQueryResult] = useState<any>();
  const [fromDate, setFromDate] = useState<Date>(moment().startOf("month").toDate());
  const [toDate, setToDate] = useState<Date>(moment().endOf("month").toDate());
  const itemsPerPage = 20;
  const [page, setPage] = useState<number>(1);
  const [selectedJobArea, setSelectedJobArea] = useState<string>("All");

  const getJobsData = (startDate: Date, endDate: Date, jobArea: string = "", page: number = 1) => {
    setIsLoaging(true);
    const data = {
      startDate: moment(startDate).format(DATE),
      endDate: moment(endDate).format(DATE),
      jobArea: jobArea === "All" ? "" : jobArea.toLowerCase(),
      page,
      itemsPerPage
    };
    const jobsCall = firebaseFunctions.httpsCallable("jobsExport");
    jobsCall(data).then((result) => {
      setQueryResult(result.data);
      setJobs(result.data.rows);
    }).finally(() => setIsLoaging(false));
  };

  const onOperationCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event?.currentTarget;
    setSelectedJobArea(value);
    setPage(1);
    getJobsData(fromDate, toDate, value, 1);
  };

  const onPageChange = (selectedPage: number) => {
    setPage(selectedPage);
    getJobsData(fromDate, toDate, selectedJobArea, selectedPage);
  }

  const onFromDateChange = (date: Date) => {
    setFromDate(date);
    getJobsData(date, toDate, selectedJobArea, page);
  };

  const onToDateChange = (date: Date) => {
    setToDate(date);
    getJobsData(fromDate, date, selectedJobArea, page);
  };

  const exportData = () => {
    const header = [
      "Date",
      "Shift length",
      "Status",
      "Caregiver name",
      "Client name",
      "Client zipcode",
      "Job description",
      "Client phone",
      "Client email",
      "Caregiver email",
      "Job created",
      "Job ID",
      "Client report option",
      "Client report info",
      "Caregiver report option",
      "Caregiver report info",
      "Payment attempts",
      "Payment errors",
    ];

    const rows = [];
    rows.push(header);
    rows.push(...(jobs || []));
    const csv = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(";")).join("\n");

    let link = document.createElement("a");
    link.download = "jobs-export.csv";
    link.href = csv;
    link.click();
  };

  useEffect(() => {
    if (!jobs && isActiveTab) {
      getJobsData(fromDate, toDate, selectedJobArea, page);
    }
  }, [jobs, isActiveTab])

  return (
    <>
      <Spacer2 />
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex mt-3 align-items-center">
          <h4 className="m-0">From date</h4>
          <Spacer1 />
          <DatePicker
            selected={fromDate}
            // @ts-ignore
            onChange={(date) => date && onFromDateChange(date)}
            popperPlacement="bottom"
          />
          <div className="mr-3" />
          <h4 className="m-0">To date</h4>
          <Spacer1 />
          <DatePicker
            selected={toDate}
            // @ts-ignore
            onChange={(date) => date && onToDateChange(date)}
            popperPlacement="bottom"
          />
          <div className="mr-3" />
          <h4 className="m-0">Operation City</h4>
          <Spacer1 />
          <DropdownFormControl
            as="select"
            value={selectedJobArea}
            id="operationCity"
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onOperationCityChange(event)}
          >
            <option key={"all"}>All</option>
            {ALLOWED_CITIES.map((city) => (
              <option key={`city-${city}`}>{city}</option>
            ))}
          </DropdownFormControl>
        </div>
        <ButtonWrapper>
          <Button onClick={() => exportData()}>
            Download Shifts
          </Button>
        </ButtonWrapper>
      </div>

      <Spacer2 />
      <AdminJobsTable
        jobs={jobs || []}
        onPageChange={onPageChange}
        selectedPage={page}
        totalPages={queryResult?.total_pages}
        isLoading={isLoading}
      />
      <Spacer3 />
    </>
  );
};

const ButtonWrapper = styled.div`
  button {
    padding: 12px 20px;
    font-size: 16px;
  }
`;

