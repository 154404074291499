import firebase from "firebase/app";
// eslint-disable-next-line no-unused-vars
import { CareNeeds, PatientProfileDocument, Pets, UserDocument, UserTypes, Genders, PATIENT } from "../types/user";
import { firestore } from "../firebase";
import { PATIENTS_PROFILES_COLLECTION, USERS_COLLECTION } from "../constants/collections";
import faker from "faker";

export const convertTimestampToDate = (timestamp: any): Date | any => {
  return timestamp.seconds && timestamp.nanoseconds
    ? new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate()
    : timestamp;
};

export const getUserDocument = async (firebaseUser: firebase.User): Promise<UserDocument | null> => {
  if (!firebaseUser) return null;

  try {
    const userRef = firestore.doc(`${USERS_COLLECTION}/${firebaseUser.uid}`);
    const snapshot = await userRef.get();

    /*TODO
       This might be an issue. If a Practitioner logs in on the web, then a user document will be created as a PATIENT.
       If that email address is then added as a Practitioner, then we would need to change the user document and mark it as Practitioner
    */
    if (!snapshot.exists) {
      return await createUserDocument(userRef, firebaseUser);
    }

    return {
      firebaseUser,
      createdAt: snapshot.data()?.createdAt,
      type: snapshot.data()?.type,
      pages: snapshot.data()?.pages,
    };
  } catch (error) {
    console.error("Error fetching user document", error);
  }

  return null;
};

export const createUserDocument = async (
  userRef: firebase.firestore.DocumentReference,
  firebaseUser: firebase.User
): Promise<UserDocument | null> => {
  try {
    const createdAt = new Date();
    await userRef.set({
      createdAt,
      type: PATIENT,
    });

    return {
      firebaseUser,
      createdAt,
      type: PATIENT,
    };
  } catch (error) {
    console.error("Error creating user doc", error);
  }

  return null;
};

export const patientProfileFromSnapshot = (snapshot: firebase.firestore.DocumentSnapshot): PatientProfileDocument => {
  return {
    firstName: snapshot.data()?.firstName,
    lastName: snapshot.data()?.lastName,
    gender: snapshot.data()?.gender,
    phone: snapshot.data()?.phone,
    birthday: snapshot.data()?.birthday ? convertTimestampToDate(snapshot.data()?.birthday) : undefined,
    addressLine1: snapshot.data()?.addressLine1,
    addressLine2: snapshot.data()?.addressLine2,
    city: snapshot.data()?.city,
    state: snapshot.data()?.state,
    zip: snapshot.data()?.zip,
    careNeeds: snapshot.data()?.careNeeds,
    pets: snapshot.data()?.pets,
    bio: snapshot.data()?.bio,
    newApplicants: snapshot.data()?.newApplicants,
    stripeCustomerId: snapshot.data()?.stripeCustomerId,
    geoPoint: snapshot.data()?.geoPoint,
    ccReady: snapshot.data()?.ccReady || false,
    isAccountOwner: snapshot.data()?.isAccountOwner || false,
    ownerFirstName: snapshot.data()?.ownerFirstName,
    ownerLastName: snapshot.data()?.ownerLastName,
    ownerPhone: snapshot.data()?.ownerPhone,
    ownerEmail: snapshot.data()?.ownerEmail,
    ownerRelationship: snapshot.data()?.ownerRelationship,
  };
};

export const getPatientProfile = async (firebaseUser: firebase.User): Promise<PatientProfileDocument | null> => {
  const profileRef = firestore.doc(`${PATIENTS_PROFILES_COLLECTION}/${firebaseUser.uid}`);
  const snapshot = await profileRef.get();
  return patientProfileFromSnapshot(snapshot);
};

export const setPatientProfileDocument = async (
  firebaseUser: firebase.User,
  patientProfile: PatientProfileDocument
): Promise<void> => {
  const profileRef = firestore.doc(`${PATIENTS_PROFILES_COLLECTION}/${firebaseUser.uid}`);
  await profileRef.set(patientProfile, { merge: true });
};

export const createRandomPatientsProfile = async (
  profileRef: firebase.firestore.DocumentReference
): Promise<PatientProfileDocument> => {
  const totalCareNeeds = faker.random.number(6);
  const careNeedsData = Object.entries(CareNeeds);
  const careNeeds: string[] = [];
  for (let i = 0; i <= totalCareNeeds; i++) {
    careNeeds.push(careNeedsData[i][0]);
  }

  const totalPets = faker.random.number(2);
  const petsData = Object.entries(Pets);
  const pets: string[] = [];
  for (let i = 0; i <= totalPets; i++) {
    pets.push(petsData[i][0]);
  }

  const fakeGenderIndex = faker.random.number(2);
  const selectedGender: string[] = Object.values(Genders).filter((gender, index) => {
    return index === fakeGenderIndex;
  });

  const profile: PatientProfileDocument = {
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    gender: selectedGender[0],
    addressLine1: faker.address.streetAddress(),
    addressLine2: faker.address.secondaryAddress(),
    city: faker.address.city(),
    state: faker.address.state(),
    zip: faker.address.zipCode(),
    phone: faker.phone.phoneNumber(),
    birthday: faker.date.past(faker.random.number({ min: 20, max: 90 })),
    bio: faker.lorem.paragraphs(3),
    careNeeds,
    pets,
    newApplicants: 0,
  };

  try {
    await profileRef.set(profile);
  } catch (error) {
    console.error("Error creating patient profile", error);
  }

  return profile;
};
