import { Badge } from "react-bootstrap";
import React from "react";
import styled from "styled-components";

interface NotificationBadgeProps {
  count: number;
}

export const NotificationBadge: React.FC<NotificationBadgeProps> = ({ count }) => {
  return <CustomBadge>{count}</CustomBadge>;
};

const CustomBadge = styled(Badge)`
  background-color: ${(props) => props.theme.colors.red};
  border-radius: 50%;
`;
