// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, RefObject } from "react";
import { PhoneNumberUtil } from "google-libphonenumber";
import styled from "styled-components";
import { PATIENT_ACCOUNT_HISTORY_ROUTE, PATIENT_HOME_NO_PROFILE_ROUTE, PATIENT_HOME_ROUTE } from "../constants/routes";
import useRequireAuth from "../hooks/use-require-auth";
import { usePatientAuth } from "../hooks/use-patient-auth";
import { setPatientProfileDocument } from "../hooks/use-patient-auth-repo";
// eslint-disable-next-line no-unused-vars
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { FlexContainer, MainContentWrapper } from "../components/layout";
import { Form, FormLabel, Col, Row } from "react-bootstrap";
import TextFormGroup from "../components/text-form-group";
import PhoneFormGroup from "../components/phone-form-group";
import { MONTH_NUMBERS_ARRAY, DAY_NUMBERS_ARRAY, YEAR_NUMBERS_ARRAY, US_STATES_ARRAY } from "../constants/profile";
import { PageLoading, Loading } from "../components/loading";
import { Spacer2, Spacer4 } from "../components/spacers";
import { Button } from "../components/buttons";
import { DividerSpan, DropdownFormControl, TextAreaFormControl } from "../components/forms";
import { requiredStar, TabLabel } from "../components/text";
// eslint-disable-next-line no-unused-vars
import { Genders, PatientProfileDocument } from "../types/user";
// eslint-disable-next-line no-unused-vars
import { FormControlElement } from "../types/form";
import { Tab, TabsWrapper } from "../components/tabs";
import * as geofirex from "geofirex";
import firebase, { firebaseFunctions } from "../firebase";
import { MAX_TEXTAREA_LENGTH } from "../constants/constants";
import useScrollTop from "../hooks/use-scroll-to-top";
import { AccountOwnerProfile } from "./account-owner-profile";
import { ZIP_CODES } from "../constants/zipcodes";
const geo = geofirex.init(firebase);

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
const getFullPhoneNumber = (phoneParts: string[]) => `${phoneParts[0]}.${phoneParts[1]}.${phoneParts[2]}`;

export const validatePhoneNumber = (phoneNumberParts: string[]) => {
  const fullPhoneNumber = getFullPhoneNumber(phoneNumberParts);
  let usePhoneNumber;
  try {
    usePhoneNumber = phoneUtil.parse(fullPhoneNumber, "US");
  } catch (error) {
    console.error("Unable to parse phone", error);
  }
  return usePhoneNumber && phoneUtil.isValidNumberForRegion(usePhoneNumber, "US");
};

export const parsePhoneNumber = (phoneNumber: string) => {
  const usePhoneNumber = phoneUtil.parse(phoneNumber, "US");
  if (usePhoneNumber) {
    const nationalNumber = usePhoneNumber.getNationalNumber()?.toString();

    if (nationalNumber) {
      const _phoneParts = [];
      _phoneParts.push(nationalNumber.substring(0, 3));
      _phoneParts.push(nationalNumber.substring(3, 6));
      _phoneParts.push(nationalNumber.substring(6, 10));
      return _phoneParts;
    }
  }
  return [];
};

interface PatientProfileProps extends RouteComponentProps {
  nextRoute?: string;
}

const PatientProfile: React.FC<PatientProfileProps> = (props) => {
  const nextRoute = props.location.state;
  const requireAuth = useRequireAuth();
  const { user, profile } = usePatientAuth();
  const history = useHistory();
  useScrollTop();

  const [currentProfile, setCurrentProfile] = useState<PatientProfileDocument>({
    firstName: "",
    lastName: "",
    gender: Object.values(Genders)[0],
    birthday: new Date(),
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "OR",
    zip: "",
    careNeeds: [],
    pets: [],
    bio: "",
    newApplicants: 0,
    stripeCustomerId: null,
    isAccountOwner: false,
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    ownerRelationship: "",
    ownerPhone: "",
  });
  const [phoneParts, setPhoneParts] = useState<string[]>([]);
  const [isSavingProfile, setIsSavingProfile] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>();
  const [isBirthdayValid, setIsBirthdayValid] = useState<boolean>();
  const [fireStoreError, setFireStoreError] = useState<string | null>();
  const [isInvalidZipCode, setIsInvalidZipCode] = useState<boolean>(false);

  const birthdayMonth = currentProfile?.birthday ? currentProfile.birthday.getMonth() + 1 : "";
  const birthdayYear = currentProfile?.birthday ? currentProfile.birthday.getFullYear() : "";
  const birthdayDay = currentProfile?.birthday ? currentProfile.birthday.getDate() : "";

  const [isAccountOwner, setIsAccountOwner] = useState<boolean>(false);
  const [isOwnerPhoneValid, setIsOwnerPhoneValid] = useState<boolean>();
  const [ownerPhoneParts, setOwnerPhoneParts] = useState<string[]>();

  useEffect(() => {
    getZipCodeFromLocalStorage();
    if (profile && profile.phone && phoneParts.length === 0) {
      setPhoneParts(parsePhoneNumber(profile.phone));
      setCurrentProfile(profile);
      setIsAccountOwner(profile.isAccountOwner || false);

      if (profile.isAccountOwner) {
        setOwnerPhoneParts(parsePhoneNumber(profile.ownerPhone || ""));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleFieldChange = (
    field: string,
    event: React.ChangeEvent<FormControlElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let { value } = event.currentTarget;

    if (typeof value === "string") {
      value = value.substring(0, MAX_TEXTAREA_LENGTH);
    }

    if (currentProfile) {
      const nextProfile: PatientProfileDocument = { ...currentProfile, [field]: value };
      setCurrentProfile(nextProfile);
    }
  };

  // Validates and change the phone number
  const onChangePhone = (partIndex: number, event: React.ChangeEvent<FormControlElement>) => {
    const { value } = event.currentTarget;
    const nextPhoneParts = { ...phoneParts };
    nextPhoneParts[partIndex] = value;
    setIsPhoneValid(undefined);
    setPhoneParts(nextPhoneParts);
  };

  // Validates and change the birthday date
  const onChangeBirthday = (field: string, event: React.ChangeEvent<FormControlElement>) => {
    const { value } = event.currentTarget;
    if (currentProfile) {
      const nextProfile: PatientProfileDocument = { ...currentProfile };
      if (field === "year") {
        nextProfile.birthday.setFullYear(parseInt(value));
      } else if (field === "month") {
        nextProfile.birthday.setMonth(parseInt((Number(value) - 1).toString()));
      } else if (field === "day") {
        nextProfile.birthday.setDate(parseInt(value));
      }
      setCurrentProfile(nextProfile);

      setIsBirthdayValid(!isNaN(nextProfile.birthday.getTime()));
    }
  };

  const getGeoPoint = async (currentProfile: PatientProfileDocument) => {
    let geoPoint;
    const fullAddress = `${currentProfile.addressLine1} ${currentProfile.addressLine2} ${currentProfile.zip} ${currentProfile.city} ${currentProfile.state}`;

    try {
      const geoLocationCall = firebaseFunctions.httpsCallable("getGeoLocation");
      const location = await geoLocationCall({ fullAddress });

      console.log(location);

      if (location.data) {
        geoPoint = geo.point(location.data.lat, location.data.lng);
      }

      return geoPoint;
    } catch (error) {
      console.error("Unable to geocode address");
      return null;
    }
  };

  const onChangeZipCode = (event: React.ChangeEvent<FormControlElement>) => {
    const { value } = event.currentTarget;

    if (value.length === 5) {
      for (let city in ZIP_CODES) {
        if (ZIP_CODES[city].includes(parseInt(value))) {
          setIsInvalidZipCode(false);
          break;
        } else {
          setIsInvalidZipCode(true);
        }
      }
    }
    handleFieldChange("zip", event);
  };

  const getZipCodeFromLocalStorage = () => {
    if (user && !currentProfile.zip) {
      const lsData = localStorage.getItem(user.firebaseUser?.email || "");
      if (lsData) {
        const tempProfile = JSON.parse(lsData);
        setCurrentProfile({ ...currentProfile, zip: tempProfile.zip });
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();

    setIsSavingProfile(true);
    setFireStoreError(null);

    const isNewPhoneValid = validatePhoneNumber(phoneParts);
    setIsPhoneValid(isNewPhoneValid);

    // owner phone validation
    let isNewOwnerPhoneValid;
    if (currentProfile.isAccountOwner && ownerPhoneParts) {
      isNewOwnerPhoneValid = validatePhoneNumber(ownerPhoneParts || []);
      setIsOwnerPhoneValid(isNewOwnerPhoneValid);
      currentProfile.ownerPhone = getFullPhoneNumber(ownerPhoneParts);
    }

    const form = event.target;
    if (isNewPhoneValid && form.checkValidity()) {
      if (!currentProfile) return;

      currentProfile.phone = getFullPhoneNumber(phoneParts);
      const geoPoint = await getGeoPoint(currentProfile);
      currentProfile.geoPoint = geoPoint || null;

      if (currentProfile.isAccountOwner && !isNewOwnerPhoneValid) {
        formInputRefs["ownerPhone"]?.current?.focus();
        return;
      }

      await saveProfile(currentProfile);
    } else if (!isNewPhoneValid) {
      formInputRefs["phoneNumberInput1"]?.current?.focus();
    } else {
      const firstInvalidInputId = form.querySelector("input.form-control:invalid").id;
      if (firstInvalidInputId) {
        formInputRefs[firstInvalidInputId]?.current?.focus();
        formSelectRefs[firstInvalidInputId]?.current?.focus();
      }
    }

    setIsSavingProfile(false);
    setValidated(true);
  };

  const saveProfile = async (profile: PatientProfileDocument) => {
    if (!user || !user.firebaseUser || !currentProfile) {
      console.error("Missing logged in user session or profile data");
      return;
    }

    try {
      await setPatientProfileDocument(user.firebaseUser, profile).then(() => {
        if (nextRoute) {
          window.location.href = PATIENT_HOME_NO_PROFILE_ROUTE;
        } else {
          history.push(PATIENT_HOME_ROUTE);
        }
      });
    } catch (error) {
      setFireStoreError(`Error saving profile ${(error as any).getMessage()}`);
    }
  };

  if (!profile || !requireAuth.user)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  const formInputRefs: { [key: string]: RefObject<HTMLInputElement> } = {
    firstName: React.createRef(),
    lastName: React.createRef(),
    phoneNumberInput1: React.createRef(),
    birthdayMonth: React.createRef(),
    birthdayDay: React.createRef(),
    birthdayYear: React.createRef(),
    addressLine1: React.createRef(),
    addressLine2: React.createRef(),
    city: React.createRef(),
    addressZip: React.createRef(),
    isAccountOwner: React.createRef(),
    ownerFirstName: React.createRef(),
    ownerLastName: React.createRef(),
    ownerPhoneNumber: React.createRef(),
    ownerEmail: React.createRef(),
    ownerRelationship: React.createRef(),
  };

  const formSelectRefs: { [key: string]: RefObject<HTMLSelectElement> } = {
    birthdayMonth: React.createRef(),
    birthdayDay: React.createRef(),
    birthdayYear: React.createRef(),
    state: React.createRef(),
  };

  return (
    <MainContentWrapper>
      <Spacer4 />
      <TabsWrapper>
        <Tab active={true}>
          <TabLabel>Profile</TabLabel>
        </Tab>
        <Tab active={false} onClick={() => history.push(PATIENT_ACCOUNT_HISTORY_ROUTE)}>
          <TabLabel>Account</TabLabel>
        </Tab>
      </TabsWrapper>
      <Spacer2 />
      <FormWrapper>
        <ProfileForm noValidate validated={validated} onSubmit={handleSubmit}>
          <AccountOwnerProfile
            currentProfile={currentProfile}
            handleFieldChange={handleFieldChange}
            formRefs={formInputRefs}
            isPhoneValid={isOwnerPhoneValid}
            setOwnerPhoneParts={setOwnerPhoneParts}
            isAccountOwner={isAccountOwner}
            setIsAccountOwner={setIsAccountOwner}
            phoneParts={ownerPhoneParts || []}
          />
          <Spacer4 />
          <h1>Care Recipient</h1>
          <Spacer4 />
          <Form.Row>
            <TextFormGroup
              md="5"
              field="firstName"
              ref={formInputRefs["firstName"]}
              value={currentProfile?.firstName}
              required={true}
              labelHtml="First Name"
              ariaLabel="First Name"
              feedbackHtml={"Please provide a valid first name."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("firstName", event)}
            />
            <TextFormGroup
              md="5"
              field="lastName"
              ref={formInputRefs["lastName"]}
              value={currentProfile?.lastName}
              required={true}
              labelHtml="Last Name"
              ariaLabel="Last Name"
              feedbackHtml={"Please provide a valid last name."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("lastName", event)}
            />
            <Form.Group as={Col} md="2">
              <FormLabel htmlFor="gender">Gender{requiredStar}</FormLabel>
              <DropdownFormControl
                as="select"
                value={currentProfile?.gender}
                required
                onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("gender", event)}
                aria-label="Gender"
              >
                {Object.values(Genders).map((gender) => (
                  <option key={`gender-${gender}`}>{gender}</option>
                ))}
              </DropdownFormControl>
              <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <PhoneFormGroup
              ref={formInputRefs["phoneNumberInput1"]}
              onChangePhone={onChangePhone}
              phoneParts={phoneParts}
              isPhoneValid={isPhoneValid}
            />
            <Form.Group as={Col} md="6">
              <FormLabel htmlFor="birthday">Birthday{requiredStar}</FormLabel>
              <Row>
                <Col style={{ padding: 0 }}>
                  <DropdownFormControl
                    as="select"
                    required
                    value={birthdayMonth}
                    id="birthdayMonth"
                    ref={formSelectRefs["birthdayMonth"]}
                    onChange={(event: React.ChangeEvent<FormControlElement>) => onChangeBirthday("month", event)}
                  >
                    {MONTH_NUMBERS_ARRAY.map((month) => (
                      <option key={`bd-month-${month}`}>{month}</option>
                    ))}
                  </DropdownFormControl>
                  <DividerSpan> / </DividerSpan>
                  <DropdownFormControl
                    as="select"
                    required
                    value={birthdayDay}
                    id="birthdayDay"
                    ref={formSelectRefs["birthdayDay"]}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChangeBirthday("day", event)}
                  >
                    {DAY_NUMBERS_ARRAY.map((day) => (
                      <option key={`bd-day-${day}`}>{day}</option>
                    ))}
                  </DropdownFormControl>
                  <DividerSpan> / </DividerSpan>
                  <DropdownFormControl
                    as="select"
                    required
                    value={birthdayYear}
                    id="birthdayYear"
                    ref={formSelectRefs["birthdayYear"]}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChangeBirthday("year", event)}
                  >
                    {YEAR_NUMBERS_ARRAY.map((year) => (
                      <option key={`bd-year-${year}`}>{year}</option>
                    ))}
                  </DropdownFormControl>
                </Col>
              </Row>
              {isBirthdayValid === false && (
                <div className="invalid-feedback d-block">Please provide a valid birthday date.</div>
              )}
            </Form.Group>
          </Form.Row>
          <Spacer2 />
          <Form.Row>
            <TextFormGroup
              md="12"
              field="addressLine1"
              ref={formInputRefs["addressLine1"]}
              value={currentProfile?.addressLine1}
              required={true}
              labelHtml="Address"
              ariaLabel="Address"
              feedbackHtml={"Please provide a valid address."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("addressLine1", event)}
            />
            <TextFormGroup
              md="12"
              field="addressLine2"
              ref={formInputRefs["addressLine2"]}
              value={currentProfile?.addressLine2}
              required={false}
              labelHtml="Address Line 2"
              ariaLabel="Address Line 2"
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("addressLine2", event)}
            />
          </Form.Row>
          <Form.Row>
            <TextFormGroup
              md="6"
              field="city"
              ref={formInputRefs["city"]}
              labelHtml="City"
              value={currentProfile?.city}
              required={true}
              minLength={3}
              feedbackHtml="Please provide a valid city."
              ariaLabel="City"
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("city", event)}
            />
            <Form.Group as={Col} md="2">
              <FormLabel htmlFor="state">State{requiredStar}</FormLabel>
              <DropdownFormControl
                style={{ width: "100%" }}
                as="select"
                value={currentProfile?.state}
                id="state"
                ref={formSelectRefs["state"]}
                required
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleFieldChange("state", event)}
              >
                {US_STATES_ARRAY.map((state) => (
                  <option key={`state-${state}`}>{state}</option>
                ))}
              </DropdownFormControl>
              <Form.Control.Feedback type="invalid">Please provide a valid state.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <TextFormGroup
                md="12"
                field="addressZip"
                ref={formInputRefs["addressZip"]}
                labelHtml="ZIP Code"
                value={currentProfile?.zip}
                required={true}
                minLength={5}
                maxLength={5}
                feedbackHtml="Please provide a valid zip."
                ariaLabel="Zip Code"
                onChange={(event: React.ChangeEvent<FormControlElement>) => onChangeZipCode(event)}
              />
              {isInvalidZipCode && (
                <div className="invalid-feedback d-block pl-3 pr-3">
                  Invalid zip code. Zip codes for Portland, Eugene, and Medford are allowed.
                </div>
              )}
            </Form.Group>
          </Form.Row>

          {/*<Spacer3 />*/}
          {/*<Container className="text-center">*/}
          {/*  <h3>Typical Care Needs:</h3>*/}
          {/*  <small className="font-italic">Select all that apply</small>*/}
          {/*</Container>*/}

          {/*<CareNeedsWrapper>*/}
          {/*  <CheckboxFormGroup*/}
          {/*    field="careNeeds"*/}
          {/*    optionsValueArray={Object.keys(CareNeeds)}*/}
          {/*    optionsLabelArray={Object.values(CareNeeds)}*/}
          {/*    valuesArray={currentProfile?.careNeeds}*/}
          {/*    onClick={handleCheckboxChange}*/}
          {/*  />*/}
          {/*</CareNeedsWrapper>*/}

          {/*<Spacer3 />*/}
          {/*<h3 className="text-center">Pets:</h3>*/}

          {/*<CheckboxFormGroup*/}
          {/*  field="pets"*/}
          {/*  optionsValueArray={Object.keys(Pets)}*/}
          {/*  optionsLabelArray={Object.values(Pets)}*/}
          {/*  valuesArray={currentProfile?.pets}*/}
          {/*  onClick={handleCheckboxChange}*/}
          {/*/>*/}

          <Spacer2 />

          <Form.Row>
            <Form.Group as={Col} md="12">
              <FormLabel htmlFor="bio">About the Care Recipient</FormLabel>
              <TextAreaFormControl
                as="textarea"
                rows={6}
                value={currentProfile?.bio}
                onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("bio", event)}
                placeholder="Enter a brief bio ... Please, don’t include sensitive data here, like SSN, Credit Card number."
              />
            </Form.Group>
          </Form.Row>

          <Spacer4 />

          <Row className="justify-content-md-center">
            {isSavingProfile ? (
              <Button onClick={(e) => e.preventDefault()}>
                <span>Update</span>
                {isSavingProfile && (
                  <LoadingWrapper>
                    <Loading />
                  </LoadingWrapper>
                )}
              </Button>
            ) : (
              <Button type="submit">
                <span>Update</span>
              </Button>
            )}
            {fireStoreError && <div className="invalid-feedback d-block">{fireStoreError}</div>}
          </Row>
          <Spacer2 />
          <Row className="justify-content-md-center">
            <Link to={PATIENT_HOME_ROUTE}>Cancel</Link>
          </Row>
          <Spacer4 />
        </ProfileForm>
      </FormWrapper>
    </MainContentWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;

  div {
    width: 20px;
    height: 20px;
  }
`;

const FormWrapper = styled(FlexContainer).attrs((props) => ({
  className: `p-4`,
}))`
  background-color: white;
  align-items: center;
  flex-direction: column;
`;

const ProfileForm = styled(Form)`
  width: 90%;
  h1 {
    text-align: center;
  }
`;

// const CareNeedsWrapper = styled.div`
//   .form-group {
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     flex-wrap: wrap;
//   }
// `;

export default PatientProfile;
