import styled from "styled-components";
import React from "react";
import { FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { theme } from "./theme";

interface DateWrapperProps {
  width?: string;
  margin?: string;
  display?: string;
}

export const DateWrapper = styled.div<DateWrapperProps>`
  display: ${(props) => props.display || "flex"};
  width: ${(props) => props.width || "350px"};
  margin: ${(props) => props.margin || "0 auto"};

  .react-datepicker-wrapper {
    border: 1.5px solid ${(props) => props.theme.colors.alto};
    border-radius: 8px;
    padding: 0 4px 0 8px;
    max-width: 150px;
    margin: 0 8px;
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
  }

  input {
    margin-top: 4px;
  }

  input::placeholder {
    color: ${(props) => props.theme.colors.silver};
    font-size: 18px;
    font-weight: normal;
  }

  svg {
    height: 26px;
    width: 26px !important;
    margin-right: 2px;
  }

  & * {
    outline: 0;
  }
`;

export const DatePickerDateCustom = React.forwardRef((value: any, onClick: any) => {
  return (
    <>
      <FormControl
        onClick={value.onClick}
        onChange={() => {}}
        value={value.value}
        placeholder={"Date"}
        plaintext={true}
      />
      <FontAwesomeIcon icon={faCalendarAlt} color={theme.colors.silver} />
    </>
  );
});
