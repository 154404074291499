import React, {useState} from "react";
import {Pagination, Table} from "react-bootstrap";
import styled from "styled-components";
import {PageLoading} from "./loading";
import {theme} from "./theme";
import {Button} from "./buttons";
import AdminMessagesModal from "./admin-messages-modal";

interface AdminJobsTableProps {
  jobs: any[];
  totalPages: number;
  selectedPage: number;
  onPageChange: (page: number) => void;
  isLoading: boolean;
}

export const AdminJobsTable: React.FC<AdminJobsTableProps> = ({
  jobs,
  totalPages,
  onPageChange,
  selectedPage,
  isLoading,
}) => {
  const [isMessagesModalVisible, setIsMessagesModalVisible] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string>("");
  const paginationItems = Array.from(Array(totalPages)).map((i, index) => {
    return (
      <Pagination.Item
        key={index}
        style={{ zIndex: 0 }}
        onClick={() => onPageChange(index + 1)}
        active={selectedPage === index + 1}
      >
        {index + 1}
      </Pagination.Item>
    );
  });
  return (
    <>
      <AdminMessagesModal
        isVisible={isMessagesModalVisible}
        onConfirm={() => {}}
        onCancel={() => setIsMessagesModalVisible(false)}
        jobId={jobId}
      />
      <Pagination>{paginationItems}</Pagination>
      {isLoading ? (
        <PageLoading />
      ) : (
        <TableWrapper>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Action</th>
                <th>Date</th>
                <th>Shift length</th>
                <th>Status</th>
                <th>Caregiver name</th>
                <th>Client name</th>
                <th>Client zipcode</th>
                <th>Job description</th>
                <th>Client phone</th>
                <th>Client email</th>
                <th>Caregiver email</th>
                <th>Job created</th>
                <th>Job ID</th>
                <th>Client report option</th>
                <th>Client report info</th>
                <th>Caregiver report option</th>
                <th>Caregiver report info</th>
                <th>Payment attempts</th>
                <th>Payment errors</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job: any, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <ActionButtonWrapper color={theme.colors.jellyBean}>
                        <Button
                          onClick={() => {
                            setJobId(job[11]);
                            setIsMessagesModalVisible(true);
                          }}
                        >
                          Messages
                        </Button>
                      </ActionButtonWrapper>
                    </td>
                    {job.map((value: any, index: number) => (
                      <TableDataWrapper key={index}>{value}</TableDataWrapper>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
      )}
    </>
  );
};

const TableWrapper = styled.div`
  font-size: 14px;
  overflow-y: scroll;

  table {
    width: 3000px;
  }
`;

const TableDataWrapper = styled.td`
  min-width: 100px;
  overflow: hidden;
`;

const ActionButtonWrapper = styled.div`
  button {
    background-color: ${(props) => props.color};
    padding: 6px 10px;
    font-size: 14px;
  }
`;
