import React from "react";
import { H1, H2 } from "../components/text";
import { MainContentWrapper } from "../components/layout";
import { Spacer3, Spacer5 } from "../components/spacers";
import styled from "styled-components";
import useScrollTop from "../hooks/use-scroll-to-top";
import { useLocation } from "react-router-dom";

const Disclosure: React.FC = () => {
  useScrollTop();
  const location = useLocation();
  const appMode = location.pathname === "/disclosure_app";

  const content = (
    <>
      <H1>DISCLOSURE STATEMENT</H1>
      <Spacer3 />
      <TextWrapper>
        <H2>Services Provided</H2>
        <p>
          The services provided by CaregiverGO & Cassiel, Inc., include the safe provision of, or assistance with,
          personal care tasks related to one or more of the following: Companionship, Meal Prep, Housekeeping/Laundry,
          Errands/Appointments, Walking Assistance, Dressing/Grooming, Fall/Safety Monitoring
        </p>
        <p>
          It is not within the scope of the agency's license to manage the medical and health conditions of clients
          should they become unstable or unpredictable. Client/Care Recipient will be notified of the need for referral
          for medical or health services should such conditions arise.
        </p>
        <p>
          <b>
            Note: An agency must not accept or retain a client for services who requires special tasks of nursing care
            unless the agency or Client/Care Recipient contracts with nursing staff.
          </b>
        </p>
        <H2>Caregiver Qualifications</H2>
        <p>
          <ol>
            <li>Must be at least eighteen years old and have a minimum of high school education or GED.</li>
            <li>
              Must display good emotional health and be able to perform basic physical actions required for caregiving
              such as standing, bending, stooping, etc.
            </li>
            <li>Must be able to read, follow written instructions and document care activities</li>
            <li>
              Must possess a valid Oregon Driver’s license and carry a current auto insurance policy with adequate
              coverage*
            </li>
            <li>
              Must meet all agency requirements including but, not limited to: passing a thorough criminal background
              check, social security number verification and possess an acceptable DMV record.
            </li>
            <li>
              Should demonstrate flexibility in acceptance of assignments and a cooperative attitude toward providing
              services.
            </li>
          </ol>
          <p>
            <i>
              *To drive with a client in your vehicle or to drive a clients vehicle you must have valid license and
              insurance.
            </i>
          </p>
        </p>
        <H2>Service Fees</H2>
        <p>
          <table>
            <tr>
              <th>Hours</th>
              <th>Rates</th>
            </tr>
            <tr className="hours">
              <td>1</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>2</td>
              <td>$60</td>
            </tr>
            <tr>
              <td>3</td>
              <td>$80</td>
            </tr>
          </table>
        </p>
        <H2>Billing Method and Payment</H2>
        <p>
          Service billing is processed following each shift. The credit card listed on site will be charged
          appropriately. Additional care provided will be added based on terms of service agreement if applicable.
        </p>
        <p>
          <b>Payments</b> will be collected via Credit Card through the CaregiverGO App.
        </p>
        <p>
          <b>Refunds & Payment Adjustments:</b> Variations in service will be adjusted on the credit card on file within
          72 hours of being confirmed. Credits and additional charges will be applied via the CaregiverGO App.
        </p>
        <p>
          <b>Fee Changes -</b> The agency will provide 30 days advance written notice of agency wide fee increases.
          Agency charges will generally increase as your personal care needs and/or services/ preferences increase.
          Please thoroughly review/understand policies for charges and changes.
        </p>
        <H2>Termination of Services</H2>
        <p>
          Shifts cancelled within 24 hours of the scheduled start time will be charged in full. If the Caregiver is
          forced to cancel the shift at any point care will be billed for the hours of service provided rounded to the
          lower of a whole hour.
        </p>
        <p>
          <b>
            NOTE: The agency may need to report unsafe conditions to the appropriate agency under its abuse and neglect
            reporting requirements.
          </b>
        </p>
        <H2>CaregiverGO Disclosure Statement</H2>
        <p>
          The purpose of this Disclosure Statement is to comply with Oregon Department of Human Services licensing rules
          and provide you with information to assist you in understanding and comparing in-home care agency services.
        </p>
        <p>
          <b>In Home Care Agency Information: Revision Date: January 18, 2021</b>
          <br />
          Agency Name: Cassiel, Inc. dba CaregiverGO
          <br />
          Address: 2365 Cedar Hills Blvd. Portland, OR 97225 <br />
          Telephone Number: 503-297-7000 (24 hours) <br />
          Office hours: 8:00 AM – 5:30 PM, Daily (7 days/week) <br />
          Agency Director’s Name: Ronn Clark <br />
          Agency Owners: Ed Emberlin, Michael Jamieson
        </p>
        <p>
          Our Non-Medical Home Care Agency is designed to supplement the services of your primary caregiver(s) with
          respect to companionship and assistance for the elderly, or for anyone else who is afflicted with one or more
          faculty impairments. We provide assistance to you and the extended family in your routine daily needs.
          Services typically can include: light housekeeping, errands, shopping, bathing, grooming, meal preparation, or
          other companionship services.
        </p>
        <p>
          We are not a medical organization. We do not provide any service defined as medical by the State of Oregon.
          The medical or professional qualifications of any of our representatives or assigned caregivers, is strictly
          incidental to their activities as our employees and/or representatives. We make no recommendations concerning
          diagnosis, prognosis, treatment, medication, dosage, prescriptions, or other medical or health related
          services.
        </p>
        <p>
          Further, we are not licensed dietitians or chefs. At your direction, we shall cook meals on site or do
          preparatory work as is necessary in our capacity as a homemaker, companion, and helper. We require primary
          instructions concerning this service.
        </p>
        <p>
          In certain cases, we understand that care recipients may have their mobility or other faculties severely
          impaired. We rely on your instructions regarding all limitations of this nature. Competent medical personnel
          should be consulted before instructing us to act. We shall take all necessary precautions to operate within
          the guidelines established for us.
        </p>
        <p>
          Our relationship is based on mutual good faith. You are representing to us that requisite knowledge and
          authority exists to instruct us as to the needs of the care recipient. We shall make our continuing best
          efforts to meet those needs.
        </p>
        <p>
          Most of our care recipients are elderly and increasingly susceptible to illness and injury. Although we cannot
          prevent this, we will put forth our best efforts to provide the assistance and companionship that can make
          life more comfortable and fulfilling for the care recipient.
        </p>
        <p>
          In good faith, you, individually, on behalf of the family and the care recipient, release CaregiverGO from
          responsibility for any and all injuries and illnesses, which may come to the care recipient in the course of
          providing services, excluding neglect, where a reasonable effort has been made by CaregiverGO for the care
          recipient’s safety and health. You agree to maintain homeowners insurance, medical insurance, and/or other
          insurance as many be necessary to provide protection for the care recipient.
        </p>
        <p>
          The Client/Family agrees to secure all cash, jewelry, credit cards, identifying information, and other
          valuables of which potential loss or damage is of concern to the Client and/or Family. Furthermore, the Client
          agrees that any claims made under CaregiverGO fidelity bond must be made in writing by the Client / Client
          Representative within five days of discovery of loss or damage under the terms of the fidelity bond. Agency
          agrees to conduct a good faith investigation into any alleged theft or damage of client's personal property,
          to share information on progress of the investigation, conclusions reached and any actions taken with client
          and appropriate agencies. In addition you agree to maintain insurance coverage for any such items under a
          homeowner's insurance policy.
        </p>
        <p>
          CaregiverGO's caregivers are not qualified to provide any medical services to the Client. In the event of an
          emergency CaregiverGO's caregivers may call 911; we then will make every reasonable effort to contact you, the
          designated person in charge, or next of kin.
        </p>
        <p>
          This disclosure is incorporated by reference into other agreements CaregiverGO may have with the care
          recipient(s), their families, next of kin, or guardian, when such agreements are in effect. When we do not
          enter into an agreement, or when an agreement is awaiting the outcome of a preliminary trial period, this
          disclosure, filled out and signed by you, stipulates that you have been informed as to our limitations.
        </p>
        <H2>Client/Care Recipient Bill of Rights</H2>
        <p>
          <ol>
            <li>The right to be treated with dignity and respect;</li>
            <li>The right to be free from theft, damage, or misuse of one’s personal property;</li>
            <li>
              The right to be given the informed choice and opportunity to select or refuse service and to accept
              responsibility for the consequences;
            </li>
            <li>The right to be free from neglect of care, verbal, mental, emotional, physical, and sexual abuse;</li>
            <li>The right to be free from financial exploitation;</li>
            <li>The right to be free from physical and chemical restraints;</li>
            <li>
              The right to voice grievances or complaints regarding services or any other issue without discrimination
              or reprisal for exercising such rights;
            </li>
            <li>
              The right to be free from discrimination in regard to race, color, national origin, gender, sexual
              orientation, or religion.
            </li>
            <li>
              The right to participate in planning of the services and care to be furnished, any changes in the services
              and care, the frequency of visits, and cessation of services;
            </li>
            <li>The right to have access to his or her client record;</li>
            <li>The right to have client information and records confidentially maintained by the agency;</li>
            <li>
              The right to be advised in writing, before care is initiated, of the charges for the services to be
              furnished, and the amount of payment that will be required from the client;
            </li>
            <li>
              The right to a written 30-day notice of termination of services by the agency that specifies the reason(s)
              for the termination with the following exceptions:
              <ol type="I">
                <li>
                  The right to immediate oral or written notice of termination of services by the agency at the time the
                  agency determines that the safety of its staff or the client cannot be insured. If oral notice is
                  given, the agency must also subsequently provide the client a written confirmation of the oral notice
                  of termination of services.
                </li>
                <li>
                  The right to a written 48-hour notice of termination of services by the agency in the event of
                  non-payment in accordance with the agency’s disclosed payment requirements.
                </li>
              </ol>
            </li>
          </ol>
        </p>
        <H2>Complaint and Grievance Procedures</H2>
        <p>
          You may file a complaint or grievance with the in home care agency and/or or the licensing agency at the
          following addresses and phones.
        </p>
        <p>
          <ol>
            <li>
              <b>In Home Care Agency Information</b>
              <br />
              <b>Agency Name:</b> CaregiverGO, Cassiel, Inc. <br />
              <b>Address:</b> 2365 Cedar Hills Blvd. Portland, OR 97225 <br />
              <b>Telephone Number:</b> (503) 297-7000 (24 hours) <br />
              <b>Business Office Hours:</b> 8:00 AM – 5:30 PM, Daily (7 days/wk) <br />
              <b>Agency Administrator’s Name:</b> Ronn Clark <br />
              <b>Agency Owners:</b> Ed Emberlin, Michael Jamieson <br />
              <br />
            </li>
            <li>
              <b>Oregon Health Authority</b> <br />
              <b>Contact: Health Care Regulation & Quality Improvement</b> <br />
              <b>Address:</b> 800 NE Oregon Street, Suite 305 Portland OR 97232 <br />
              <b>Telephone Number:</b> (971) 673-0540 <br />
              <b>Fax Number:</b> (971) 673-0556 <br />
              <b>Office Hours:</b> Monday through Friday 8:00 AM to 4:30 PM <br />
              <i>The licensing agency will conduct a complaint investigation if indicated.</i>
            </li>
          </ol>
        </p>
        <H2>Filing a Complaint</H2>
        <p>
          Complaints may be filed verbally by phone or in person with the CaregiverGO office or Oregon Health Authority,
          HCRQI Department. A complaint form will be completed and a copy provided to the Client/Care Recipient for
          complaints filed with the CaregiverGO office. A written report may also be filed at the CaregiverGO or Oregon
          Health Authority Licensing Section; addresses listed above.
        </p>
        <p>
          <b>
            Notice: The Oregon Health Authority has the authority to examine your records, including those containing
            confidential medical information, as part of the Department’s evaluation of the agency. Photo copies of the
            records shall be made upon request.
          </b>
        </p>
        <H2>Light Housekeeping Defined</H2>
        <p>
          <b style={{ color: "red" }}>The Caregiver does not provide a general housekeeping service.</b> Typical "light"
          housekeeping tasks to be provided by the Caregiver employee would include: tidying up rooms in which the care
          recipient spends his/her time (bedroom, living room, kitchen), washing dishes after meals, wiping spills on
          sink or floor, "spot mopping", sweeping kitchen floor when needed, passing the vacuum in rooms used by care
          recipient, occasional light dusting, tidying bathrooms after use by Care Recipient, and rinsing tub or shower
          after use. It is recommended that an independent cleaning service be hired for tasks such as: scrubbing floors
          in kitchen and bathrooms, moving furniture, heavy lifting, window or mirror washing, dusting behind and under
          furniture, drape cleaning and heavy laundry.
        </p>
        <H2>Safety</H2>
        <p>
          For the safety of the care recipient, their families, and the caregiver, the following requirements apply:
        </p>
        <p>
          <ul>
            <li>Any loose carpeting on hardwood, tile or vinyl flooring should be addressed.</li>
            <li>
              Any exposed and/or questionable wiring such as worn out extension / lamp cords for any lights and/or
              appliances should be removed.
            </li>
            <li>Any flammable materials exposed to the elements should be properly stored.</li>
            <li>
              Certified and current fire extinguishers should be available, and working smoke detectors properly
              installed. The local fire department can often times provide free assistance and information.
            </li>
            <li>The caregivers are not able to dead-lift more than 25 pounds, to minimize physical injuries.</li>
          </ul>
        </p>
        <H2>Restrictive Covenant</H2>
        <p>
          <b>(Agreement Not To Hire CaregiverGO's Employees).</b>
        </p>
        <p>
          Client shall not employ or receive services from the employee(s) assigned to Client by CaregiverGO except as
          contemplated by this Agreement for a period of two (2) years following the last day the employee(s) rendered
          services to Client on behalf of CaregiverGO pursuant to this Agreement. This prohibition includes, but is not
          limited to:
        </p>
        <p>
          <ol>
            <li>Paying the employee directly for services during the employee’s employment with CaregiverGO;</li>
            <li>Paying the employee directly for services after the employee’s employment with CaregiverGO ends;</li>
            <li>
              Paying another agency, person, or entity for services provided by the employee after the employee’s
              employment with CaregiverGO ends; and
            </li>
            <li>Accepting services from the employee while the employee is employed by another agency or entity.</li>
          </ol>
        </p>
        <p>
          In the event that Client violates this prohibition, Client shall pay $10,000.00 for each such employee to
          CaregiverGO as liquidated damages and any reasonable attorney’s fees and costs associated with collecting
          those liquidated damages. This amount reflects the costs of recruiting, screening, and training the
          employee(s). In addition to this remedy, CaregiverGO shall be entitled to obtain injunctive relief against any
          violation of this Agreement, without notice to Client, without the necessity of proving actual damages and
          without posting a bond. These remedies shall be cumulative and not mutually exclusive. CaregiverGO reserves
          the right to pursue any other or further remedies at law or in equity to enforce its rights under this
          Agreement.
        </p>
        <H2>Vehicle Policy</H2>
        <p>
          A client’s vehicle is the preferred choice for incidental transportation services. The clients vehicle must be
          insured at all times when in use by a CaregiverGO Caregiver.
        </p>
      </TextWrapper>
      <Spacer5 />
    </>
  );

  return appMode ? (
    <div className="p-3">{content}</div>
  ) : (
    <MainContentWrapper align="center">
      <Spacer5 />
      {content}
    </MainContentWrapper>
  );
};

const TextWrapper = styled.div`
  h2,
  p {
    text-align: left;
  }

  p {
    line-height: 23px;
    margin-bottom: 14px;
  }

  h2 {
    margin-top: 26px;
  }

  table {
    width: 300px;
    margin: 0;

    tr {
      border-bottom: 1px solid #ccc;
    }

    tr:last-child {
      border-bottom: none;
    }

    th,
    td {
      text-align: center;
      padding: 6px;
    }
  }
`;

export default Disclosure;
