export type Review = {
  id?: string;
  createdAt: firebase.firestore.Timestamp;
  review: string;
  rating: number;
  reviewer: string | null;
  jobId: string | undefined;
};

export const fromFirebase = (docs: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
  const reviews: Review[] = [];

  docs.forEach((doc) => {
    reviews.push({
      createdAt: doc.data().createdAt,
      review: doc.data().review,
      rating: doc.data().rating,
      reviewer: doc.data().reviewer,
      jobId: doc.data().jobId,
    });
  });

  return reviews;
};
