import React from "react";
import { Spacer2, Spacer3 } from "../components/spacers";
import { ButtonText, H1 } from "../components/text";
import { Button } from "../components/buttons";
import { AUTH_ROUTE } from "../constants/routes";
import { MainContentWrapper } from "../components/layout";
import { useHistory } from "react-router-dom";

interface ResetPasswordCompleteProps {}

const ResetPasswordComplete: React.FC<ResetPasswordCompleteProps> = () => {
  const history = useHistory();

  return (
    <MainContentWrapper align="center">
      <Spacer2 />
      <H1>Your password has been updated</H1>
      <Spacer3 />
      <Button onClick={() => history.push(AUTH_ROUTE)}>
        <ButtonText>Log in</ButtonText>
      </Button>
    </MainContentWrapper>
  );
};

export default ResetPasswordComplete;
