import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, FormLabel, Pagination, Table } from "react-bootstrap";
import styled from "styled-components";
import { ALLOWED_CITIES } from "../constants/zipcodes";
import { AdminCaregiversCsvBtn } from "./admin-caregivers-csv-btn";
import { Button } from "./buttons";
import { DropdownFormControl } from "./forms";
import { theme } from "./theme";
// eslint-disable-next-line no-unused-vars
import { FormControlElement } from "../types/form";
import { FormControl as InputFormControl } from "./forms";
import { PageLoading } from "./loading";

interface AdminCaregiverTableProps {
  caregivers: any[];
  onToggleCaregiverAccount: any;
  operationCityFilter: string;
  filterByOperationCity: Function;
  onSearchBy: (searchText: string) => void;
  pagination: boolean;
  totalPages: number;
  onPageChange: (page: number) => void;
  selectedPage: number;
  isLoading: boolean;
}

export const AdminCaregiversTable: React.FC<AdminCaregiverTableProps> = (props) => {
  const [caregivers, setCaregivers] = useState<any>(props.caregivers);
  const [searchText, setSearchText] = useState<string>("");

  const sortCaregiversBy = (field: string) => {
    switch (field) {
      default:
      case "displayName":
      case "email": {
        return props.caregivers?.sort((a: any, b: any) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0));
      }
      case "lastSignIn": {
        return props.caregivers.sort((a: any, b: any) =>
          a.lastSignInEpoch < b.lastSignInEpoch ? 1 : b.lastSignInEpoch < a.lastSignInEpoch ? -1 : 0
        );
      }
      case "disabled": {
        return props.caregivers.sort((a: any, b: any) =>
          a.disabled < b.disabled ? 1 : b.disabled < a.disabled ? -1 : 0
        );
      }
    }
  };

  const paginationItems = Array.from(Array(props.totalPages)).map((i, index) => {
    return (
      <Pagination.Item
        key={index}
        onClick={() => props.onPageChange(index + 1)}
        active={props.selectedPage === index + 1}
      >
        {index + 1}
      </Pagination.Item>
    );
  });

  useEffect(() => {
    setCaregivers(props.caregivers);
  }, [props.caregivers]);

  return (
    <>
      <div className="d-flex align-items-center pt-3 pb-3">
        <Form.Group as={Col} md="2" className="p-0">
          <FormLabel className="mr-1" htmlFor="state">
            Search By Name
          </FormLabel>
          <InputFormControl
            field="searchText"
            value={searchText}
            onChange={(event: React.ChangeEvent<FormControlElement>) => {
              props.onSearchBy(event.currentTarget.value);
              setSearchText(event.currentTarget.value);
            }}
          />
        </Form.Group>
        <Form.Group as={Col} md="2" className="p-0 d-flex flex-column pl-5">
          <FormLabel className="mr-1" htmlFor="state">
            Operation City
          </FormLabel>
          <DropdownFormControl
            as="select"
            value={props.operationCityFilter}
            id="operationCity"
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => props.filterByOperationCity(event)}
          >
            <option key={"all"}>All</option>
            {ALLOWED_CITIES.map((city) => (
              <option key={`city-${city}`}>{city}</option>
            ))}
          </DropdownFormControl>
        </Form.Group>
        <div className="ml-auto">
          <AdminCaregiversCsvBtn caregivers={caregivers}></AdminCaregiversCsvBtn>
        </div>
      </div>
      <Pagination>{paginationItems}</Pagination>
      {props.isLoading ? (
        <PageLoading />
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>
                <LinkButtonWrapper
                  onClick={() => {
                    setCaregivers([...sortCaregiversBy("displayName")]);
                  }}
                >
                  <button>Name</button>
                </LinkButtonWrapper>
              </th>
              <th>
                <LinkButtonWrapper
                  onClick={() => {
                    setCaregivers([...sortCaregiversBy("email")]);
                  }}
                >
                  <button>Email</button>
                </LinkButtonWrapper>
              </th>
              <th>
                <LinkButtonWrapper
                  onClick={() => {
                    setCaregivers([...sortCaregiversBy("operationCity")]);
                  }}
                >
                  <button>Operation City</button>
                </LinkButtonWrapper>
              </th>
              <th>
                <LinkButtonWrapper onClick={() => setCaregivers([...sortCaregiversBy("lastSignIn")])}>
                  <button>Last Sign In</button>
                </LinkButtonWrapper>
              </th>
              <th>
                <LinkButtonWrapper onClick={() => setCaregivers([...sortCaregiversBy("disabled")])}>
                  <button>Action</button>
                </LinkButtonWrapper>
              </th>
            </tr>
          </thead>
          <tbody>
            {caregivers?.map((caregiver: any) => {
              return (
                <tr key={caregiver.uid}>
                  <td>{caregiver.displayName}</td>
                  <td>{caregiver.email}</td>
                  <td>{caregiver.operationCity}</td>
                  <td>
                    {caregiver.metadata.lastSignInTime ? moment(caregiver.metadata.lastSignInTime).format("l") : ""}
                  </td>
                  <td>
                    <ActionButtonWrapper
                      color={caregiver.disabled ? theme.colors.jellyBean : theme.colors.yellowOrange}
                    >
                      <Button onClick={() => props.onToggleCaregiverAccount(caregiver.uid, !caregiver.disabled)}>
                        {caregiver.disabled ? "Enable" : "Disable"} account
                      </Button>
                    </ActionButtonWrapper>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

const LinkButtonWrapper = styled.div`
  button {
    background-color: transparent;
    border: 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ActionButtonWrapper = styled.div`
  button {
    background-color: ${(props) => props.color};
    padding: 6px 10px;
    font-size: 14px;
  }
`;
