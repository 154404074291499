import React from "react";
import styled from "styled-components";
// eslint-disable-next-line no-unused-vars
import { FormControlElement } from "../types/form";
import { Form, FormLabel, Col, Row } from "react-bootstrap";
import { FormControl as InputFormControl, DividerSpan } from "./forms";
import { requiredStar } from "./text";

interface PhoneFormGroupProps {
  isPhoneValid: boolean | undefined;
  phoneParts: string[];
  onChangePhone: Function;
}
const PhoneFormGroup = React.forwardRef<HTMLInputElement, PhoneFormGroupProps>((props, ref) => {
  const { isPhoneValid, phoneParts, onChangePhone } = props;
  return (
    <Form.Group as={Col} md="6">
      <FormLabel htmlFor="phoneNumber">Phone Number{requiredStar}</FormLabel>
      <Row>
        <Col>
          <PhoneInputFormControl
            id="phoneNumberInput1"
            ref={ref}
            minLength={3}
            maxLength={3}
            required
            isValid={isPhoneValid}
            value={phoneParts[0] ?? ''}
            onChange={(event: React.ChangeEvent<FormControlElement>) => onChangePhone(0, event)}
          />
          <DividerSpan>
            -
          </DividerSpan>
          <PhoneInputFormControl
            minLength={3}
            maxLength={3}
            required
            isValid={isPhoneValid}
            value={phoneParts[1] ?? ''}
            onChange={(event: React.ChangeEvent<FormControlElement>) => onChangePhone(1, event)}
          />
          <DividerSpan>
            -
          </DividerSpan>
          <PhoneInputFormControl
            minLength={4}
            maxLength={4}
            required
            isValid={isPhoneValid}
            value={phoneParts[2] ?? ''}
            onChange={(event: React.ChangeEvent<FormControlElement>) => onChangePhone(2, event)}
          />
        </Col>
      </Row>
      {isPhoneValid === false && <div className="invalid-feedback d-block">Please provide a valid phone number.</div>}
    </Form.Group>
  );
});

const PhoneInputFormControl = styled(InputFormControl)`
  max-width: 80px;
  display: inline-block;
`;

export default PhoneFormGroup;
