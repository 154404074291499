// eslint-disable-next-line no-unused-vars
import { DEFAULT_PRICE_PER_HOUR, Job, JobApplicant, JobApplicantFields, JobStatus, NewJob } from "../types/jobs";
// eslint-disable-next-line no-unused-vars
import { getPublicAddress, PatientProfileDocument, UserDocument } from "../types/user";
import { firestore } from "../firebase";
import { JOBS_COLLECTION, RECURRING_JOBS } from "../constants/collections";
import { getPatientDisplayName } from "../constants/profile";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import { Applicant } from "../types/applicant";
import { ZIP_CODES } from "../constants/zipcodes";

const HOURS_TO_PAY_JOB_AUTOMATICALLY: number = 3;

export const saveNewJob = async (
  incomingJob: NewJob | undefined,
  patient: UserDocument | null | false,
  profile: PatientProfileDocument | null
) => {
  if (!incomingJob || !patient || !profile || !patient.firebaseUser?.uid) return;
  if (!incomingJob.date || !incomingJob.shiftLength || !incomingJob.careNeeds?.length) return;

  const automaticPaymentDate = getAutomaticPayment(incomingJob.date, incomingJob.shiftLength);
  const warningDate = getWarningDate(incomingJob.date);
  const archivingDate = getAchivingDate(incomingJob.date);

  let jobArea = "portland";

  if (ZIP_CODES["eugene"].includes(Number(profile.zip))) {
    jobArea = "eugene";
  }

  if (ZIP_CODES["medford"].includes(Number(profile.zip))) {
    jobArea = "medford";
  }

  let newJob: Job = {
    careNeeds: incomingJob.careNeeds,
    date: incomingJob.date,
    automaticPaymentDate: automaticPaymentDate,
    warningDate,
    archivingDate,
    patientBirthday: profile.birthday,
    patientDisplayName: getPatientDisplayName(profile.lastName, profile.firstName),
    patientGender: profile.gender,
    patientCity: profile.city,
    patientZip: profile.zip,
    patientUID: patient.firebaseUser?.uid,
    pricePerHour: DEFAULT_PRICE_PER_HOUR,
    shiftLength: parseInt(incomingJob.shiftLength.toString()),
    status: incomingJob && incomingJob.status ? incomingJob.status : JobStatus.PENDING,
    notes: incomingJob.notes || "",
    dayOfWeek: moment(incomingJob.date).format("dddd").toLowerCase(),
    geoPoint: profile.geoPoint || null,
    warningSent: false,
    warningPushNotificationSent: false,
    isOwner: incomingJob.isOwner,
    createdDate: new Date(),
    jobArea: jobArea,
  };

  if (!incomingJob.isOwner) {
    newJob = {
      ...newJob,
      firstName: incomingJob.firstName,
      lastName: incomingJob.lastName,
      relationship: incomingJob.relationship,
      age: incomingJob.age,
      gender: incomingJob.gender,
    };
  }

  let parentJob: Partial<Job> = {};

  if (incomingJob.isRecurring) {
    parentJob = {
      ...newJob,
      isRecurring: incomingJob.isRecurring,
      recurringDays: incomingJob.recurringDays,
      recurringEndDate: incomingJob.recurringEndDate,
      recurringDates: incomingJob.recurringDates
    };
  }

  if (incomingJob.jobId) {
    await firestore.collection(JOBS_COLLECTION).doc(incomingJob.jobId).set(newJob, { merge: true });
  } else {
    if (incomingJob.isRecurring) {
      // create the parent job
      const parentJobId = (await firestore.collection(RECURRING_JOBS).add(parentJob)).id;

      // create each job with the date and the parent job id
      parentJob.recurringDates?.forEach(async (date) => {
        newJob.parentJobId = parentJobId;
        newJob.date = date;
        newJob.automaticPaymentDate = getAutomaticPayment(date, newJob.shiftLength);
        newJob.warningDate = getWarningDate(date);
        newJob.archivingDate = getAchivingDate(date);
        await firestore.collection(JOBS_COLLECTION).add(newJob);
      });
    } else {
      await firestore.collection(JOBS_COLLECTION).add(newJob);
    }
  }
};

export const updateApplication = async (
  job: Job,
  applicant: Applicant,
  field: string,
  trueOrFalse: boolean,
  profile?: PatientProfileDocument | null
) => {
  if (!job.applicants?.length) return;

  const applicants = job.applicants?.map((currentApplicant: JobApplicant) => {
    return currentApplicant.uid === applicant.id ? { ...currentApplicant, [field]: trueOrFalse } : currentApplicant;
  });

  const jobStatus = field === JobApplicantFields.confirmed && trueOrFalse ? JobStatus.CONFIRMED : JobStatus.PENDING;
  const confirmedApplicant = JobApplicantFields.confirmed && trueOrFalse ? applicant.id : null;

  let address = "";
  if (profile && jobStatus === JobStatus.CONFIRMED) {
    address = getPublicAddress(profile);
  }

  await firestore
    .collection(JOBS_COLLECTION)
    .doc(job.id)
    .update({ applicants: JSON.parse(JSON.stringify(applicants)), status: jobStatus, confirmedApplicant, address });
};

export const updateJobStatus = async (job: Job, newStatus: string) => {
  if (!Object.values(JobStatus).includes(newStatus as any)) {
    throw Error(`Job status ${newStatus} is invalid.`);
  }

  await firestore.collection(JOBS_COLLECTION).doc(job.id).update({ status: newStatus });
};

export const updateRecurringJobStatus = async (job: Job, newStatus: string) => {
  if (!Object.values(JobStatus).includes(newStatus as any)) {
    throw Error(`Job status ${newStatus} is invalid.`);
  }

  await firestore.collection(RECURRING_JOBS).doc(job.id).update({ status: newStatus });
};

export const fromFirebase = (doc: any) => {
  const job: Job = {
    id: doc.id,
    patientUID: doc.data().patientUID,
    patientDisplayName: doc.data().patientDisplayName,
    patientBirthday: doc.data().patientBirthday,
    patientGender: doc.data().patientGender,
    patientCity: doc.data().patientCity,
    patientZip: doc.data().patientZip,
    date: doc.data().date.toDate(),
    automaticPaymentDate: doc.data().automaticPaymentDate ? doc.data().automaticPaymentDate.toDate() : null,
    warningDate: doc.data().warningDate ? doc.data().warningDate.toDate() : null,
    archivingDate: doc.data().archivingDate ? doc.data().archivingDate.toDate() : null,
    shiftLength: doc.data().shiftLength,
    pricePerHour: doc.data().pricePerHour,
    careNeeds: doc.data().careNeeds,
    notes: doc.data().notes,
    status: doc.data().status,
    dayOfWeek: doc.data().dayOfWeek,
    reviewed: doc.data().reviewed || false,
    confirmedApplicant: doc.data().confirmedApplicant || null,
    clientFeedbackOption: doc.data().clientFeedbackOption,
    clientFeedbackInfo: doc.data().clientFeedbackInfo,
    applicants: doc.data().applicants
      ? doc.data().applicants.map((applicant: any) => {
          let newApplicant: JobApplicant = {
            cancelled: false,
            confirmed: applicant.confirmed || false,
            displayName: applicant.displayName || "",
            photoURL: applicant.photoURL || null,
            rating: applicant.rating,
            uid: applicant.uid,
          };
          return newApplicant;
        })
      : [],
    warningSent: doc.data().warningSent || false,
    warningPushNotificationSent: doc.data().warningPushNotificationSent || false,
    isOwner: doc.data().isOwner,
    firstName: doc.data().firstName || null,
    lastName: doc.data().lastName || null,
    relationship: doc.data().relationship || null,
    age: doc.data().age || null,
    gender: doc.data().gender || null,
    updatedDate: new Date(),
    parentJobId: doc.data().parentJobId
  };

  return job;
};

export const calculateRecurringDates = (initDate: any, endDate: any, selectedDayts: number[]) => {
  let initial = initDate.clone();
  const end = (endDate.clone()).add(1, 'day')
  const selectedDates = [];

  while (initial.isSameOrBefore(end)) {
    const day = initial.day();
    if (selectedDayts.includes(day)) {
      selectedDates.push(initial.toDate());
    }
    initial.add(1, 'day');
  }

  return selectedDates;
};

const getAutomaticPayment = (date: any, shiftLength: number) => {
  const hoursToAutomaticPayment: number = +shiftLength + +HOURS_TO_PAY_JOB_AUTOMATICALLY;
  return moment(date).add(hoursToAutomaticPayment, "hours").toDate();
};

const getWarningDate = (date: any) => moment(date).subtract(1, "day").toDate();
const getAchivingDate = (date: any) => moment(date).subtract(15, "minutes").toDate();

// const hoursToAutomaticPayment: number = +incomingJob.shiftLength + +HOURS_TO_PAY_JOB_AUTOMATICALLY;
//   const automaticPaymentDate = moment(incomingJob.date).add(hoursToAutomaticPayment, "hours").toDate();
//   const warningDate = moment(incomingJob.date).subtract(1, "day").toDate();
//   const archivingDate = moment(incomingJob.date).subtract(15, "minutes").toDate();

// Test recurring dates generator
// const current = moment('2024-03-11');
// const end = moment('2024-03-20');
// const selected = [1, 3, 5];
// console.log(generateRecurringDates(current, end, selected));
