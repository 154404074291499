const config = {
  apiKey: "AIzaSyBj-rG6-xYx9ch72wnyk-jlx46f0bjCoA0",
  authDomain: "caregivergo-7f521.firebaseapp.com",
  databaseURL: "https://caregivergo-7f521.firebaseio.com",
  projectId: "caregivergo-7f521",
  storageBucket: "caregivergo-7f521.appspot.com",
  messagingSenderId: "814420947501",
  appId: "1:814420947501:web:e545f89858aae3639dbea4",
  measurementId: "G-V4N3SPRTT1"
};

export const firebaseConfig = config;
