import React from "react";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Button, BackButton } from "../components/buttons";
import { Spacer2, Spacer4 } from "../components/spacers";

interface IProps {
  isVisible: boolean;
  setIsVisible: Function;
  title: string;
  mainCopy: any;
  hint?: any;
  onConfirm: Function;
  confirmCopy: string;
  onCancel?: Function;
  cancelCopy: string;
}

const ModalPopup: React.FC<IProps> = (props) => {
  const { isVisible, setIsVisible, title, mainCopy, hint, onConfirm, confirmCopy, onCancel, cancelCopy } = props;

  return isVisible ? (
    <ModalCardBackdrop>
      <ModalCard>
        <ModalCardTitle>
          <span />
          {title}
          <CloseIcon icon={faTimes} color="white" size="1x" onClick={() => setIsVisible(false)} />
        </ModalCardTitle>
        <ModalCopyWrapper>
          <Spacer4 />
          <ModalCardMainCopy>{mainCopy}</ModalCardMainCopy>
          {hint && <ModalCardHint>{hint}</ModalCardHint>}
          <Spacer2 />
          <Row className="justify-content-md-center">
            <Button onClick={(e) => onConfirm()}>{confirmCopy}</Button>
          </Row>
          <Row className="justify-content-md-center">
            <BackButton
              onClick={(e) => {
                onCancel ? onCancel() : setIsVisible(false);
              }}
            >
              {cancelCopy}
            </BackButton>
          </Row>
          <Spacer4 />
        </ModalCopyWrapper>
      </ModalCard>
    </ModalCardBackdrop>
  ) : (
    <></>
  );
};

const ModalCardBackdrop = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1100;
`;

const ModalCard = styled.div`
  background: white;
  width: 600px;
  height: auto;
  position: fixed;
  z-index: 1100;
  top: 40%;
  margin-top: -250px;
  left: 50%;
  margin-left: -300px;

  box-shadow: 0 3px 4px 2px ${(props) => props.theme.colors.boulder};
`;

export const ModalCardTitle = styled.div`
  background-color: ${(props) => props.theme.colors.jellyBean};
  color: white;
  font-size: 36px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  margin: 8px 24px 0 0;
  cursor: pointer;
`;

const ModalCopyWrapper = styled.div`
  width: 444px;
  margin: auto;
`;

const ModalCardMainCopy = styled.p`
  font-size: 36px;
  color: ${(props) => props.theme.colors.emperor};
  letter-spacing: 0;
  text-align: center;
  line-height: 46px;
  font-family: "Varta", sans-serif;
`;

const ModalCardHint = styled.p`
  font-size: 24px;
  color: ${(props) => props.theme.colors.jellyBean};
  letter-spacing: 0;
  text-align: center;
  font-style: italic;
  line-height: 28px;
`;

export default ModalPopup;
