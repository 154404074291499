import React from "react";
import useRequireAuth from "../hooks/use-require-auth";
import styled from "styled-components";
import { CalendarIcon, AvatarIcon, PencilIcon } from "../components/icons";
import { Link } from "react-router-dom";
import { PATIENT_JOBS_ROUTE, PATIENT_NEW_JOB_ROUTE, PATIENT_PROFILE_ROUTE } from "../constants/routes";
import { H1 } from "../components/text";
import { PageLoading } from "../components/loading";
import { Spacer2, Spacer4 } from "../components/spacers";
import { MainContentWrapper } from "../components/layout";
import useScrollTop from "../hooks/use-scroll-to-top";
import { Alert } from "react-bootstrap";
import { usePatientAuth } from "../hooks/use-patient-auth";

const PatientHome: React.FC = () => {
  const requireAuth = useRequireAuth();
  const { profile } = usePatientAuth();

  useScrollTop();

  if (!requireAuth.user)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <>
      <MainContentWrapper align="center">
        <H1>What would you like to do?</H1>
        <Spacer4 />

        <LinksWrapper className="mb-4">
          <HomeLink icon={PencilIcon} text="Post a<br>new job" to={PATIENT_NEW_JOB_ROUTE} />
          <HomeLink icon={CalendarIcon} text="View<br>upcoming jobs" to={PATIENT_JOBS_ROUTE} />
          <HomeLink icon={AvatarIcon} text="Edit Care<br>Profile" to={PATIENT_PROFILE_ROUTE} />
        </LinksWrapper>
        {(profile && !profile.isAccountOwner) && (
          <Alert variant="info" className="m-0">
            <p className="m-0">
              We have improved our care profile settings. Please visit{" "}
              <Alert.Link href={PATIENT_PROFILE_ROUTE}>your Profile page</Alert.Link> to provide additional details.
            </p>
          </Alert>
        )}
      </MainContentWrapper>
    </>
  );
};

const LinksWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  text-align: center;
`;

const LinkWrapper = styled.div`
  background-color: white;
  width: 271px;
  height: 218px;

  &:hover {
    box-shadow: 0 0 5px 3px #e0e0e0;

    .bottom-border {
      background-color: ${(props) => props.theme.colors.celadon};
    }
  }

  a {
    display: block;
    padding-top: 36px;
  }

  a:hover {
    text-decoration: none;
  }
`;

const HomeLinkText = styled.p`
  font-size: 30px;
  color: ${(props) => props.theme.colors.jellyBean};
  line-height: 32px;
  text-decoration: none !important;
`;

const HomeLink = (props: any) => (
  <LinkWrapper>
    <Link to={props.to}>
      {props.icon}
      <Spacer2 />
      <HomeLinkText dangerouslySetInnerHTML={{ __html: props.text }} />
      <BottomBorder />
    </Link>
  </LinkWrapper>
);

const BottomBorder = styled.div.attrs(() => ({
  className: "bottom-border",
}))`
  margin-top: 43px;
  background-color: white;
  height: 10px;
`;

export default PatientHome;
