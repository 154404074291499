import React, { useEffect, useState } from "react";
import { MainContentWrapper } from "../components/layout";
import useRequireAuth from "../hooks/use-require-auth";
import { usePatientAuth } from "../hooks/use-patient-auth";
import useScrollTop from "../hooks/use-scroll-to-top";
import { firestore } from "../firebase";
import { JobFields, JobStatus } from "../types/jobs";
import { CHATS_COLLECTION, JOBS_COLLECTION } from "../constants/collections";
import { PageLoading } from "../components/loading";
import { Spacer3 } from "../components/spacers";
import { Tab, TabsWrapper } from "../components/tabs";
import { TabLabel } from "../components/text";
import { Tabs, Tab as CurrentTab } from "react-bootstrap";
import styled from "styled-components";
import { theme } from "../components/theme";
import { JobChatListItem } from "../components/chat/job-chat-list-item";

enum ActiveTab {
  // eslint-disable-next-line no-unused-vars
  ACTIVE = "active",
  // eslint-disable-next-line no-unused-vars
  ENDED = "ended",
  // eslint-disable-next-line no-unused-vars
  ALL = "all",
}

const MyMessages: React.FC = () => {
  const requireAuth = useRequireAuth();
  const { user } = usePatientAuth();
  useScrollTop();

  const [loading, setLoading] = useState(true);
  // TODO use proper type DocumentSnapshot
  const [jobs, setJobs] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(ActiveTab.ACTIVE);

  useEffect(() => {
    if (!user || !user?.firebaseUser) return;

    const jobStatuses = {
      [ActiveTab.ACTIVE]: [JobStatus.CONFIRMED],
      [ActiveTab.ENDED]: [JobStatus.COMPLETED],
      [ActiveTab.ALL]: [JobStatus.CONFIRMED, JobStatus.COMPLETED],
    };

    const unsubscribe = firestore
      .collection(JOBS_COLLECTION)
      .where(JobFields[JobFields.patientUID], "==", user?.firebaseUser?.uid)
      .where(JobFields[JobFields.status], "in", jobStatuses[activeTab])
      .orderBy(JobFields[JobFields.date])
      .onSnapshot(
        async (documents) => {
          const jobsData = [];

          // Iterate over each job document to fetch its chats
          for (const doc of documents.docs) {
            const job = doc.data();

            // Fetch chats associated with this job
            const chatsSnapshot = await firestore
              .collection(JOBS_COLLECTION)
              .doc(doc.id)
              .collection(CHATS_COLLECTION)
              .orderBy("createdAt", "asc")
              .get();

            const chats = chatsSnapshot.docs.map((chatDoc) => chatDoc.data());
            // Add the chats to the job object
            if (chats.length > 0) {
              jobsData.push({
                ...job,
                chats: chats,
              });
            }
          }

          setJobs(jobsData);
          setLoading(false);
        },
        (e) => {
          console.error(e);
          setLoading(false);
          setError(true);
        }
      );

    return () => unsubscribe();
  }, [user, activeTab]);

  if (!requireAuth.user || loading)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <>
      <MainContentWrapper customContainer={true}>
        <Spacer3 />
        <TabsWrapper>
          <Tab active={true}>
            <TabLabel>
              <span>My Messages</span>
            </TabLabel>
          </Tab>
        </TabsWrapper>
        <Spacer3 />
        <MessagesTabs
          id="controlled-messages-tab"
          activeKey={activeTab}
          onSelect={(key: string) => {
            setActiveTab(key as ActiveTab);
            setLoading(true);
          }}
        >
          <CurrentTab eventKey="active" title="Active">
            <Spacer3 />
            {jobs.length > 0 ? (
              <JobChatList>
                {jobs.map((job: any) => (
                  <JobChatListItem key={job.id} job={job} />
                ))}
              </JobChatList>
            ) : (
              <div>
                <p>No Active Jobs</p>
              </div>
            )}
          </CurrentTab>
          <CurrentTab eventKey="ended" title="Ended">
            <Spacer3 />
            {jobs.length > 0 ? (
              <JobChatList>
                {jobs.map((job: any) => (
                  <JobChatListItem key={job.id} job={job} />
                ))}
              </JobChatList>
            ) : (
              <div>
                <p>No Ended Jobs</p>
              </div>
            )}
          </CurrentTab>
          <CurrentTab eventKey="all" title="All">
            <Spacer3 />
            {jobs.length > 0 ? (
              <JobChatList>
                {jobs.map((job: any) => (
                  <JobChatListItem key={job.id} job={job} />
                ))}
              </JobChatList>
            ) : (
              <div>
                <p>No Jobs</p>
              </div>
            )}
          </CurrentTab>
        </MessagesTabs>
      </MainContentWrapper>
    </>
  );
};

const MessagesTabs = styled(Tabs)`
  background-color: ${theme.colors.wildSand};
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;

  a.nav-item {
    width: 100%;
    border: 1px solid ${theme.colors.alto};
    border-radius: 50px;
    padding: 10px 50px;
    text-align: center;

    &.active {
      background-color: ${theme.colors.jellyBean};
      border: 1px solid ${theme.colors.jellyBean};
      border-radius: 50px;
      color: #fff;
    }
  }
`;

const JobChatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default MyMessages;
