import { useEffect } from "react";
import { usePatientAuth } from "./use-patient-auth";
import { useRouter } from "./use-router";
import { AUTH_ROUTE, PATIENT_HOME_NO_PROFILE_ROUTE, PATIENT_PROFILE_ROUTE, ADMIN_ROUTE } from "../constants/routes";

function useRequireAuth() {
  const auth = usePatientAuth();
  const router = useRouter();

  useEffect(() => {
    if (!auth.loading && !auth.user) {
      router.push(AUTH_ROUTE);
      return;
    }

    if (!auth.loading && auth.token?.claims.admin) {
      router.push(ADMIN_ROUTE);
      return;
    }

    if (!auth.loading && (!auth.profile?.firstName || !auth.profile.ccReady)) {
      if (router.pathname === PATIENT_HOME_NO_PROFILE_ROUTE) return;
      if (router.pathname === PATIENT_PROFILE_ROUTE) return;

      router.push(PATIENT_HOME_NO_PROFILE_ROUTE);
      return;
    }
  }, [auth, router]);

  return auth;
}

export default useRequireAuth;
