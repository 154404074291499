import React from "react";
import styled from "styled-components";
import { Form, Col } from "react-bootstrap";
import { Button } from "./buttons";

interface CheckboxFormGroupProps {
  field: string;
  optionsValueArray: string[];
  optionsLabelArray: string[];
  valuesArray?: String[];
  value?: String;
  singleValue?: boolean;
  onClick: Function;
}
const CheckboxFormGroup: React.FC<CheckboxFormGroupProps> = (props) => {
  const { optionsValueArray, optionsLabelArray, valuesArray, field, onClick, value, singleValue } = props;
  return (
    <Form.Row>
      <Form.Group as={Col} md="12" className="text-center">
        {optionsLabelArray.map((label, index) => {
          const _value = optionsValueArray[index];
          return (
            <CheckboxButton
              key={`${field}-${_value}`}
              type="button"
              className={(singleValue && value === _value) || valuesArray?.includes(_value) ? "selected" : ""}
              value={_value}
              onClick={(event) => onClick(field, event)}
            >
              {label}
            </CheckboxButton>
          );
        })}
      </Form.Group>
    </Form.Row>
  );
};

const CheckboxButton = styled(Button)`
  color: ${(props) => props.theme.colors.tundora};
  background-color: white;
  border-color: ${(props) => props.theme.colors.silverChalice};
  border-style: solid;
  border-width: 1px;

  font-size: 16px;
  height: 60px;
  margin: 8px;
  display: inline-flex;
  line-height: 18px;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: white;
  }
  &.selected {
    background-color: ${(props) => props.theme.colors.celadon};
    color: ${(props) => props.theme.colors.tundora};
    border-color: ${(props) => props.theme.colors.celadon};
  }

  &.larger {
    width: 200px;
    height: 80px;
  }
`;

export { CheckboxFormGroup, CheckboxButton };
