import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Button } from "./buttons";

interface AdminCaregiversCsvBtnProps {
  caregivers: any[];
}

export const AdminCaregiversCsvBtn: React.FC<AdminCaregiversCsvBtnProps> = (props) => {
  return (
    <>
      <ButtonWrapper>
        <Button
          onClick={() => {
            const header = ["Name", "Email", "Last Sign In", "Account status"];

            const rows = [];
            rows.push(header);
            props.caregivers.map((cg: any) =>
              rows.push([
                cg.displayName,
                cg.email,
                cg.lastSignInEpoch !== 0 ? moment(cg.lastSignIn).format("l") : " ",
                cg.disabled ? "disabled" : "active",
              ])
            );
            const csv = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

            let link = document.createElement("a");
            link.download = "caregivers-export.csv";
            link.href = csv;
            link.click();
          }}
        >
          Download Caregivers
        </Button>
      </ButtonWrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  button {
    padding: 12px 20px;
    font-size: 16px;
  }
`;
