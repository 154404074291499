/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { firebaseFunctions } from "../firebase";
import { MainContentWrapper } from "../components/layout";
import { PageLoading } from "../components/loading";
import { Spacer5 } from "../components/spacers";
import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "../components/buttons";
import useAdminAuth from "../hooks/use-admin-auth";
import Spinner from "react-bootstrap/Spinner";
import { AdminCaregiverTab } from "../components/admin-caregiver-tab";
import { AdminJobsTab } from "../components/admin-jobs-tab";

interface Caregiver {
  firstName: string;
  lastName: string;
  email: string;
}

const Admin: React.FC = () => {
  const auth = useAdminAuth();
  const [isActiveJobsTab, setIsActiveJobsTab] = useState<boolean>(true);
  const [isActiveCaregiverListTab, setIsActiveCaregiverListTab] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const fileReader = new FileReader();
  const [newCaregivers, setNewCaregivers] = useState<Caregiver[] | undefined>(undefined);
  const [showNewCaregivers, setShowNewCaregivers] = useState(false);
  const [importingCaregivers, setImportingCaregivers] = useState(false);
  const [importResult, setImportResult] = useState<string | undefined>();
  
  useEffect(() => {
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target?.result;
        csvFileToArray(csvOutput as string);
      };

      fileReader.readAsText(file);
    }
  }, [file]);

  const csvFileToArray = (csv: string) => {
    const csvHeader = csv.slice(0, csv.indexOf("\n")).split(",");
    const csvRows = csv.slice(csv.indexOf("\n") + 1).split("\n");

    let array = csvRows.map((i: string) => {
      const values = i.split(",");
      return csvHeader.reduce((object: any, header: any, index: any) => {
        object[header] = values[index];
        return object;
      }, {});
    });

    // Clear up entries with undefined values
    array = array.filter((i: any) => {
      const values = Object.values(i);
      return !values.some((v: any) => v === undefined);
    });

    const newCaregivers = array.map((caregiver: any) => {
      const values = Object.values(caregiver);

      return {
        firstName: cleanCaregiverEntry(values[0] as string),
        lastName: cleanCaregiverEntry(values[1] as string),
        email: cleanCaregiverEntry(values[2] as string),
      };
    });

    setNewCaregivers(newCaregivers as Caregiver[]);
  };

  const cleanCaregiverEntry = (str: string) => str.replace(/(\r\n|\n|\r)/gm, "").trim();

  const importCaregivers = async () => {
    setImportingCaregivers(true);
    const data = { caregivers: newCaregivers };
    const importCall = firebaseFunctions.httpsCallable("importCaregivers");
    importCall(data)
      .then((result) => {
        setImportResult(result.data);
        setImportingCaregivers(false);
      })
      .catch(() => {
        setImportResult("Error importing caregivers. Please try again or contact Germinate's support team.");
      });
  };

  
  

  // Left for testing purposes
  // const notificationCall = async () => {
  //   const onCall = firebaseFunctions.httpsCallable("newJobsNotification");
  //   const result = await onCall();
  //   console.log(result);
  // };

  if (auth.loading)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <>
      <MainContentWrapper customContainer={true}>
        <Spacer5 />
        {/*Left for testing purposes*/}
        {/*<div style={{ display: "flex", justifyContent: "end" }}>*/}
        {/*  <Button onClick={notificationCall}>Test Daily Jobs Notification</Button>*/}
        {/*</div>*/}
        <Tabs
          defaultActiveKey="jobs-list"
          className="mb-3"
          onSelect={(key) => {
            setIsActiveCaregiverListTab(key === "caregiver-list");
            setIsActiveJobsTab(key === "jobs-list");
          }}
        >
          <Tab eventKey="jobs-list" title="Shifts">
            <AdminJobsTab isActiveTab={isActiveJobsTab} />
          </Tab>

          <Tab eventKey="caregiver-list" title="Caregivers List">
            <AdminCaregiverTab isActiveTab={isActiveCaregiverListTab} />
          </Tab>

          <Tab eventKey="import-caregivers" title="Import caregivers">
            <h2 className="pt-3 mb-1">Import new caregivers</h2>
            <div className="d-flex justify-content-center align-items-center pt-3 pb-3">
              <div className="custom-file mr-2">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  accept=".csv"
                  onChange={(e) => {
                    setNewCaregivers(undefined);
                    setImportResult(undefined);
                    setFile(e.target.files?.length ? e.target.files[0] : undefined);
                  }}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {file ? file.name : "Choose file"}
                </label>
              </div>

              <div className="d-flex justify-content-center" style={{ width: "150px" }}>
                <ButtonWrapper>
                  <Button disabled={file === undefined} onClick={() => setShowNewCaregivers(true)}>
                    Load CSV
                  </Button>
                </ButtonWrapper>
              </div>
            </div>
            {showNewCaregivers && (
              <>
                <h3>The following caregivers will be imported ({newCaregivers?.length} total)</h3>
                <div>
                  {newCaregivers?.map((caregiver, index) => {
                    const entries = Object.values(caregiver);

                    return (
                      <div key={`new-caregiver-${index}`}>
                        <p className="mb-1 ml-2">{`${entries[0]} ${entries[1]}, ${entries[2]}`}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-4" />
                <Button onClick={importCaregivers} disabled={importingCaregivers}>
                  Import caregivers and send invitation email{" "}
                  {importingCaregivers && (
                    <SpinnerWrapper>
                      <Spinner animation="border" />
                    </SpinnerWrapper>
                  )}
                </Button>
                <div className="dropdown-divider" />
                {importResult && (
                  <>
                    <h2>Import results</h2>
                    <pre>{importResult}</pre>
                  </>
                )}
                <div className="mt-4" />
              </>
            )}
          </Tab>
        </Tabs>
      </MainContentWrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  button {
    padding: 12px 20px;
    font-size: 16px;
  }
`;

const SpinnerWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;

  .spinner-border {
    height: 24px;
    width: 24px;
  }
`;

export { Admin };
