import React from "react";

const GenericError: React.FC = () => {
  return (
    <div className="d-flex flex-column pt-3">
      <p className="mb-0 text-center">Sorry, we found an error on this page.</p>
      <p className="mb-0 text-center">Please try again reloading the page or contact support</p>
    </div>
  );
};

export default GenericError;
