import { useMemo } from "react";
// eslint-disable-next-line no-unused-vars
import { Job, JobStatus } from "../types/jobs";
import {usePatientAuth} from "./use-patient-auth";

export const useChatActive = (job: Job) => {
  const auth = usePatientAuth();

  const isChatActive = useMemo(() => {
    if (!job) return false;

    if (auth.token?.claims.admin) return false

    const currentDate = new Date();

    // If job not confirmed or completed, chat is not active
    if (job.status !== JobStatus.CONFIRMED && job.status !== JobStatus.COMPLETED) {
      return false;
    }

    // If job is completed, chat is active until job end date + 1 day
    if (job.status === JobStatus.CONFIRMED) return true;

    // If Job is completed, calculate end date + 1 day
    if (job.status === JobStatus.COMPLETED) {
      const jobEndDate = new Date(job.date);

      jobEndDate.setHours(jobEndDate.getHours() + job.shiftLength);

      // Add 1 day
      const additionalDay = new Date(jobEndDate);
      additionalDay.setDate(additionalDay.getDate() + 1);
      // Chat is active until additional day
      return currentDate <= additionalDay;
    }

    return false;
  }, [job, auth]);

  return isChatActive;
};
