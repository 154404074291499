export const JobStatus = Object.freeze({
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  CONFIRMED: "CONFIRMED",
  PAID: "PAID",
  PAYMENT_ERROR: "PAYMENT_ERROR",
  PAYMENT_ATTEMPTS_EXHAUSTED: "PAYMENT_ATTEMPTS_EXHAUSTED",
  CLIENT_REPORTED_PROBLEM: "CLIENT_REPORTED_PROBLEM",
  CAREGIVER_REPORTED_PROBLEM: "CAREGIVER_REPORTED_PROBLEM",
  ARCHIVED: "ARCHIVED",
});

export type JobApplicant = {
  uid: string;
  displayName: string;
  photoURL: string;
  rating: number;
  cancelled: boolean;
  confirmed: boolean;
};

export const JobApplicantFields = Object.freeze({
  confirmed: "confirmed",
});

export enum JobFields {
  "id",
  "patientUID",
  "date",
  "status",
  "reviewed",
  "clientFeedbackOption",
  "clientFeedbackInfo",
  "clientFeedbackDate",
  "parentJobId",
}

export const DEFAULT_PRICE_PER_HOUR = 35;
export const MAX_NOTES_LENGTH = 3000;
export const MAX_TASK_NOTES_LENGTH = 270;

export type Job = {
  id?: string;
  patientUID: string;
  patientDisplayName: string;
  patientBirthday: Date;
  patientGender: string;
  patientCity: string;
  patientZip: string;
  date: Date;
  automaticPaymentDate: Date;
  warningDate: Date;
  archivingDate: Date;
  shiftLength: number;
  pricePerHour: number;
  careNeeds: string[];
  dayOfWeek: string;
  notes?: string;
  status: string;
  ratingGiven?: number;
  patientAddress?: string;
  confirmedApplicant?: string;
  applicants?: JobApplicant[];
  applicantsIds?: string[];
  geoPoint?: object | null;
  reviewed?: boolean;
  clientFeedbackOption?: string;
  clientFeedbackInfo?: string;
  warningSent: boolean;
  warningPushNotificationSent: boolean;
  isOwner?: boolean;
  firstName?: string;
  lastName?: string;
  relationship?: string;
  age?: number;
  gender?: string;
  createdDate?: Date;
  updatedDate?: Date;
  jobArea?: string;
  parentJobId?: string;
  isRecurring?: boolean;
  recurringDays?: number[];
  recurringEndDate?: Date;
  recurringDates?: Date[];
  chats?: {
    id: string;
    text: string;
    senderId: string;
    senderName: string;
    createdAt: Date;
    seen: boolean;
  }[];
};

export type NewJob = {
  step?: number;
  date?: Date;
  shiftLength?: number;
  pricePerHour?: number;
  careNeeds?: string[];
  notes?: string;
  jobId?: string;
  status?: string;
  isOwner?: boolean;
  firstName?: string;
  lastName?: string;
  relationship?: string;
  age?: number;
  gender?: string;
  parentJobId?: string;
  isRecurring?: boolean;
  recurringDays?: number[];
  recurringEndDate?: Date;
  recurringDates?: Date[];
};

export const shiftLengths: string[] = ["1", "2", "3"];
