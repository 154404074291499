export const MONTH_NUMBERS_ARRAY: number[] = Array.from(Array(13).keys());
MONTH_NUMBERS_ARRAY.shift();
export const DAY_NUMBERS_ARRAY: number[] = Array.from(Array(32).keys());
DAY_NUMBERS_ARRAY.shift();
export const YEAR_NUMBERS_ARRAY: number[] = [];
for (let year = 1910; year <= 2000; year++) {
  YEAR_NUMBERS_ARRAY.push(year);
}
// ... address
export const US_STATES_ARRAY: string[] = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

export const getPatientDisplayName = (firstName: string, lastName: string) =>
  `${lastName}, ${firstName.substring(0, 1)}`;
