export const AUTH_ROUTE = "/";
export const SIGN_UP_ROUTE = "/sign-up";
export const FORGOT_PASSWORD_ROUTE = "/forgot";
export const VERIFY_EMAIL_RESET_PASSWORD_ROUTE = "/user-management";
export const RESET_PASSWORD_COMPLETE_ROUTE = "/password-reset-complete";
export const TERMS_OF_SERVICE_ROUTE = "/terms";
export const TERMS_OF_SERVICE_APP_ROUTE = "/terms_app";
export const DISCLOSURE_ROUTE = "/disclosure";
export const DISCLOSURE_APP_ROUTE = "/disclosure_app";
export const PRIVACY_ROUTE = "/privacy";
export const PRIVACY_APP_ROUTE = "/privacy_app";
export const PATIENT_HOME_ROUTE = "/home";
export const PATIENT_HOME_NO_PROFILE_ROUTE = "/setup";
export const PATIENT_PROFILE_ROUTE = "/profile";
export const PATIENT_JOBS_ROUTE = "/jobs";
export const PATIENT_JOB_ROUTE = "/job/:jobId";
export const PATIENT_JOB_ROUTE_PATH = "/job";
export const PATIENT_NEW_JOB_ROUTE = "/post-job";
export const PATIENT_EDIT_JOB_ROUTE = "/edit-job/:jobId";
export const PATIENT_EDIT_JOB_ROUTE_PATH = "/edit-job";
export const PATIENT_NEW_JOB_SUCCESS_ROUTE = "/new-job-success";
export const PATIENT_ACCOUNT_HISTORY_ROUTE = "/account-history";
export const PATIENT_ACCOUNT_INFO_ROUTE = "/account-info";
export const APPLICANT_ROUTE = "/applicant/:applicantId/:jobId";
export const APPLICANT_PATH = "/applicant";
export const ADMIN_ROUTE = "/admin";
export const MY_MESSAGES_ROUTE = "/messages";
export const MY_MESSAGE_ROUTE = "/messages/:messageId";
