import { useEffect } from "react";
import { usePatientAuth } from "./use-patient-auth";
import { useRouter } from "./use-router";
import { AUTH_ROUTE } from "../constants/routes";

function useAdminAuth() {
    const auth = usePatientAuth();
    const router = useRouter();

    useEffect(() => {
        if (!auth.loading && !auth.user) {
            router.push(AUTH_ROUTE);
            return;
        }

        if (!auth.loading && !auth.token?.claims.admin) {
            auth.signOut().then(() => {
              window.location.href = AUTH_ROUTE;
            });
        }
    }, [auth, router]);

    return auth;
}

export default useAdminAuth;
