import React from "react";
import { H1, H2 } from "../components/text";
import { MainContentWrapper } from "../components/layout";
import { Spacer3, Spacer5 } from "../components/spacers";
import styled from "styled-components";
import useScrollTop from "../hooks/use-scroll-to-top";
import { TERMS_OF_SERVICE_APP_ROUTE, TERMS_OF_SERVICE_ROUTE } from "../constants/routes";
import { Link, useLocation } from "react-router-dom";

const Privacy: React.FC = () => {
  useScrollTop();
  const location = useLocation();
  const appMode = location.pathname === "/privacy_app";

  const content = (
    <>
      <H1>Privacy Policy</H1>
      <Spacer3 />
      <TextWrapper>
        <H2>Introduction</H2>
        <p>
          Cassiel Portland LLC dba CaregiverGO, (the “Company”, “we” or “us”) values its users’ and visitors’ privacy.
          This privacy policy is effective as of the date displayed at the bottom of this page; it summarizes what
          information we might collect from a registered user or other visitor (“you”), and what we will and will not do
          with it.
        </p>
        <p>
          Please note that this privacy policy does not govern the collection and use of information by companies that
          the Company does not control, nor by individuals not employed or managed by the Company. If you visit a
          website that we mention or link to, be sure to review its privacy policy before providing the site with
          information.
        </p>
        <H2>What we do with your personally identifiable information</H2>
        <p>
          It is always up to you whether to disclose personally identifiable information to us, although if you elect
          not to do so, we reserve the right not to register you as a user or provide you with any services. “Personally
          identifiable information” (“PII”) means information that can be used to identify you as an individual, such
          as, for example:
        </p>
        <p>
          <ul>
            <li>your name, company, email address, phone number, billing address, and shipping address</li>
            <li>your user ID and password</li>
            <li>credit card information</li>
            <li>any account-preference information you provide us</li>
            <li>
              your computer’s domain name and IP address, indicating where your computer is located on the Internet
            </li>
            <li>
              session data for your login session, so that our computer can ‘talk’ to yours while you are logged in
            </li>
          </ul>
        </p>
        <p>If you do provide PII to us, either directly or through a reseller or other business partner, we will:</p>
        <p>
          <ul>
            <li>
              not sell or rent it to a third party without your permission — although unless you opt out (see below), we
              may use your contact information to provide you with information we believe you need to know or may find
              useful, such as (for example) news about our services and products and modifications to the{" "}
              <Link to={appMode ? TERMS_OF_SERVICE_APP_ROUTE : TERMS_OF_SERVICE_ROUTE}>Terms of Service</Link>;
              <li>not use or disclose the information except:</li>
              <ul>
                <li>as necessary to provide services you have ordered;</li>
                <li>in other ways described in this privacy policy or to which you have otherwise consented;</li>
                <li>
                  in the aggregate with other information in such a way so that your identity cannot reasonably be
                  determined (for example, statistical compilations);
                </li>
                <li>as required by law, for example, in response to a subpoena;</li>
                <li>to outside auditors who have agreed to keep the information confidential;</li>
                <li>
                  as necessary to enforce the{" "}
                  <Link to={appMode ? TERMS_OF_SERVICE_APP_ROUTE : TERMS_OF_SERVICE_ROUTE}>Terms of Service</Link>;
                </li>
                <li>
                  as necessary to protect the rights, safety, or property of the Company, its users, or others; this may
                  include (for example) exchanging information with other pages for fraud protection and/or risk
                  reduction.
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <H2>Other information we collect</H2>
        <p>
          We may collect other information that cannot be readily used to identify you, such as (for example) the domain
          name and IP address of your computer. We may use this information, individually or in the aggregate, for
          technical administration of the Website; research and development; customer and account administration; and to
          help us focus our marketing efforts more precisely.
        </p>
        <H2>Location</H2>
        <p>
          We request your precise location so we can provide accurate job distance information. For your security we do
          not request or store exact (street address) locations and our mobile apps do not track your location in the
          background.
        </p>
        <H2>Cookies</H2>
        <p>
          The Company may use “cookies” to store personal data on your computer. We may also link information stored on
          your computer in cookies with personal data about specific individuals stored on our servers. If you set up
          your web browser (for example, Chrome or Firefox) so that cookies are not allowed, you might not be able to
          use some or all of the features of the Website. We currently do not react to Do Not Track (DNT) signals.
        </p>
        <H2>Service Providers</H2>
        <p>
          We may employ third party companies to administer and provide the Company services on our behalf (including,
          without limitation, bill and credit card payment processing, maintenance, administration, support, hosting and
          database management services, email and sms providers). These third parties may have access to your PII only
          to perform these tasks on our behalf in accordance with this Privacy Policy and are obligated not to disclose
          or use it for any other purpose.
        </p>
        <H2>Business Transfers</H2>
        <p>
          The Company may sell, transfer or otherwise share some or all of its business or assets, including your
          personal information, in connection with a business deal (or potential business deal) such as a merger,
          consolidation, acquisition, reorganization or sale of assets or in the event of bankruptcy, in which case we
          will require the recipient to honor this Privacy Policy. You acknowledge that such transfers may occur and
          that any acquirer or successor of the Company or its assets may continue to use your personal information as
          set forth in this Privacy Policy.
        </p>
        <H2>Changing or Deleting Your Information</H2>
        <p>
          All Company account holders may review, update, correct or delete the PII in their registration profile by
          logging into their account. Removing your information may limit our ability to provide services to you. You
          may also contact us directly to accomplish the foregoing or if you have additional requests or questions.
          We’ll take reasonable steps to provide, modify or delete your information as soon as is practicable, but some
          information may remain in archived/backup copies for our records or as otherwise required by law. We may limit
          or reject your request in certain cases, including without limitation where the burden or expense of providing
          access would be disproportionate to the risks to your privacy in the case in question, where the rights of
          other persons would be violated, or as required by law. We will respond to all requests within 30 days.
        </p>
        <H2>Your privacy responsibilities</H2>
        <p>To help protect your privacy, be sure:</p>
        <p>
          <ul>
            <li>
              to never share your user ID or password with anyone, and to notify the Company immediately upon a breach
              of security;
            </li>
            <li>to log off the Website when you are finished;</li>
            <li>
              to take customary precautions to guard against “malware” (viruses, Trojan horses, bots, etc.), for example
              by installing and updating suitable anti-virus software.
            </li>
          </ul>
        </p>
        <H2>Information collected from children</H2>
        <p>
          You must be at least 13 years old to use the Website. The Company does not knowingly collect personally
          identifiable information from children under 13. (See the{" "}
          <a href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule">
            U.S. Children’s Online Privacy Protection Act.
          </a>
          )
        </p>
        <H2>Changes to this privacy policy</H2>
        <p>
          We reserve the right to change this privacy policy as we deem necessary or appropriate because of legal
          compliance requirements or changes in our business practices. If you have provided us with an email address,
          we will endeavor to notify you, by email to that address, of any material change to how we will use personally
          identifiable information.{" "}
        </p>
      </TextWrapper>
      <Spacer5 />
    </>
  );

  return appMode ? (
    <div className="p-3">{content}</div>
  ) : (
    <MainContentWrapper align="center">
      <Spacer5 />
      {content}
    </MainContentWrapper>
  );
};

const TextWrapper = styled.div`
  a {
    text-decoration: underline;
  }

  h2,
  p {
    text-align: left;
  }

  p {
    line-height: 23px;
    margin-bottom: 14px;
  }

  h2 {
    margin-top: 26px;
  }

  table {
    width: 300px;
    margin: 0;

    tr {
      border-bottom: 1px solid #ccc;
    }

    tr:last-child {
      border-bottom: none;
    }

    th,
    td {
      text-align: center;
      padding: 6px;
    }
  }
`;

export default Privacy;
