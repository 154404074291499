export const ALLOWED_CITIES = ['Portland', 'Eugene', 'Medford'];
export const ZIP_CODES: { [key: string]: number[] } = {
  portland: [
    97027,
    97201,
    97202,
    97204,
    97205,
    97206,
    97209,
    97210,
    97219,
    97221,
    97223,
    97222,
    97224,
    97225,
    97229,
    97240,
    97242,
    97266,
    97267,
    97268,
    97269,
    97280,
    97281,
    97282,
    97286,
    97290,
    97291,
    97296,
    97298,
    97062,
    97070,
    97123,
    97124,
    97034,
    97035,
    97005,
    97006,
    97007,
    97008,
    97075,
    97076,
    97077,
    97068,
    97045,
  ],
  eugene: [
    97401,
    97402,
    97404,
    97408,
    97405,
    97448,
    97487,
    97473,
    97419,
    97478,
    97426,
    97424,
    97475,
    97477,
    97482
  ],
  medford: [
    97501,
    97504,
    97540,
    97520,
    97530,
    97502,
    97525,
    97527,
    97537,
    97526,
    97524,
    97535,
  ]
};
