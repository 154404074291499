// eslint-disable-next-line no-unused-vars
import React, { ChangeEvent, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { FormUser } from "../types/user";
import Form from "react-bootstrap/Form";
import { usePatientAuth } from "../hooks/use-patient-auth";
import { useHistory } from "react-router-dom";
import { ButtonText, H1 } from "../components/text";
import { MainContentWrapper } from "../components/layout";
import { AUTH_ROUTE } from "../constants/routes";
import { PageLoading } from "../components/loading";
import { Spacer2, Spacer3, Spacer4 } from "../components/spacers";
import { Button } from "../components/buttons";
import { FormControl } from "../components/forms";
import { AuthFormWrapper, AuthForm } from "../components/auth";

const ForgotPassword: React.FC = () => {
  const auth = usePatientAuth();
  const [email, setEmail] = useState();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const handleReset = async (event: any) => {
    event.preventDefault();

    if (!email) {
      setError("Please fill in a valid email");
      return;
    }

    try {
      await auth.forgot(email);
      setSuccess(true);
    } catch (error) {
      setError(error?.message);
    }
  };

  if (auth.loading)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <>
      {success ? (
        <MainContentWrapper align="center">
          <Spacer2 />
          <H1>Password reset!</H1>
          <Spacer3 />
          <AuthFormWrapper>
            <p>We have emailed you a password reset link!</p>
            <Spacer3 />
            <Button onClick={() => history.push(AUTH_ROUTE)}>
              <ButtonText>Log in</ButtonText>
            </Button>
            <Spacer4 />
          </AuthFormWrapper>
        </MainContentWrapper>
      ) : (
        <MainContentWrapper align="center">
          <Spacer2 />
          <H1>Enter your email to reset</H1>
          <H1>your password:</H1>
          <Spacer3 />
          <AuthFormWrapper>
            <AuthForm onSubmit={(event: any) => handleReset(event)}>
              <Form.Group controlId="formBasicEmail">
                <FormControl
                  type="email"
                  placeholder="Email"
                  value={email || ""}
                  onChange={(event: any) => setEmail(event.target.value)}
                />
              </Form.Group>
              {error && <p>{error}</p>}
              <Spacer2 />
              <Button>
                <ButtonText>Reset password</ButtonText>
              </Button>
            </AuthForm>
            <Spacer4 />
          </AuthFormWrapper>
        </MainContentWrapper>
      )}
    </>
  );
};

export default ForgotPassword;
