import React, { createContext, useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { PatientProfileDocument, UserDocument } from "../types/user";
import { firebaseAuth, firestore } from "../firebase";
import { getUserDocument, getPatientProfile, patientProfileFromSnapshot } from "./use-patient-auth-repo";
import { PATIENTS_PROFILES_COLLECTION } from "../constants/collections";


type PatientContext = {
  user: UserDocument | null | false;
  token: firebase.auth.IdTokenResult | null;
  profile: PatientProfileDocument | null;
  loading: boolean;
  signIn: Function;
  signUp: Function;
  signOut: Function;
  forgot: Function;
};

const patientContext = createContext<PatientContext>({
  user: null,
  token: null,
  profile: null,
  loading: true,
  signIn: () => {},
  signOut: () => {},
  signUp: () => {},
  forgot: () => {},
});

interface IProps {}
export const ProvideAuth: React.FC<IProps> = (props) => {
  const auth = useProvidePatientAuth();

  return <patientContext.Provider value={auth}>{props.children}</patientContext.Provider>;
};

export const usePatientAuth = () => {
  return useContext(patientContext);
};

function useProvidePatientAuth(): PatientContext {
  const [user, setUser] = useState<UserDocument | null>(null);
  const [token, setToken] = useState<firebase.auth.IdTokenResult | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<PatientProfileDocument | null>(null);

  const signUp = async (email: string, password: string) => {
    try {
      setLoading(true);
      const user = await firebaseAuth.createUserWithEmailAndPassword(email, password);
      user.user?.sendEmailVerification();
    } catch (error) {
      setLoading(false);
      throw new Error("There was an error creating your account. Please try again.");
    }
  };

  const signIn = async (email: string, password: string) => {
    let user;

    try {
      setLoading(true);
      user = await firebaseAuth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      setLoading(false);
      throw new Error("There was an error with your E-Mail/Password combination. Please try again.");
    }

    if (!user.user?.emailVerified) {
      throw new Error("Please verify your email address.");
    }
  };

  const forgot = async (email: string) => {
    try {
      setLoading(true);
      await firebaseAuth.sendPasswordResetEmail(email);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error("The email does not exist. Please try again.");
    }
  };

  const signOut = async () => {
    setLoading(true);
    await firebaseAuth.signOut();
    setUser(null);
    setProfile(null);
    setToken(null);
    setLoading(false);
  };

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser && firebaseUser.emailVerified) {
        const userDocument = await getUserDocument(firebaseUser);
        setUser(userDocument);

        const profile = await getPatientProfile(firebaseUser);
        setProfile(profile);

        const idToken = await firebaseUser.getIdTokenResult();
        setToken(idToken);

        setLoading(false);
      } else {
        await signOut();
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && user.firebaseUser) {
      const unsubscribe = firestore
        .collection(PATIENTS_PROFILES_COLLECTION)
        .doc(user?.firebaseUser?.uid)
        .onSnapshot((snapshot) => {
          const profile = patientProfileFromSnapshot(snapshot);
          setProfile(profile);
        });

      return () => unsubscribe();
    }
  }, [user]);

  return {
    user,
    token,
    profile,
    loading,
    signIn,
    signUp,
    signOut,
    forgot,
  };
}
