import styled, { css } from "styled-components";
import { Form, FormControl as BSFormControl } from "react-bootstrap";

const baseInputStyles = css`
  border-color: ${(props) => props.theme.colors.alto};
  border-width: 2px;
  border-radius: 7px;
  padding: 20px 10px;

  // Remove focus outline
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.alto};
    box-shadow: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.silverChalice};
  }

  &:valid:not(select),
  &:invalid:not(select),
  &.is-valid:not(select),
  &.is-invalid:not(select) {
    padding-right: 0 !important;
    // CAR-71 - Don't check / exclamation for validated input
    background-image: none !important;
    // CAR-71 - Don't use green border for validated input
    border-color: ${(props) => props.theme.colors.alto} !important;
  }
`;

export const FormControl = styled(Form.Control)`
  ${baseInputStyles}
`;

export const TextAreaFormControl = styled(BSFormControl)`
  ${baseInputStyles};
  width: 100%;
  padding: 8px;
  border-width: 2px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.tundora};

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.silver};
  }
`;

export const DividerSpan = styled.span`
  width: 20px;
  display: inline-block;
  text-align: center;
`;

export const DropdownFormControl = styled(BSFormControl)`
  ${baseInputStyles}

  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, ${(props) => props.theme.colors.alto}, ${(props) => props.theme.colors.alto});
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
`;
