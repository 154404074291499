import styled from "styled-components";
import { theme } from "./theme";

export const TabsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.div<TabProps>`
  flex: 1;
  background-color: ${(props) => (props.active ? theme.colors.jellyBean : "white")};
  color: ${(props) => (props.active ? "white" : theme.colors.tundora)};
  padding: 18px;
  text-align: center;
  cursor: pointer;
`;
