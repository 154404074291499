import React from "react";
import { Form, FormLabel, Col } from "react-bootstrap";
import { FormControl as InputFormControl } from "./forms";
import { requiredStar } from "./text";

interface TextFormGroupProps {
  field: string;
  md: string;
  value?: any;
  onChange?: Function;
  labelHtml?: any;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  feedbackHtml?: any;
  ariaLabel: string;
}
const TextFormGroup = React.forwardRef<HTMLInputElement, TextFormGroupProps>((props, ref) => {
  const { field, labelHtml, required, ariaLabel, value, onChange, feedbackHtml, md, minLength, maxLength } = props;
  return (
    <Form.Group as={Col} md={md}>
      {labelHtml && (
        <FormLabel htmlFor={field}>
          {labelHtml}
          {required && requiredStar}
        </FormLabel>
      )}
      <InputFormControl
        ref={ref}
        value={value ?? ''}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
        onChange={onChange}
        id={field}
        aria-label={ariaLabel}
        placeholder={ariaLabel}
      />
      <Form.Control.Feedback type="invalid">{feedbackHtml}</Form.Control.Feedback>
    </Form.Group>
  );
});

export default TextFormGroup;
