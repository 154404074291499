export type Applicant = {
  id: string;
  displayName: string;
  favouriteThingCaregiving: string[];
  photoURL: string;
  timeCaregiving: string;
  greatestStrengthCaregiving: string;
  bio: string;
  rating: number;
  shiftsWorked: number;
};

export const fromFirebase = (doc: any) => {
  const applicant: Applicant = {
    id: doc.id,
    displayName: doc.data().displayName,
    favouriteThingCaregiving: doc.data().favouriteThingCaregiving,
    photoURL: doc.data().photoURL,
    timeCaregiving: doc.data().timeCaregiving,
    greatestStrengthCaregiving: doc.data().greatestStrengthCaregiving,
    bio: doc.data().bio,
    rating: doc.data().rating || 0,
    shiftsWorked: doc.data().shiftsWorked || 0,
  };

  return applicant;
};
