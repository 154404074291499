import React from "react";

const PasswordRequirements: React.FC = () => {
  return (
    <div className="text-left">
      <p className="mb-0">Passwords should:</p>
      <ul>
        <li>Be at least 8 characters long</li>
        <li>Contain at least one number</li>
        <li>Contain at least one uppercase character</li>
      </ul>
    </div>
  );
};

export default PasswordRequirements;
