import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled.button`
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: ${(props) => props.theme.colors.jellyBean};
  color: white;
  border: none;
  border-radius: 50px;
  padding: 18px 40px;
  font-size: 18px;

  &:hover {
    background-color: ${(props) => props.theme.colors.resolutionBlue};
  }

  &:focus {
    outline: none;
  }
`;

export const ButtonAvailability = styled.div`
  filter: opacity(0.5);
  pointer-events: none;
`;

export const LinkButton = styled(Link).attrs((props) => ({
  className: "btn",
}))`
  background-color: ${(props) => props.theme.colors.jellyBean};
  color: white;
  border: none;
  border-radius: 30px;
  padding: 14px 40px;

  &:hover {
    background-color: ${(props) => props.theme.colors.resolutionBlue};
    color: white;
  }
`;

export const BackButton = styled(Button)`
  color: ${(props) => props.theme.colors.jellyBean};
  text-decoration: none;
  background-color: transparent;
  text-align: center;

  &:hover {
    background-color: transparent;
  }
`;
