// TODO
// Send email to office with all job details and feedback
import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Button, BackButton, ButtonAvailability } from "./buttons";
import { Spacer2, Spacer4, Spacer1 } from "./spacers";
import { ModalCardTitle } from "./modal-popup";
import { TextSize } from "./text";
import { PageLoading } from "./loading";
import { CloseIcon, LoadingWrapper, ModalCard, ModalCardBackdrop, ModalContentWrapper } from "./modal-parts";
import styled from "styled-components";
// eslint-disable-next-line no-unused-vars
import { FormControlElement } from "../types/form";
import { DropdownFormControl, TextAreaFormControl } from "./forms";
import { theme } from "./theme";
import { firestore } from "../firebase";
import { JOBS_COLLECTION } from "../constants/collections";
import { PATIENT_JOBS_ROUTE } from "../constants/routes";
import { useHistory } from "react-router-dom";
import { JobFields, JobStatus } from "../types/jobs";

const MAX_REPORT_CHARS = 1000;
const OPTION_LATE = "Caregiver was late";
const OPTION_DID_NOT_ARRIVE = "Caregiver did not arrive";
const OPTION_ELSE = "Something else";

interface IProps {
  isVisible: boolean;
  onConfirm: Function;
  onCancel: Function;
  jobId: string | undefined;
  readonly?: boolean;
  clientFeedbackOption?: string | undefined;
  clientFeedbackInfo?: string | undefined;
}

const ReportProblemModal: React.FC<IProps> = (props) => {
  const { isVisible, onCancel, jobId, readonly, clientFeedbackOption, clientFeedbackInfo } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null | undefined>(null);
  const [option, setOption] = useState<string>(OPTION_LATE);
  const [info, setInfo] = useState<string>("");
  const [reportSent, setReportSent] = useState<boolean>(false);
  const history = useHistory();

  const reportProblem = async () => {
    setLoading(true);
    try {
      await firestore
        .collection(JOBS_COLLECTION)
        .doc(jobId)
        .set(
          {
            [JobFields[JobFields.clientFeedbackOption]]: option,
            [JobFields[JobFields.clientFeedbackInfo]]: info,
            [JobFields[JobFields.status]]: JobStatus.CLIENT_REPORTED_PROBLEM,
          },
          { merge: true }
        );
      setReportSent(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError("Sorry, there was a problem saving the data. Please try again.");
      setLoading(false);
    }
  };

  return isVisible ? (
    <ModalCardBackdrop>
      <ReportProblemModalCard>
        <ModalCardTitle className="d-flex justify-content-between align-items-center">
          <div style={{ width: "60px" }} />
          <span>{readonly ? "Reported problem" : "Report a problem"}</span>
          <CloseIcon
            icon={faTimes}
            color="white"
            size="1x"
            onClick={() => (reportSent ? history.push(PATIENT_JOBS_ROUTE) : onCancel())}
          />
        </ModalCardTitle>
        <Spacer2 />
        {!reportSent ? (
          readonly ? (
            <ReportProblemModalContentWrapper>
              <p>
                Reason: <ReadOnlyText>{clientFeedbackOption}</ReadOnlyText>
              </p>
              <p className="mb-0">Message: </p>
              <p>
                <ReadOnlyText>{clientFeedbackInfo}</ReadOnlyText>
              </p>
            </ReportProblemModalContentWrapper>
          ) : (
            <ReportProblemModalContentWrapper>
              <p>Please let us know what went wrong with this job.</p>
              <Spacer1 />
              <div className="d-flex align-items-center">
                <p className="mb-0">Choose one option:</p>
                <Spacer1 />
                <DropdownFormControl
                  as="select"
                  value={option}
                  onChange={(event: React.ChangeEvent<FormControlElement>) => setOption(event.currentTarget.value)}
                >
                  <option>{OPTION_LATE}</option>
                  <option>{OPTION_DID_NOT_ARRIVE}</option>
                  <option>{OPTION_ELSE}</option>
                </DropdownFormControl>
              </div>
              <Spacer2 />
              <ReportProblemTextAreaFormControl
                as="textarea"
                rows={8}
                value={info}
                onChange={(event: React.ChangeEvent<FormControlElement>) =>
                  setInfo(event.currentTarget.value.substring(0, MAX_REPORT_CHARS))
                }
                placeholder="Please describe here the problem in detail..."
              />
              <p className="pb-0 info">Characters left {MAX_REPORT_CHARS - info.length}</p>
            </ReportProblemModalContentWrapper>
          )
        ) : (
          <ReportProblemModalContentWrapper>
            <p className="text-center w-100">Thank you!</p>
            <p className="pr-5 pl-5 pb-3">
              One of our representatives will contact you during the next working hours to help you on this problem.
            </p>
          </ReportProblemModalContentWrapper>
        )}
        {error && (
          <div className="d-flex justify-content-center">
            <p className="text-danger text-center w-50 p-2">{error}</p>
            <Spacer2 />
          </div>
        )}
        {loading ? (
          <LoadingWrapper>
            <PageLoading />
          </LoadingWrapper>
        ) : !reportSent ? (
          !readonly && (
            <>
              <Row className="justify-content-md-center">
                {option && info ? (
                  <Button onClick={() => reportProblem()}>{"Report"}</Button>
                ) : (
                  <ButtonAvailability>
                    <Button onClick={() => {}}>{"Report"}</Button>
                  </ButtonAvailability>
                )}
              </Row>
              <Row className="justify-content-md-center">
                <BackButton onClick={() => onCancel()}>Cancel</BackButton>
              </Row>
            </>
          )
        ) : (
          <>
            <Row className="justify-content-md-center">
              <Button onClick={() => history.push(PATIENT_JOBS_ROUTE)}>{"Close"}</Button>
            </Row>
          </>
        )}
        <Spacer4 />
      </ReportProblemModalCard>
    </ModalCardBackdrop>
  ) : (
    <></>
  );
};

const ReportProblemTextAreaFormControl = styled(TextAreaFormControl)`
  &::placeholder {
    color: ${(props) => props.theme.colors.gray};
  }
`;

const ReadOnlyText = styled.span`
  color: ${(props) => props.theme.colors.gray};
`;

const ReportProblemModalCard = styled(ModalCard)`
  width: 650px;
  margin-left: -320px;
`;

const ReportProblemModalContentWrapper = styled(ModalContentWrapper)`
  padding-bottom: 0;
  align-items: start;

  p {
    font-size: ${(props) => TextSize.large};
  }

  .info {
    display: flex;
    font-size: ${(props) => TextSize.regular};
    color: ${(props) => theme.colors.gray};
    justify-content: flex-end;
    width: 100%;
  }
`;

export default ReportProblemModal;
