import React, { useState, useEffect } from "react";
import useRequireAuth from "../hooks/use-require-auth";
import Row from "react-bootstrap/Row";
import { firebaseFunctions } from "../firebase";
import { GET_CARD_FUNCTION } from "../constants/callable-functions";
// eslint-disable-next-line no-unused-vars
import { PaymentMethod } from "@stripe/stripe-js";
import { PageLoading } from "../components/loading";
import { MainContentWrapper } from "../components/layout";
import { Spacer1, Spacer2, Spacer3, Spacer4 } from "../components/spacers";
import { Tab, TabsWrapper } from "../components/tabs";
import {
  TabLabel,
  TextLink,
  TextSize,
  LinkTextSeparator,
  TextLinkDisabled,
  H1,
  H3,
  BigBoldLabel,
  BigLabel,
} from "../components/text";
import { useHistory } from "react-router-dom";
import { PATIENT_ACCOUNT_HISTORY_ROUTE, PATIENT_PROFILE_ROUTE } from "../constants/routes";
import styled from "styled-components";
import { AccountWrapper } from "./patient-account-history";
import CreditCardAndRetryPaymentModal from "../components/credit-card-and-retry-payment-modal";
import PasswordModal from "../components/password-modal";
import useScrollTop from "../hooks/use-scroll-to-top";

const PatientAccountInfo: React.FC = () => {
  const requireAuth = useRequireAuth();
  const [loading, setLoading] = useState(true);
  const [creditCard, setCreditCard] = useState<PaymentMethod>();
  const [creditCardModal, setCreditCardModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [error, setError] = useState<string>();
  const history = useHistory();
  useScrollTop();

  useEffect(() => {
    getCard().then(() => setLoading(false));
  }, []);

  const getCard = async () => {
    try {
      const cardsCall = firebaseFunctions.httpsCallable(GET_CARD_FUNCTION);
      const result = await cardsCall();

      if (result && result.data) {
        setCreditCard(result.data);
      }
    } catch (error) {
      setError("Sorry, we were unable to load this section. Please try again reloading this page.");
      console.error(error);
    }
  };

  if (!requireAuth.user)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <MainContentWrapper>
      <Spacer4 />
      <TabsWrapper>
        <Tab active={false} onClick={() => history.push(PATIENT_PROFILE_ROUTE)}>
          <TabLabel>Profile</TabLabel>
        </Tab>
        <Tab active={true}>
          <TabLabel>Account</TabLabel>
        </Tab>
      </TabsWrapper>
      <Spacer2 />
      <AccountWrapper>
        <Row>
          <TextLink size={TextSize.large} to={PATIENT_ACCOUNT_HISTORY_ROUTE}>
            Account History
          </TextLink>
          <Spacer2 />
          <LinkTextSeparator>|</LinkTextSeparator>
          <Spacer2 />
          <TextLinkDisabled size={TextSize.large}>Account Info</TextLinkDisabled>
        </Row>
        <Spacer1 />
        <H1 className="text-center">Account Info</H1>
        <Spacer3 />
        <InfoRow className="border-bottom">
          <H3>Payment</H3>
          {!loading ? (
            <div>
              <BigBoldLabel>Payment Method</BigBoldLabel>
              <Spacer1 />
              {error ? (
                <p className="text-danger">{error}</p>
              ) : (
                <>
                  {creditCard && creditCard.card && (
                    <CreditCard
                      last4={creditCard.card?.last4}
                      brand={creditCard.card?.brand}
                      exp_month={creditCard.card?.exp_month}
                      exp_year={creditCard.card?.exp_year}
                    />
                  )}
                  <Spacer2 />
                  <TextLink
                    to={""}
                    onClick={(e) => {
                      e.preventDefault();
                      setCreditCardModal(true);
                    }}
                  >
                    {creditCard ? "Edit" : "Add"}
                  </TextLink>
                </>
              )}
            </div>
          ) : (
            <LoadingWrapper>
              <PageLoading />
            </LoadingWrapper>
          )}
        </InfoRow>
        <CreditCardAndRetryPaymentModal
          isVisible={creditCardModal}
          oldCardId={creditCard?.id}
          onCancel={() => setCreditCardModal(false)}
          onConfirm={() => history.go(0)}
        />
        <InfoRow>
          <H3>Password</H3>
          <div>
            <p>********</p>
            <Spacer2 />
            <TextLink
              to={""}
              onClick={(e) => {
                e.preventDefault();
                setPasswordModal(true);
              }}
            >
              Change Password
            </TextLink>
          </div>
        </InfoRow>
        <PasswordModal isVisible={passwordModal} onCancel={() => setPasswordModal(false)} />
      </AccountWrapper>
    </MainContentWrapper>
  );
};

const InfoRow = styled(Row)`
  padding: 32px 32px 32px 0;

  h3 {
    width: 250px;
  }

  p {
    margin: 0;
    line-height: 18px;
  }
`;

const LoadingWrapper = styled.div`
  div {
    margin-top: 0;
  }
`;

interface CardProps {
  last4: string;
  brand: string;
  exp_month: number;
  exp_year: number;
}

const CreditCard: React.FC<CardProps> = (props: CardProps) => {
  const { last4, brand, exp_month, exp_year } = props;

  return (
    <>
      <div>
        <BigBoldLabel className="d-inline">{brand.toUpperCase()}</BigBoldLabel>
        <BigLabel className="d-inline"> **** {last4}</BigLabel>
      </div>
      <BigLabel>
        {exp_month}/{exp_year}
      </BigLabel>
    </>
  );
};

export default PatientAccountInfo;
