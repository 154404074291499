import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ModalCardBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1100;
`;

export const ModalCard = styled.div`
  background: white;
  width: 600px;
  height: auto;
  position: fixed;
  top: 40%;
  margin-top: -250px;
  left: 50%;
  margin-left: -300px;

  box-shadow: 0 3px 4px 2px ${(props) => props.theme.colors.boulder};
`;

export const ModalContentWrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .StripeElement {
    width: 400px;
    border: 2px solid ${(props) => props.theme.colors.alto};
    border-radius: 7px;
    padding: 14px;
  }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  width: 46px !important;
  height: 46px;
  margin: 8px;
`;

export const LoadingWrapper = styled.div`
  height: 116px;

  div {
    margin-top: 10px;
  }
`;
