import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Button, BackButton } from "./buttons";
import { Spacer2, Spacer4, Spacer3 } from "./spacers";
import { ModalCardTitle } from "./modal-popup";
import { H3, TextSize } from "./text";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { firebaseFunctions } from "../firebase";
import { SAVE_CARD_FUNCTION, REMOVE_CARD_FUNCTION } from "../constants/callable-functions";
import { PageLoading } from "./loading";
import { CloseIcon, LoadingWrapper, ModalCard, ModalCardBackdrop, ModalContentWrapper } from "./modal-parts";
import styled from "styled-components";
import { INFO_EMAIL, INFO_PHONE } from "../constants/info";
import { useHistory } from "react-router-dom";

interface IProps {
  isVisible: boolean;
  onConfirm: Function;
  onCancel: Function;
  oldCardId?: string;
  retryPayment?: boolean;
}

const DEFAULT_ERROR_MESSAGE = "There was an error saving your card. Please try again.";

const CreditCardAndRetryPaymentModal: React.FC<IProps> = (props) => {
  const { oldCardId, retryPayment } = props;
  const { isVisible, onCancel, onConfirm } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null | undefined>(null);
  const [cardId, setCardId] = useState(oldCardId);
  const [retriesExhausted, setRetriesExhausted] = useState<boolean>(false);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const removeCard = async () => {
    if (!cardId) return;

    try {
      const removeCardCall = firebaseFunctions.httpsCallable(REMOVE_CARD_FUNCTION);
      await removeCardCall({ paymentId: cardId });
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
      console.error(error);
    }
  };

  const saveCard = async () => {
    setLoading(true);
    setError(null);
    let setupIntent;

    try {
      const setupIntentCall = firebaseFunctions.httpsCallable(SAVE_CARD_FUNCTION);
      setupIntent = await setupIntentCall();
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
      console.error(error);
    }

    if (!stripe || !elements) {
      setError(DEFAULT_ERROR_MESSAGE);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setLoading(false);
      setError(DEFAULT_ERROR_MESSAGE);
      return;
    }

    try {
      let result = await stripe.confirmCardSetup(setupIntent?.data.client_secret, {
        payment_method: { card: cardElement },
      });

      if (result.error) {
        setLoading(false);
        setError(result.error.message);
        return;
      }
    } catch (error) {
      setLoading(false);
      setError(DEFAULT_ERROR_MESSAGE);
      console.error(error);
      return;
    }

    await removeCard();

    if (retryPayment) {
      try {
        await onConfirm();
      } catch (error) {
        setCardId(undefined);
        setLoading(false);
        setRetriesExhausted(true);
      }
    } else {
      await onConfirm();
    }
  };

  return isVisible ? (
    <ModalCardBackdrop>
      <ModalCard>
        <ModalCardTitle className="d-flex justify-content-between align-items-center">
          <div style={{ width: "60px" }} />
          <span>{retryPayment ? "Retry your payment" : "Credit Card Info"}</span>
          <CloseIcon
            icon={faTimes}
            color="white"
            size="1x"
            onClick={() => (retriesExhausted ? history.go(0) : onCancel())}
          />
        </ModalCardTitle>
        <Spacer2 />
        {retriesExhausted ? (
          <RetryExhausted>
            <Spacer3 />
            <div className="d-flex justify-content-center">
              <H3>Your payment could not be completed.</H3>
            </div>
            <div className="d-flex justify-content-center">
              <Spacer2 />
              <p className="text-center w-75">
                Sorry, we could not complete your payment.
                <br />
                Please contact us at <a href={`mailto:${INFO_EMAIL}`}>{INFO_EMAIL}</a>
                <br />
                or calling at <a href={`tel:${INFO_PHONE}`}>{INFO_PHONE}</a>.
              </p>
            </div>
            <Spacer2 />
            <Row className="justify-content-md-center">
              <Button onClick={() => history.go(0)}>Close</Button>
            </Row>
          </RetryExhausted>
        ) : (
          <>
            <ModalContentWrapper>
              <H3>{retryPayment ? "Your credit card has been denied:" : "Enter your payment method:"}</H3>
              {retryPayment && (
                <Hint>
                  Please contact your CC provider or
                  <br /> use a different credit card.
                </Hint>
              )}
              <Spacer2 />
              <CardElement />
            </ModalContentWrapper>
            {error && (
              <div className="d-flex justify-content-center">
                <p className="text-danger text-center w-50 p-2">{error}</p>
                <Spacer2 />
              </div>
            )}
            {loading ? (
              <LoadingWrapper>
                <PageLoading />
              </LoadingWrapper>
            ) : (
              <>
                <Row className="justify-content-md-center">
                  <Button onClick={() => saveCard()}>{retryPayment ? "Retry payment" : "Save"}</Button>
                </Row>
                <Row className="justify-content-md-center">
                  <BackButton onClick={() => onCancel()}>Cancel</BackButton>
                </Row>
              </>
            )}
          </>
        )}
        <Spacer4 />
      </ModalCard>
    </ModalCardBackdrop>
  ) : (
    <></>
  );
};

const RetryExhausted = styled.div`
  a {
    text-decoration: underline;
  }
`;

const Hint = styled.p`
  text-align: center;
  font-size: ${TextSize.regular};
`;

export default CreditCardAndRetryPaymentModal;
