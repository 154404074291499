import React from "react";
import { MainContentWrapper } from "../components/layout";
import useRequireAuth from "../hooks/use-require-auth";
import { PageLoading } from "../components/loading";
import { Spacer3 } from "../components/spacers";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps } from "react-router-dom";
import JobChat from "../components/chat/job-chat";
import { Tab, TabsWrapper } from "../components/tabs";
import { TabLabel } from "../components/text";

export interface MatchParams {
  messageId: string;
}

interface MyMessageProps extends RouteComponentProps<MatchParams> {}

const MyMessage: React.FC<MyMessageProps> = (props) => {
  const requireAuth = useRequireAuth();
  const jobId = props.match.params.messageId;
  if (!requireAuth.user)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <>
      <MainContentWrapper customContainer={true}>
        <Spacer3 />
        <TabsWrapper>
          <Tab active={true}>
            <TabLabel>
              <span>My Messages</span>
            </TabLabel>
          </Tab>
        </TabsWrapper>
        <Spacer3 />
        <JobChat jobId={jobId} />
      </MainContentWrapper>
    </>
  );
};

export default MyMessage;
