import React from "react";
import Header, { HEADER_HEIGHT } from "./header";
import Footer from "./footer";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { theme } from "./theme";

interface IProps {}
const Layout: React.FC<IProps> = (props) => {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
};

interface MainContentWrapperProps {
  color?: string;
  align?: string;
  marginTop?: string;
  customContainer?: boolean;
}

export const MainContentWrapper: React.FC<MainContentWrapperProps> = (props) => {
  return (
    <MainWrapper color={props.color} align={props.align} margintop={props.marginTop} fluid>
      {
        props.customContainer ? 
          <CustomContainer>{props.children}</CustomContainer> : 
          <Container>{props.children}</Container>
      }
    </MainWrapper>
  );
};

const MainWrapper = styled(Container)`
  display: flex;
  align-items: ${(props) => props.align || "normal"};
  margin-top: ${(props) => (props.margintop ? props.margintop : HEADER_HEIGHT)}px;
  min-height: 700px;
  background-color: ${(props) => props.color || theme.colors.wildSand};
`;

const CustomContainer = styled.div`
  width: 95%;
  margin: 0 auto;
`;

export const FlexContainer = styled(Container).attrs((props) => ({
  className: "d-flex",
}))``;

export const PageLoadingWrapper = styled(FlexContainer)`
  justify-content: center;
  margin-top: 90px;
`;

export default Layout;
