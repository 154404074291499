import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

export const TextSize = {
  small: "14px",
  regular: "18px",
  big: "20px",
  large: "22px",
};

export const H1 = styled.h1`
  font-size: 42px;
`;

export const H2 = styled.h2`
  font-size: 36px;
  font-family: "Varta", sans-serif;
`;

export const H3 = styled.h3`
  font-size: 30px;
  font-family: "Varta", sans-serif;
`;

export const H4 = styled.h4`
  font-size: 24px;
  font-family: "Varta", sans-serif;
`;

export const RegularText = styled.p`
  font-family: "Gibson Regular", sans-serif;
  color: ${(props) => props.theme.colors.tundora};
  font-size: 18px;
`;

export const ButtonText = styled.p`
  font-family: "Gibson Regular", sans-serif;
  font-size: ${TextSize.regular};
  margin: 0;
`;

interface TextLinkProps {
  size?: string;
}

export const TextLink = styled(Link)<TextLinkProps>`
  font-family: "Varta", sans-serif;
  font-size: ${(props) => props.size || TextSize.regular};
  color: ${(props) => props.theme.colors.jellyBean};
  margin: 0;

  &:hover {
    color: ${(props) => props.theme.colors.jellyBean};
  }
`;

export const TextLinkDisabled = styled.p<TextLinkProps>`
  font-family: "Varta", sans-serif;
  font-size: ${(props) => props.size || TextSize.regular};
  color: ${(props) => props.theme.colors.tundora};
  margin: 0;
`;

export const LinkTextSeparator = styled.p`
  font-size: 20px;
`;

export const Italic = styled.p<TextLinkProps>`
  font-family: "Varta", sans-serif;
  font-size: ${(props) => props.size || TextSize.regular};
  font-style: italic;
`;

// TODO unify the following styles accross the site

export const Label = styled.p`
  font-size: 14px;
  font-family: "Varta", sans-serif;
`;

export const BigLabel = styled.p`
  font-size: 18px;
  margin-bottom: 6px;
`;

export const BigBoldLabel = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: "Varta", sans-serif;
  margin: 0;
`;

export const TabLabel = styled.span`
  font-size: 26px;
`;

export const Notes = styled.p`
  font-size: 20px;
  white-space: pre-line;
`;

export const BoldLarge = styled.p`
  font-weight: bold;
  font-size: ${TextSize.large};
  margin-bottom: 0;
`;

export const requiredStar = <span className="text-danger">*</span>;
