import React from "react";
import styled from "styled-components";
import { theme } from "./theme";

interface IProps {
  steps: number[];
  currentStep: number;
  setCurrentStep: Function;
}

const JobProgress: React.FC<IProps> = (props) => {
  const { steps, currentStep } = props;

  return (
    <BubbleWrapper>
      {steps.map((step, key) => (
        <Bubble key={key} active={currentStep === step}>
          {step}
        </Bubble>
      ))}
    </BubbleWrapper>
  );
};

const BubbleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 250px;
  margin: 0 auto;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 0.001em solid ${(props) => props.theme.colors.alto};
    width: 100%;
    transform: translateY(-50%);
  }
`;

interface BubbleProps {
  active: boolean;
}

const Bubble = styled.div<BubbleProps>`
  color: ${(props) => (props.active ? "white" : props.theme.colors.alto)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding-top: 3px;
  background: ${(props) => (props.active ? theme.colors.jellyBean : "white")};
  border: 1px solid ${(props) => props.theme.colors.alto};
  z-index: 1;
  cursor: default;
`;

export default JobProgress;
