/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { useHistory } from "react-router-dom";
import { ButtonText, H1 } from "../components/text";
import { MainContentWrapper } from "../components/layout";
import { AUTH_ROUTE, RESET_PASSWORD_COMPLETE_ROUTE } from "../constants/routes";
import { PageLoading } from "../components/loading";
import { Spacer2, Spacer3 } from "../components/spacers";
import { Button } from "../components/buttons";
import queryString from "query-string";
import { useLocation } from "react-router";
import styled from "styled-components";
import { INFO_PHONE } from "../constants/info";
import { firebaseAuth } from "../firebase";
import Form from "react-bootstrap/Form";
import { FormControl } from "../components/forms";
import PasswordRequirements from "../components/password-requirements";
import { PASSWORD_REGEX } from "../constants/users";

const VerifyEmailResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();
  const [password, setPassword] = useState<string>("");
  const history = useHistory();
  const location = useLocation();
  const params: any = queryString.parse(location.search);

  useEffect(() => {
    if ((params.mode !== "verifyEmail" && params.mode !== "resetPassword") || !params.oobCode) {
      setLoading(false);
      return;
    }

    if (params.mode === "verifyEmail") {
      firebaseAuth
        .applyActionCode(params.oobCode)
        .then((_) => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((_) => {
          setSuccess(false);
          setLoading(false);
        });
    } else {
      firebaseAuth
        .verifyPasswordResetCode(params.oobCode)
        .then((_) => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((_) => {
          setSuccess(false);
          setLoading(false);
        });
    }
  }, []);

  const resetPassword = () => {
    if (!password.match(PASSWORD_REGEX)) {
      setError("The password does not match the requirements.");
      return;
    }

    firebaseAuth.confirmPasswordReset(params.oobCode, password).then(
      (_) => {
        history.push(RESET_PASSWORD_COMPLETE_ROUTE);
      },
      (_) => {
        setError(
          `There was an error resetting your password. Please try again or contact us at <a href="tel:${INFO_PHONE}">${INFO_PHONE}.`
        );
      }
    );

    history.push(RESET_PASSWORD_COMPLETE_ROUTE);
  };

  return (
    <>
      {loading ? (
        <MainContentWrapper align="center">
          <Spacer2 />
          <H1>Verifying your email</H1>
          <PageLoading />
        </MainContentWrapper>
      ) : success ? (
        params.mode === "verifyEmail" ? (
          <MainContentWrapper align="center">
            <Spacer2 />
            <H1>Your email has been verified</H1>
            <Spacer3 />
            <Button onClick={() => history.push(AUTH_ROUTE)}>
              <ButtonText>Continue</ButtonText>
            </Button>
          </MainContentWrapper>
        ) : (
          <MainContentWrapper align="center">
            <Spacer2 />
            <H1>Type a new password to reset it</H1>
            <Spacer3 />
            <PasswordFormWrapper>
              <Form.Group controlId="formBasicPassword">
                <FormControl
                  type="password"
                  placeholder="Password"
                  onChange={(event: any) => setPassword(event.target.value)}
                />
              </Form.Group>
              {error && <p className="text-danger">{error}</p>}
              <PasswordRequirements />
              <Spacer3 />
              <Button onClick={resetPassword}>
                <ButtonText>Reset password</ButtonText>
              </Button>
            </PasswordFormWrapper>
          </MainContentWrapper>
        )
      ) : (
        <MainContentWrapper align="center">
          <Spacer2 />
          <H1>We found an error</H1>
          <Spacer3 />
          <TextWrapper>
            <p>Please try again clicking on the link provided on the email.</p>
            <p>
              If this error persist, please contact us at <a href={`tel:${INFO_PHONE}`}>{INFO_PHONE}</a>.
            </p>
          </TextWrapper>
        </MainContentWrapper>
      )}
    </>
  );
};

const TextWrapper = styled.div`
  p {
    font-size: 18px;
  }

  a {
    text-decoration: underline;
  }
`;

const PasswordFormWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
`;

export default VerifyEmailResetPassword;
