import { useEffect, useState } from "react";
import { firestore } from "../firebase";
import { JOBS_COLLECTION, CHATS_COLLECTION } from "../constants/collections";

export const useUnseenMessages = (jobId: string) => {
  const [unseenCount, setUnseenCount] = useState(0);

  useEffect(() => {
    if (!jobId) return;

    const jobDocRef = firestore.collection(JOBS_COLLECTION).doc(jobId);

    const fetchConfirmedApplicantAndUnseenMessages = async () => {
      // Obtener el job para buscar el confirmedApplicant
      const jobDoc = await jobDocRef.get();
      const jobData = jobDoc.data();

      if (!jobData?.confirmedApplicant) {
        console.error("No confirmed applicant for this job");
        return;
      }

      const confirmedApplicantId = jobData.confirmedApplicant;

      // Subscribe to unseen messages from the confirmed applicant
      const unsubscribe = jobDocRef
        .collection(CHATS_COLLECTION)
        .where("seen", "==", false)
        .where("senderId", "==", confirmedApplicantId)
        .onSnapshot((snapshot) => {
          setUnseenCount(snapshot.size);
        });

      return () => unsubscribe();
    };

    fetchConfirmedApplicantAndUnseenMessages();
  }, [jobId]);

  return unseenCount;
};
