/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { firebaseFunctions } from "../firebase";
import { AdminCaregiversTable } from "./admin-caregivers-table";
import { Spacer5 } from "./spacers";

interface AdminCaregiverTabProps {
  isActiveTab: boolean;
}

const AdminCaregiverTab: React.FC<AdminCaregiverTabProps> = ({ isActiveTab }) => {
  const itemsPerPage = 20;
  const [page, setPage] = useState(1);
  const [caregivers, setCaregivers] = useState();
  const [queryResult, setQueryResult] = useState<any>();
  const [loadingCaregivers, setLoadingCaregivers] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [loadedData, setLoadedData] = useState<boolean>(false);

  const getCaregiverList = (searchText: string = "", page: number = 1, operationCity: string = "") => {
    setLoadingCaregivers(true);
    const caregiversCall = firebaseFunctions.httpsCallable("caregiversList");
    caregiversCall({
      page,
      searchText,
      itemsPerPage,
      operationCity
    }).then(result => {
      const { data } = result;
      setCaregivers(data.caregivers);
      setQueryResult(data);
      setLoadedData(true);
    }).finally(() => setLoadingCaregivers(false));
  };

  const filterByName = (searchText: string) => {
    const text = searchText.split(" ").map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(" ");
    setSearchText(text);
  }

  const getSelectedCity = () => selectedCity === "All" ? "" : selectedCity;

  const onPageChange = (selectedPage: number) => {
    setPage(selectedPage);
    getCaregiverList(searchText, selectedPage, getSelectedCity());
  };

  const filterByOpCityActive = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setSelectedCity(value);
    getCaregiverList(searchText, page, value === "All" ? "" : value);
  };

  const onToggleCaregiverAccount = (uid: string, disabled: boolean) => {
    const data = { uid, disabled };
    setLoadingCaregivers(true);
    const toggleCaregiverAccount = firebaseFunctions.httpsCallable("toggleCaregiverAccount");
    toggleCaregiverAccount(data).then(() => {
      setLoadingCaregivers(false);
      getCaregiverList(searchText, page, getSelectedCity());
    });
  };

  useEffect(() => {
    if (!caregivers && isActiveTab) {
      getCaregiverList();
    }
  }, [caregivers, isActiveTab]);

  useEffect(() => {
    if (loadedData) {
      const getData = setTimeout(() => {
        getCaregiverList(searchText, page, getSelectedCity());
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [searchText]);

  return (
    <>
      <h2 className="pt-3 mb-1">Active Caregivers</h2>
      <AdminCaregiversTable
        caregivers={caregivers || []}
        onToggleCaregiverAccount={onToggleCaregiverAccount}
        operationCityFilter={selectedCity}
        filterByOperationCity={filterByOpCityActive}
        onSearchBy={filterByName}
        pagination
        totalPages={queryResult?.total_pages}
        onPageChange={onPageChange}
        selectedPage={page}
        isLoading={loadingCaregivers}
      />
      <Spacer5 />
    </>
  );
};

export { AdminCaregiverTab };
