import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-regular-svg-icons";
// eslint-disable-next-line no-unused-vars
import { JobApplicant } from "../types/jobs";
import { theme } from "./theme";
// eslint-disable-next-line no-unused-vars
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
// eslint-disable-next-line no-unused-vars
import { Applicant } from "../types/applicant";

interface AvatarProps {
  single?: boolean;
  width?: number;
  noFilters?: boolean;
  applicant: JobApplicant | Applicant | undefined;
  avatarIconSize?: SizeProp;
  color?: string;
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { applicant, avatarIconSize, color = theme.colors.jellyBean } = props;
  const [imageBroken, setImageBroken] = useState(false);

  if (applicant?.photoURL && !imageBroken) {
    return (
      <AvatarContainer
        {...props}
        src={applicant.photoURL}
        alt={applicant.displayName}
        onError={() => setImageBroken(true)}
      />
    );
  }

  return <FontAwesomeIcon icon={faUserCircle} size={avatarIconSize || "3x"} color={color} />;
};

const AvatarContainer = styled.img<AvatarProps>`
  border-radius: 50%;
  filter: ${(props) => (!props.noFilters ? "contrast(0.4) brightness(1.5)" : "none")};
  margin-left: ${(props) => (props.single ? "0" : "-30px")};
  width: ${(props) => (props.width ? `${props.width}px` : "80px")};
  height: ${(props) => (props.width ? `${props.width}px` : "80px")};
  background-color: white;
  mix-blend-mode: inherit;
`;
