import React, { useEffect, useState } from "react";
import useRequireAuth from "../hooks/use-require-auth";
import styled from "styled-components";
import { AvatarIcon } from "../components/icons";
import { Link, useHistory } from "react-router-dom";
import { PATIENT_HOME_NO_PROFILE_ROUTE, PATIENT_HOME_ROUTE, PATIENT_PROFILE_ROUTE } from "../constants/routes";
import { H1 } from "../components/text";
import { PageLoading } from "../components/loading";
import { Spacer2, Spacer4 } from "../components/spacers";
import { MainContentWrapper } from "../components/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../components/theme";
import CreditCardAndRetryPaymentModal from "../components/credit-card-and-retry-payment-modal";
import { usePatientAuth } from "../hooks/use-patient-auth";

const PatientHomeNoProfile: React.FC = () => {
  const requireAuth = useRequireAuth();
  const auth = usePatientAuth();
  const [enableProfile, setEnableProfile] = useState<boolean>();
  const [enableCreditCard, setEnableCreditCard] = useState<boolean>();
  const [creditCardModal, setCreditCardModal] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!auth.loading) {
      const firstName = auth.profile?.firstName;
      const ccReady = auth.profile?.ccReady;
      if (firstName && ccReady) {
        history.replace(PATIENT_HOME_ROUTE);
        return;
      }
      setEnableProfile(!firstName);
      setEnableCreditCard(!ccReady);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loading]);

  if (!requireAuth.user && auth.loading)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <MainContentWrapper align="center">
      <H1>Let's setup your account:</H1>
      <Spacer4 />

      <LinksWrapper>
        {enableProfile && <HomeLink icon={AvatarIcon} text="Add a Care<br>Profile" to={PATIENT_PROFILE_ROUTE} />}
        <Spacer2 />
        {enableCreditCard && (
          <HomeLink
            onClick={() => setCreditCardModal(true)}
            icon={<FontAwesomeIcon icon={faCreditCard} size="3x" color={theme.colors.jellyBean} />}
            text="Add a Credit<br>Card"
          />
        )}
      </LinksWrapper>
      <CreditCardAndRetryPaymentModal
        isVisible={creditCardModal}
        onCancel={() => setCreditCardModal(false)}
        onConfirm={() => history.go(0)}
      />
    </MainContentWrapper>
  );
};

const LinksWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
`;

const LinkWrapper = styled.div`
  background-color: white;
  width: 271px;
  height: 218px;

  &:hover {
    box-shadow: 0 0 5px 3px #e0e0e0;

    .bottom-border {
      background-color: ${(props) => props.theme.colors.celadon};
    }
  }

  a,
  span {
    display: block;
    padding-top: 36px;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }
`;

const HomeLinkText = styled.p`
  font-size: 30px;
  color: ${(props) => props.theme.colors.jellyBean};
  line-height: 32px;
  text-decoration: none !important;
`;

const HomeLink = (props: any) => (
  <LinkWrapper>
    {props.to ? (
      <Link to={{ pathname: props.to, state: PATIENT_HOME_NO_PROFILE_ROUTE }}>
        {props.icon}
        <Spacer2 />
        <HomeLinkText dangerouslySetInnerHTML={{ __html: props.text }} />
        <BottomBorder />
      </Link>
    ) : (
      <span onClick={() => props.onClick()}>
        {props.icon}
        <Spacer2 />
        <HomeLinkText dangerouslySetInnerHTML={{ __html: props.text }} />
        <BottomBorder />
      </span>
    )}
  </LinkWrapper>
);

const BottomBorder = styled.div.attrs((props) => ({
  className: "bottom-border",
}))`
  margin-top: 43px;
  background-color: white;
  height: 10px;
`;

export default PatientHomeNoProfile;
