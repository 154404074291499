import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";
import { MY_MESSAGES_ROUTE } from "../constants/routes";
import { CommentIcon } from "./icons";
// eslint-disable-next-line no-unused-vars
import { Job } from "../types/jobs";
import { useUnseenMessages } from "../hooks/use-unseen-messages";
import { NotificationBadge } from "./chat/notification-badge";

interface JobConfirmedButtonProps {
  job: Job;
}

export const JobConfirmedButton: React.FC<JobConfirmedButtonProps> = ({ job }) => {
  const unseenCount = useUnseenMessages(job.id!);
  return (
    <ConfirmedButton
      to={{
        pathname: `${MY_MESSAGES_ROUTE}/${job.id}`,
        state: job,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span></span>
      <span style={{ marginRight: 10 }}>CONFIRMED</span>
      <span className="comment-icon-wrapper">
        <div style={{ position: "relative" }}>
          <CommentIcon />
          {unseenCount > 0 && (
            <div style={{ position: "absolute", top: "-10px", right: "25px" }}>
              <NotificationBadge count={unseenCount} />
            </div>
          )}
        </div>
      </span>
    </ConfirmedButton>
  );
};

const ConfirmedButton = styled(Link)`
  background-color: ${(props) => props.theme.colors.jellyBean};
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: block;
  text-align: center;
  transition: background-color 0.3s;
  display: flex;
  justify-content: between;
  padding: 0;
  align-items: center;

  span {
    width: 100%;
  }

  .comment-icon-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: ${(props) => props.theme.colors.lightGreen};
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.jellyBean};
    color: #fff;
    text-decoration: none;
  }
`;
