// eslint-disable-next-line no-unused-vars
import React, { RefObject, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { CheckboxFormGroup } from "../components/checkbox-form-group";
import PhoneFormGroup from "../components/phone-form-group";
import { Spacer4 } from "../components/spacers";
import { H4 } from "../components/text";
import TextFormGroup from "../components/text-form-group";
// eslint-disable-next-line no-unused-vars
import { FormControlElement } from "../types/form";
// eslint-disable-next-line no-unused-vars
import { PatientProfileDocument } from "../types/user";

interface Props {
  currentProfile: PatientProfileDocument;
  handleFieldChange: Function;
  formRefs: { [key: string]: RefObject<HTMLInputElement> };
  setOwnerPhoneParts: Function;
  isPhoneValid: boolean | undefined;
  isAccountOwner: boolean;
  setIsAccountOwner: Function;
  phoneParts: string[];
};

export const AccountOwnerProfile: React.FC<Props> = ({
  currentProfile,
  handleFieldChange,
  formRefs,
  setOwnerPhoneParts,
  isPhoneValid,
  isAccountOwner,
  setIsAccountOwner, 
  phoneParts
}) => {
  const [phonePartsT, setPhonePatstT] = useState<string[]>([]);

  const selectIsAccountOwner = (a: any, event: React.ChangeEvent<FormControlElement>) => {
    setIsAccountOwner(event.target.value === "true");
    const tempEvent = { currentTarget: { value: event.target.value === "true" } };
    handleFieldChange("isAccountOwner", tempEvent);
  };

  const onPhoneChange = (partIndex: number, event: React.ChangeEvent<FormControlElement>) => {
    const { value } = event.currentTarget;
    const nextPhoneParts = { ...phonePartsT };
    nextPhoneParts[partIndex] = value;
    setPhonePatstT(nextPhoneParts);
    setOwnerPhoneParts(nextPhoneParts);
  };

  useEffect(() => {
    if (phoneParts) {
      setPhonePatstT(phoneParts);
    }
  }, [phoneParts]);

  return (
    <AccountOwnerWrapper>
      <H4>Are you creating this profile on behalf of someone else?</H4>
      <CheckboxFormGroup
        field="isAccountOwner"
        optionsValueArray={["false", "true"]}
        optionsLabelArray={[
          "No, I am creating this profile for myself.",
          "Yes, I am creating this profile for someone else.",
        ]}
        singleValue={true}
        value={`${isAccountOwner}`}
        onClick={selectIsAccountOwner}
      />
      {isAccountOwner && (
        <>
          <Spacer4 />
          <h1>Account Owner</h1>
          <Form.Row>
            <TextFormGroup
              md="6"
              field="ownerFirstName"
              ref={formRefs.ownerFirstName}
              value={currentProfile.ownerFirstName}
              required={true}
              labelHtml="First Name"
              ariaLabel="First Name"
              feedbackHtml={"Please provide a valid first name."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("ownerFirstName", event)}
            />
            <TextFormGroup
              md="6"
              field="ownerLastName"
              ref={formRefs.ownerLastName}
              value={currentProfile.ownerLastName}
              required={true}
              labelHtml="Last Name"
              ariaLabel="Last Name"
              feedbackHtml={"Please provide a valid last name."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("ownerLastName", event)}
            />
          </Form.Row>

          <Form.Row>
            <TextFormGroup
              md="6"
              field="ownerEmail"
              ref={formRefs.ownerEmail}
              value={currentProfile.ownerEmail}
              required={true}
              labelHtml="Email"
              ariaLabel="Email"
              feedbackHtml={"Please provide a valid email address."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("ownerEmail", event)}
            />
            <TextFormGroup
              md="6"
              field="ownerRelationship"
              ref={formRefs.ownerRelationship}
              value={currentProfile.ownerRelationship}
              required={true}
              labelHtml="Relationship"
              ariaLabel="e.g.: family member, care provider, etc…"
              feedbackHtml={"Please provide a valid relationship."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("ownerRelationship", event)}
            />
          </Form.Row>

          <Form.Row>
            <PhoneFormGroup
              ref={formRefs.ownerPhoneNumber}
              onChangePhone={onPhoneChange}
              phoneParts={phonePartsT}
              isPhoneValid={isPhoneValid}
            />
          </Form.Row>
        </>
      )}
    </AccountOwnerWrapper>
  );
};

const AccountOwnerWrapper = styled.div`
  h4 {
    text-align: center;
  }
`;
