import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { PATIENT_JOBS_ROUTE } from "../constants/routes";
import { Spacer3, Spacer4 } from "../components/spacers";
import { MainContentWrapper } from "../components/layout";
import { LinkButton } from "../components/buttons";
import useScrollTop from "../hooks/use-scroll-to-top";

const PatientAddEditJobStepSuccess: React.FC = (props) => {
  useScrollTop();

  return (
    <MainContentWrapper color="white">
      <WrapperRow>
        <Col>
          <Spacer4 />
          <Row>
            <Col>
              <h1>Great, you're all set!</h1>
            </Col>
          </Row>
          <Spacer3 />
          <CopyRow>
            <Col>We've posted your job for potential Caregivers to see.</Col>
          </CopyRow>
          <Spacer4 />
          <Row>
            <Col>
              <LinkButton to={PATIENT_JOBS_ROUTE}>Go to My Jobs</LinkButton>
            </Col>
          </Row>
        </Col>
      </WrapperRow>
    </MainContentWrapper>
  );
};

const WrapperRow = styled(Row).attrs((props) => ({
  className: `text-center`,
}))`
  width: 482px !important;
  margin-left: auto;
  margin-right: auto;
`;

const CopyRow = styled(Row)`
  font-size: 28px;
  color: ${(props) => props.theme.colors.emperor};
  text-align: center;
  line-height: 36px;
`;

export default PatientAddEditJobStepSuccess;
