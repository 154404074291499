import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { ProvideAuth } from "../hooks/use-patient-auth";
import { stripePromise } from "../App";
import Theme from "./theme";
import { ErrorBoundary } from "./error-boundary";

interface IProps {}

const Providers: React.FC<IProps> = (props) => {
  return (
    <ErrorBoundary>
      <ProvideAuth>
        <Elements stripe={stripePromise}>
          <Theme>{props.children}</Theme>
        </Elements>
      </ProvideAuth>
    </ErrorBoundary>
  );
};

export default Providers;
