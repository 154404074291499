import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import { Applicant } from "../types/applicant";
import ReactStars from "react-rating-stars-component";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Row, Col } from "react-bootstrap";
import { Spacer2, Spacer4 } from "./spacers";
import { ModalCardTitle } from "./modal-popup";
import { PageLoading } from "./loading";
import { theme } from "./theme";
// eslint-disable-next-line no-unused-vars
import { fromFirebase, Review } from "../types/reviews";
import { CloseIcon, LoadingWrapper, ModalCard, ModalCardBackdrop, ModalContentWrapper } from "./modal-parts";
import { firestore } from "../firebase";
import { PRACTITIONERS_PROFILES_COLLECTION, REVIEWS_COLLECTION } from "../constants/collections";

const MAX_REVIEWS = 10;

interface ModalIProps {
  isVisible: boolean;
  setIsVisible: Function;
  applicant: Applicant;
}

const ReviewsModal: React.FC<ModalIProps> = (props) => {
  const { isVisible, setIsVisible, applicant } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>();
  const [reviews, setReviews] = useState<Review[]>([]);

  useEffect(() => {
    if (isVisible) {
      setLoading(true);

      firestore
        .collection(PRACTITIONERS_PROFILES_COLLECTION)
        .doc(applicant.id)
        .collection(REVIEWS_COLLECTION)
        .limit(MAX_REVIEWS)
        .orderBy("createdAt", "desc")
        .get()
        .then((docs) => {
          const reviews = fromFirebase(docs);
          setReviews(reviews);
          // setApplicant(fromFirebase(doc));
          setLoading(false);
        })
        .catch((error: any) => {
          setError(true);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return isVisible ? (
    <ModalCardBackdrop>
      <ModalCard>
        <ModalCardTitle className="d-flex justify-content-between align-items-center">
          <div style={{ width: "60px" }} />
          <span>Reviews for {applicant.displayName}</span>
          <CloseIcon icon={faTimes} color="white" size="1x" onClick={() => setIsVisible(false)} />
        </ModalCardTitle>
        {error && (
          <ErrorWrapper>
            <p className="text-danger text-center">
              Sorry, we found an error while loading the reviews. <br /> Please try again by reloading the page.
            </p>
          </ErrorWrapper>
        )}
        {loading ? (
          <>
            <Spacer4 />
            <LoadingWrapper>
              <PageLoading />
            </LoadingWrapper>
          </>
        ) : (
          !error && (
            <ScrollingModalContentWrapper>
              {reviews.map((appReview: Review, index) => {
                if (appReview.rating > 0) {
                  return <ApplicantReviewComponent key={index} applicantReview={appReview} />;
                }

                return <div key={index} />;
              })}
            </ScrollingModalContentWrapper>
          )
        )}
      </ModalCard>
    </ModalCardBackdrop>
  ) : (
    <></>
  );
};

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 20px;
`;

interface ReviewIProps {
  applicantReview: Review;
}
const ApplicantReviewComponent: React.FC<ReviewIProps> = (props) => {
  const { applicantReview } = props;

  return (
    <ReviewWrapper>
      <ReviewerRow>
        <Col>{applicantReview.reviewer}</Col>
      </ReviewerRow>
      <StarsRow>
        <ReactStars
          count={5}
          value={applicantReview.rating}
          edit={false}
          onChange={() => {}}
          size={24}
          isHalf
          activeColor={theme.colors.yellowOrange}
          color={theme.colors.alto}
        />
        <Spacer2 />
        <DateSpan>{moment(applicantReview.createdAt.toDate()).fromNow()}</DateSpan>
      </StarsRow>
      <ReviewCopyRow>
        <Col>{applicantReview.review}</Col>
      </ReviewCopyRow>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
    </ReviewWrapper>
  );
};

const StarsRow = styled.div`
  display: flex;
  justify-content: start;
`;

const ScrollingModalContentWrapper = styled(ModalContentWrapper)`
  overflow-y: auto;
  height: 450px;
`;

const ReviewWrapper = styled.div`
  width: 468px;
`;

const ReviewerRow = styled(Row)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.emperor};
  text-align: left;
  margin-bottom: 2px;
`;

const ReviewCopyRow = styled(Row)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.emperor};
`;

const DateSpan = styled.span`
  font-size: 20px;
  color: ${(props) => props.theme.colors.emperor};
`;

export default ReviewsModal;
