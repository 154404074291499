import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
  AUTH_ROUTE,
  PATIENT_PROFILE_ROUTE,
  PATIENT_JOBS_ROUTE,
  PATIENT_ACCOUNT_HISTORY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  PRIVACY_ROUTE,
} from "../constants/routes";
import { ReactComponent as LogoImage } from "../assets/footer-logo.svg";
import Container from "react-bootstrap/Container";
import { Spacer1, Spacer2, Spacer4 } from "./spacers";
import { INFO_EMAIL, INFO_PHONE } from "../constants/info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-solid-svg-icons";

const Footer: React.FC = () => {
  const today: Date = new Date();

  return (
    <>
      <FooterContainer fluid>
        <Container>
          <Spacer4 />
          <Row>
            <Col md="3">
              <WhiteTextWrapper>
                <h4>About Us</h4>
                <Spacer2 />
                <WhiteLink to={TERMS_OF_SERVICE_ROUTE}>Terms of Service</WhiteLink>
                <Spacer1 />
                <WhiteLink to={PRIVACY_ROUTE}>Privacy Policy</WhiteLink>
              </WhiteTextWrapper>
            </Col>
            <Col md="3">
              <WhiteTextWrapper>
                <h4>My Jobs</h4>
                <Spacer2 />
                <WhiteLink to={PATIENT_JOBS_ROUTE}>Upcoming Jobs</WhiteLink>
                <Spacer1 />
                <WhiteLink to={PATIENT_ACCOUNT_HISTORY_ROUTE}>Completed Jobs</WhiteLink>
              </WhiteTextWrapper>
            </Col>
            <Col md="3">
              <WhiteTextWrapper>
                <h4>Care Profile</h4>
                <Spacer2 />
                <WhiteLink to={PATIENT_PROFILE_ROUTE}>Edit Care Profile</WhiteLink>
              </WhiteTextWrapper>
            </Col>
            <Col md="3">
              <WhiteTextWrapper>
                <h4>Contact Us</h4>
                <Spacer2 />
                <Row className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faPhone} color="white" />
                  <Spacer2 />
                  <a href={`tel:${INFO_PHONE}`}>{INFO_PHONE}</a>
                </Row>
                <Spacer1 />
                <Row className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faEnvelope} color="white" />
                  <Spacer2 />
                  <a href={`mailto:${INFO_EMAIL}`}>{INFO_EMAIL}</a>
                </Row>
              </WhiteTextWrapper>
            </Col>
          </Row>
          <Spacer4 />
          <Row>
            <Col md="4">
              <WhiteTextWrapper>
                <Link to={AUTH_ROUTE}>
                  <LogoImage />
                </Link>
              </WhiteTextWrapper>
            </Col>
            <Col md="4">
              <CopyrightText>© {today.getFullYear()}, CaregiverGO. All Rights Reserved.</CopyrightText>
            </Col>
          </Row>
          <Spacer4 />
        </Container>
      </FooterContainer>
    </>
  );
};

const WhiteTextWrapper = styled.div`
  text-align: left;
  width: 260px;

  a {
    color: white;
  }
`;

const WhiteLink = styled(Link).attrs(() => ({
  className: "text-white",
}))``;

const CopyrightText = styled.div`
  font-size: 12px;
  text-align: center;
  padding-top: 28px;
`;

const FlexContainer = styled(Container).attrs(() => ({
  className: "text-white text-center",
}))``;

const FooterContainer = styled(FlexContainer)`
  background-color: ${(props) => props.theme.colors.jellyBean};
`;

export default Footer;
