import React from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { CheckboxFormGroup } from "../components/checkbox-form-group";
import { CareNeeds } from "../types/user";
// eslint-disable-next-line no-unused-vars
import { FormControlElement } from "../types/form";
// eslint-disable-next-line no-unused-vars
import { careNeedsLabelsArray, FormStepProps, Task } from "./patient-add-edit-job";
import { TextAreaFormControl } from "../components/forms";
import { Spacer1, Spacer2 } from "../components/spacers";
import styled from "styled-components";
import { H3, Label, RegularText } from "../components/text";
import useScrollTop from "../hooks/use-scroll-to-top";
import { MAX_NOTES_LENGTH, MAX_TASK_NOTES_LENGTH } from "../types/jobs";
import { INFO_PHONE } from "../constants/info";

const PatientAddEditJobStep2: React.FC<FormStepProps> = (props) => {
  const { currentJob, handleCheckboxChange, handleFieldChange, selectedTasks, setSelectedTasks } = props;
  useScrollTop();

  const onValueChange = ({ currentTarget }: React.ChangeEvent<FormControlElement>, task: Task) => {
    const note =
      currentTarget.value.length >= MAX_TASK_NOTES_LENGTH
        ? currentTarget.value.slice(0, MAX_TASK_NOTES_LENGTH)
        : currentTarget.value;
    setSelectedTasks && setSelectedTasks(selectedTasks?.map((t) => (t.key === task.key ? { ...t, note } : t)));
  };

  return (
    <>
      <Spacer1 />
      <Row>
        <Col>
          <H3>What tasks do you need help with?</H3>
        </Col>
      </Row>
      <Spacer2 />
      <TasksWrapper>
        <CheckboxFormGroup
          field="careNeeds"
          optionsValueArray={Object.keys(CareNeeds)}
          optionsLabelArray={careNeedsLabelsArray}
          valuesArray={currentJob?.careNeeds}
          onClick={handleCheckboxChange}
        />
      </TasksWrapper>
      <Form.Row>
        <Form.Group as={Col} md="12">
          {selectedTasks?.map((task: Task, index: number) => {
            return (
              <Row key={`row-${index}`}>
                <RegularText className="mb-0" key={`label-${index}`}>
                  {task.title}
                </RegularText>
                <TextAreaFormControl
                  className="mb-2"
                  key={`textarea-${index}`}
                  as="textarea"
                  rows={3}
                  value={task.note}
                  onChange={(event: React.ChangeEvent<FormControlElement>) => onValueChange(event, task)}
                  aria-label={`Enter a brief bio for ${task.title}...`}
                  placeholder={`Please tell us more about ${task.title}`}
                ></TextAreaFormControl>
              </Row>
            );
          })}
          <Row className="d-flex">
            <RegularText className="mb-0">Additional details</RegularText>
            <TextAreaFormControl
              as="textarea"
              rows={8}
              value={currentJob?.notes}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("notes", event)}
              aria-label="Enter a brief bio ..."
              placeholder='Please tell us a bit more about each selected need. Eg., "I need help getting my groceries from the car up to the stairs of my apartment."'
            />
            <Label className="mb-0">(Maximum {MAX_NOTES_LENGTH} characters)</Label>
          </Row>
        </Form.Group>
      </Form.Row>
      <Alert variant="light" className="mb-0">
        <p className="mb-0">The following Personal Care items are not provided via CaregiverGO:</p>
        <p className="mb-0">Bathing</p>
        <p className="mb-0">Toileting / Incontinence Care</p>
        <p className="mb-0">Medication Administration</p>
        <p className="mb-0">
          If one of these tasks is needed, please call Visiting Angels{" "}
          <Alert.Link href={`tel:${INFO_PHONE}`}>{INFO_PHONE}</Alert.Link>
        </p>
      </Alert>
    </>
  );
};

const TasksWrapper = styled.div`
  .form-group {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  button {
    border-color: ${(props) => props.theme.colors.alto};
    padding: 16px 18px;
    margin: 8px;
  }
`;

export { PatientAddEditJobStep2 };
