import React, { useEffect, useState } from "react";
import useRequireAuth from "../hooks/use-require-auth";
import { fromFirebase } from "../hooks/use-jobs";
import { usePatientAuth } from "../hooks/use-patient-auth";
// eslint-disable-next-line no-unused-vars
import { JobFields, JobStatus, Job } from "../types/jobs";
import { firestore } from "../firebase";
import { JOBS_COLLECTION } from "../constants/collections";
import { PageLoading } from "../components/loading";
import { MainContentWrapper } from "../components/layout";
import { Spacer1, Spacer3, Spacer4 } from "../components/spacers";
import { Italic, TabLabel } from "../components/text";
import { JobItem } from "../components/job-item";
import { Tab, TabsWrapper } from "../components/tabs";
import GenericError from "../components/generic-error";
import useScrollTop from "../hooks/use-scroll-to-top";
import { Button } from "../components/buttons";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { PATIENT_NEW_JOB_ROUTE } from "../constants/routes";

const PatientJobs: React.FC = () => {
  const requireAuth = useRequireAuth();
  const { user } = usePatientAuth();
  const history = useHistory();
  useScrollTop();

  const [loading, setLoading] = useState(true);
  // TODO use proper type DocumentSnapshot
  const [jobs, setJobs] = useState<any>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!user || !user?.firebaseUser) return;

    const unsubscribe = firestore
      .collection(JOBS_COLLECTION)
      .where(JobFields[JobFields.patientUID], "==", user?.firebaseUser?.uid)
      .where(JobFields[JobFields.status], "in", [JobStatus.PENDING, JobStatus.CONFIRMED])
      .orderBy(JobFields[JobFields.date])
      .onSnapshot(
        (documents) => {
          setJobs(documents);
          setLoading(false);
        },
        (e) => {
          console.error(e);
          setLoading(false);
          setError(true);
        }
      );

    return () => unsubscribe();
  }, [user]);

  if (!requireAuth.user || loading)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <>
      <MainContentWrapper>
        <Spacer3 />
        <TabsWrapper>
          <Tab active={true}>
            <TabLabel>
              <span>Jobs</span>
            </TabLabel>
          </Tab>
        </TabsWrapper>
        <Spacer1 />
        {error ? (
          <GenericError />
        ) : jobs?.docs.length ? (
          // TODO any, this should be query snapshot
          jobs?.docs.map((job: any, index: number) => {
            return <JobItem key={index} job={fromFirebase(job)} />;
          })
        ) : (
          <>
            <Spacer3 />
            <div className="d-flex flex-column align-items-center">
              <Italic>There are currently no upcoming jobs.</Italic>
              <ButtonWrapper>
                <Button onClick={() => history.push(PATIENT_NEW_JOB_ROUTE)}>Post a Job</Button>
              </ButtonWrapper>
            </div>
          </>
        )}
        <Spacer4 />
      </MainContentWrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  width: 200px;
`;

export default PatientJobs;
