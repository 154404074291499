export const PRACTITIONER = "practitioner";
export const PATIENT = "patient";
export type UserTypes = "patient" | "practitioner";

export type FormUser = {
  email: string;
  password: string;
  repeatPassword?: string;
  type: UserTypes;
};

export type UserDocument = {
  firebaseUser: firebase.User | null;
  createdAt: Date;
  type: UserTypes;
  pages?: String[];
};

export type PatientProfileDocument = {
  firstName: string;
  lastName: string;
  gender: string;
  phone: string;
  birthday: Date;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  careNeeds: String[];
  pets: String[];
  bio: string;
  newApplicants: number;
  stripeCustomerId?: string | null;
  geoPoint?: object | null;
  ccReady?: boolean;
  isAccountOwner?: boolean;
  ownerFirstName?: string;
  ownerLastName?: string;
  ownerEmail?: string;
  ownerRelationship?: string;
  ownerPhone?: string;
};

export const getPublicAddress = (profile: PatientProfileDocument) => {
  return `${profile.addressLine1} ${profile.addressLine2}`;
};

export const Genders = Object.freeze({
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
});

export const CareNeeds = Object.freeze({
  COMPANIONSHIP: "Companionship",
  MEAL_PREP: "Meal Prep",
  HOUSEKEEPING_LAUNDRY: "Housekeeping / Laundry",
  ERRANDS_APPOINTMENTS: "Errands / Appointments",
  WALKING_ASSISTANCE: "Walking Assistance",
  DRESSING_GROOMING: "Dressing / Grooming",
  FALL_SAFETY_MONITORING: "Fall / Safety Monitoring",
});

export const Pets = Object.freeze({
  CATS: "I have cat/cats",
  DOGS: "I have dog/dogs",
  OTHER: "I have other pets",
});
