// TODO turn into function
import styled from "styled-components";

export const Spacer0 = styled.div.attrs((props) => ({
  className: "p-0",
}))``;

export const Spacer1 = styled.div.attrs((props) => ({
  className: "p-1",
}))``;

export const Spacer2 = styled.div.attrs((props) => ({
  className: "p-2",
}))``;

export const Spacer3 = styled.div.attrs((props) => ({
  className: "p-3",
}))``;

export const Spacer4 = styled.div.attrs((props) => ({
  className: "p-4",
}))``;

export const Spacer5 = styled.div.attrs((props) => ({
  className: "p-5",
}))``;
