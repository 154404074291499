import React, {useEffect, useState} from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import useRequireAuth from "../hooks/use-require-auth";
import {Alert, Col, Form, Row} from "react-bootstrap";
import JobProgress from "../components/job-progress";
// eslint-disable-next-line no-unused-vars
import {Job, JobFields, JobStatus, MAX_NOTES_LENGTH, NewJob} from "../types/jobs";
// eslint-disable-next-line no-unused-vars
import {FormControlElement} from "../types/form";
import {PatientAddEditJobStep1} from "./patient-add-edit-job-step1";
import {PageLoading} from "../components/loading";
import {Spacer2, Spacer4} from "../components/spacers";
import {BackButton, Button} from "../components/buttons";
import {MainContentWrapper} from "../components/layout";
import {PatientAddEditJobStep2} from "./patient-add-edit-job-step2";
import {PatientAddEditJobStep3} from "./patient-add-edit-job-step3";
import {CareNeeds} from "../types/user";
import {saveNewJob} from "../hooks/use-jobs";
import {usePatientAuth} from "../hooks/use-patient-auth";
// eslint-disable-next-line no-unused-vars
import {RouteComponentProps, useHistory} from "react-router-dom";
import {PATIENT_NEW_JOB_SUCCESS_ROUTE} from "../constants/routes";
import useScrollTop from "../hooks/use-scroll-to-top";
import moment from "moment-timezone";
import {firestore} from "../firebase";
import {JOBS_COLLECTION} from "../constants/collections";
import GenericError from "../components/generic-error";
import {convertToTimeZone} from "../constants/timezones";
import {SPLIT_TEXT_BY_ENTER} from "../constants/constants";

export const careNeedsLabelsArray = Object.values(CareNeeds);

export interface Task {
  key: string;
  title: any;
  note: string;
}

export interface FormStepProps {
  currentJob?: NewJob;
  setCurrentJob?: Function;
  handleDateChange: Function;
  handleCheckboxChange: Function;
  handleFieldChange: Function;
  state?: Job;
  selectedTasks?: Task[];
  setSelectedTasks?: Function;
  allNotes?: string;
  profileName?: string;
  editMode?: boolean;
}

const emptyNewJob: NewJob = {
  step: 1,
  date: undefined,
  shiftLength: undefined,
  pricePerHour: undefined,
  careNeeds: undefined,
  notes: undefined,
  isOwner: true,
  firstName: '',
  lastName: '',
  gender: '',
  relationship: '',
  age: 0,
  isRecurring: false,
  recurringDays: undefined,
  recurringEndDate: undefined,
  recurringDates: []
};

let initialJob: NewJob = {...emptyNewJob};

interface PatientAddEditJobRouterProps {
  jobId: string;
}

interface PatientAddEditJobProps extends RouteComponentProps<PatientAddEditJobRouterProps> {}

const PatientAddEditJob: React.FC<PatientAddEditJobProps> = (props) => {
  const jobId = props.match.params.jobId;
  const editMode = jobId !== undefined;
  const history = useHistory();
  const requireAuth = useRequireAuth();
  const {user, profile} = usePatientAuth();
  useScrollTop();

  const [currentJob, setCurrentJob] = useState<NewJob>();
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);

  useEffect(() => {
    const jobToEdit = props.location.state as Job;

    if (jobToEdit) {
      initialJob.date = jobToEdit.date;
      initialJob.shiftLength = jobToEdit.shiftLength;
      initialJob.pricePerHour = jobToEdit.pricePerHour;
      initialJob.careNeeds = jobToEdit.careNeeds;
      initialJob.notes = jobToEdit.notes;
      initialJob.jobId = jobToEdit.id;
      initialJob.status = jobToEdit.status;
      initialJob.parentJobId = jobToEdit.parentJobId;
      setCurrentJob(initialJob);
    } else {
      setCurrentJob(emptyNewJob);
    }
  }, [props.location.state]);

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [validated, setValidated] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showOverlapAlert, setShowOverlapAlert] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [jobSaved, setJobSaved] = useState<boolean>(false);

  const handleDateChange = (date: Date) => {
    let nextDate = date;
    if (!currentJob?.date) {
      nextDate = moment(date).hour(7).toDate();
    }

    validateExistingJobs(nextDate, currentJob?.shiftLength || 1, editMode && jobId ? jobId : undefined);
    const nextJob: NewJob = {...currentJob, date: nextDate};
    setCurrentJob(nextJob);
  };

  const handleFieldChange = (field: string, event: React.FormEvent<FormControlElement>) => {
    const {value} = event.currentTarget;
    const nextJob: NewJob = {...currentJob, [field]: value.substring(0, MAX_NOTES_LENGTH)};

    if (field === 'shiftLength') {
      validateExistingJobs(nextJob.date || new Date(moment().toDate()), nextJob.shiftLength || 1, editMode && jobId ? jobId : undefined);
    }
    setCurrentJob(nextJob);
  };

  const handleCheckboxChange = (field: string, event: React.FormEvent<FormControlElement>) => {
    const {value} = event.currentTarget;
    let nextArray = currentJob?.careNeeds ?? [];
    if (nextArray.includes(value)) {
      nextArray = nextArray.filter((element: string) => element !== value);
    } else {
      nextArray.push(value);
    }
    const nextJob: NewJob = {...currentJob, [field]: nextArray};
    setCurrentJob(nextJob);
    selectTask(value);
    return nextJob;
  };

  const selectTask = (careNeeds: string) => {
    const taskExist = selectedTasks.find(t => t.key === careNeeds);

    if (taskExist) {
      setSelectedTasks(selectedTasks.filter(t => t.key !== careNeeds))
    } else {
      setSelectedTasks([...selectedTasks, {
        key: careNeeds,
        // @ts-ignore
        title: CareNeeds[careNeeds],
        note: ''
      }])
    }

  };

  const getNotes = () => {
    const notes: string[] = selectedTasks.map((t) => `${t.title}: ${t.note}`);
    notes.push(`Additional Notes: ${currentJob?.notes || ''}`);
    return notes.join("\r\n");
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity()) {
      const step = currentStep + 1;
      const nextJob: NewJob = {...currentJob, step: step};
      setCurrentJob(nextJob);
      setCurrentStep(step);
    }

    setValidated(true);
  };

  const onPostNewJob = async (event: any) => {
    event.preventDefault();

    const newJob = {...currentJob, notes: getNotes()};
    const newDate = convertToTimeZone(currentJob?.date, profile?.city);
    newJob.date = newDate.toDate();

    // Edit mode
    if (jobId && editMode) {
      newJob.jobId = jobId;
    }

    if (!processing) {
      setProcessing(true);
      setJobSaved(true);
      await saveNewJob(newJob, user, profile);
      setCurrentJob(emptyNewJob);
      setSelectedTasks([]);
      history.push(PATIENT_NEW_JOB_SUCCESS_ROUTE);
      setProcessing(false);
    }
  };

  const validateExistingJobs = (shiftDate: Date, length: number, jobsIdToExclude?: string) => {
    if (!user || !user?.firebaseUser) return;

    const shiftInitDate = moment(shiftDate);
    const shiftEndDate = moment(shiftDate).add(length, 'hours').add(-1, 'minutes');
    setError(false);

    const unsubscribe = firestore
      .collection(JOBS_COLLECTION)
      .where(JobFields[JobFields.patientUID], '==', user?.firebaseUser?.uid)
      .where(JobFields[JobFields.status], 'in', [JobStatus.PENDING, JobStatus.CONFIRMED])
      .where(JobFields[JobFields.date], '>=', new Date(moment(shiftInitDate).startOf('day').toDate()))
      .where(JobFields[JobFields.date], '<=', new Date(moment(shiftInitDate).endOf('day').toDate()))
      .orderBy(JobFields[JobFields.date])
      .onSnapshot(
        (documents) => {
          let showWarning = false;
          documents.docs.forEach((item: any, index: number) => {
            if (jobsIdToExclude === item.id) {
              showWarning = false;
              return;
            }

            const {date, shiftLength} = item.data();
            const initDate = moment(date.toDate());
            const endDate = moment(date.toDate()).add(shiftLength, 'hours').add(-1, 'minute');

            if (shiftInitDate.isBetween(initDate, endDate) || shiftInitDate.isSame(initDate) || shiftInitDate.isSame(endDate) ||
              shiftEndDate.isBetween(initDate, endDate) || shiftEndDate.isSame(initDate) || shiftEndDate.isSame(endDate)) {
              showWarning = true;
            } else if (initDate.isBetween(shiftInitDate, shiftEndDate) || initDate.isSame(shiftInitDate) || initDate.isSame(shiftEndDate) ||
              endDate.isBetween(shiftInitDate, shiftEndDate) || endDate.isSame(shiftInitDate) || endDate.isSame(shiftEndDate)) {
              showWarning = true;
            }
          });

          setShowOverlapAlert(showWarning);
        },
        (e) => {
          console.log(e);
          setError(true);
        }
      );

    return () => unsubscribe();
  };

  useEffect(() => {
    if (!jobId || !editMode) {
      return;
    }

    firestore.collection(JOBS_COLLECTION)
      .doc(jobId)
      .get()
      .then((doc) => {
        const jobToEdit = doc.data();
        if (jobToEdit) {
          setJobToEdit(jobToEdit);
        }
      })
      .catch((e) => {
        setError(true);
      });
  }, [jobId, editMode]);

  /**
   * set the values of the selected job to edit the props
   * @param job
   */
  const setJobToEdit = (job: any) => {
    initialJob.jobId = job.id;
    initialJob.date = job.date.toDate();
    initialJob.shiftLength = job.shiftLength;
    initialJob.pricePerHour = job.pricePerHour;
    initialJob.careNeeds = job.careNeeds;
    initialJob.status = job.status;
    initialJob.parentJobId = job.parentJobId;

    let additionalNotes = '';
    const tasks = job.notes.split(SPLIT_TEXT_BY_ENTER).map((item: string) => {
      const task = item.split(":");
      if (task[0] !== 'Additional Notes') {
        return {title: task[0].trim(), note: task[1].trim()} as Task;
      } else {
        additionalNotes = task[1].trim();
        return undefined;
      }
    }).filter((task: Task) => task);

    initialJob.notes = additionalNotes;
    setSelectedTasks(tasks);
    setCurrentJob(initialJob);
  };

  if (!requireAuth.user) {
    return (
      <MainContentWrapper color="white">
        <PageLoading/>
      </MainContentWrapper>
    );
  }

  return (
    <MainContentWrapper color="white">
      <JobForm noValidate validated={validated} onSubmit={handleSubmit} className={`step${currentStep}`}>
        <Spacer4/>
        <h1>{editMode ? 'Edit' : 'Post'} a Job</h1>
        <Spacer2/>

        <JobProgress steps={[1, 2, 3]} currentStep={currentStep} setCurrentStep={setCurrentStep}/>

        <Spacer2/>
        {currentStep === 1 && (
          <PatientAddEditJobStep1
            currentJob={currentJob}
            setCurrentJob={setCurrentJob}
            handleDateChange={handleDateChange}
            handleCheckboxChange={handleFieldChange}
            handleFieldChange={handleFieldChange}
            profileName={`${profile?.firstName || '...'} ${profile?.lastName.slice(0, 1) || ''}`}
            editMode={editMode}
          />
        )}
        {currentStep === 2 && (
          <PatientAddEditJobStep2
            currentJob={currentJob}
            handleDateChange={handleDateChange}
            handleCheckboxChange={handleCheckboxChange}
            handleFieldChange={handleFieldChange}
            setCurrentJob={setCurrentJob}
            selectedTasks={selectedTasks}
            setSelectedTasks={setSelectedTasks}
          />
        )}
        {currentStep === 3 && (
          <PatientAddEditJobStep3
            currentJob={currentJob}
            handleDateChange={handleDateChange}
            handleCheckboxChange={handleCheckboxChange}
            handleFieldChange={handleFieldChange}
            allNotes={getNotes()}
          />
        )}

        <Spacer2/>
        {showOverlapAlert && !jobSaved &&
            <Alert variant="warning">You already have a job scheduled at this time</Alert>}
        {error && <GenericError/>}
        <Spacer2/>

        <Row>
          <Col>
            {currentStep !== 3 &&
                <NextButton job={currentJob} step={currentStep} selectedTasks={selectedTasks}/>}
            {currentStep === 3 && (
              <>
                <Row>
                  {!editMode && (
                    <Col md="auto" style={{margin: '0 auto'}}>
                      <Alert variant="warning">Edits to a posted job can be made while in pending
                        status</Alert>
                    </Col>
                  )}
                  <Col md="12">
                    <Button onClick={onPostNewJob}>{editMode ? 'Next' : 'Post My Job'} </Button>
                  </Col>
                </Row>
              </>
            )}
            {currentStep !== 1 && (
              <>
                <Row>
                  <Col md="12">
                    <BackButton
                      onClick={() => {
                        setCurrentStep(1);
                      }}
                    >
                      I have changes
                    </BackButton>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Spacer2/>
      </JobForm>
    </MainContentWrapper>
  );
};

interface NextButtonProps {
  job: NewJob | undefined;
  step: number;
  selectedTasks: Task[];
}

const NextButton: React.FC<NextButtonProps> = (props) => {
  const {job, step, selectedTasks} = props;
  const [showWarning, setShowWarning] = useState<boolean>(false);

  useEffect(() => {
    setShowWarning(false)
  }, [step, setShowWarning]);

  let disabled: boolean = false;

  if (!job) disabled = true;

  if (step === 1) {
    if (!job?.date || !job.shiftLength) disabled = true;

    // Validate required fields for recurring jobs
    if (job && job?.isRecurring) {
      if ((job.recurringDays && job.recurringDays.length === 0) || job.recurringEndDate === undefined || job.recurringDates?.length === 0) disabled = true;
    }

    // checking the owner of the current job
    if (!job?.isOwner) {
      if (!job?.firstName || !job?.lastName || !job?.age || job?.age === 0
        || !job?.gender || !job?.relationship) disabled = true;
    }
  }

  if (step === 2) {
    if (!job?.careNeeds?.length) disabled = true;

    const emptyNotes = selectedTasks.filter(task => task.note === '').length > 0;
    if (!job?.notes || job?.notes === '' || emptyNotes) disabled = true;
  }

  return (
    <>
      <ButtonContainer onClick={() => disabled && setShowWarning(true)}>
        <Button disabled={disabled}>Next</Button>
      </ButtonContainer>
      {showWarning && disabled && (<Alert variant="warning" className="mt-2">
        <p className="m-0">
          please complete all the boxes to continue.
        </p>
      </Alert>)}
    </>
  );
};

const ButtonContainer = styled.div`
  max-height: 80px;
`;

const JobForm = styled(Form).attrs(() => ({
  className: `text-center`,
}))`
  margin-left: auto;
  margin-right: auto;

  &.step1 {
    width: 500px;
  }

  &.step2 {
    width: 760px;
  }

  &.step3 {
    width: 100%;
  }
`;

export default PatientAddEditJob;
