import moment from "moment-timezone";

export const CITY_TIMEZONE: {[key: string]: string} = {
  portland: 'America/Los_Angeles',
  eugene: 'America/Los_Angeles',
  medford: 'America/Los_Angeles',
  default: 'America/Los_Angeles'
};



export const convertToTimeZone = (shiftDate: Date | undefined, city: string | undefined) => {
  if (!shiftDate) {
    return moment(shiftDate);
  }

  if (!city) {
    return moment(shiftDate);
  }

  let timeZone = CITY_TIMEZONE.default;
  if (city && CITY_TIMEZONE[city.toLowerCase()]) {
    timeZone = CITY_TIMEZONE[city.toLowerCase()];
  }

  return moment(shiftDate).tz(timeZone, true);
};

export const  getFromTimeZone = (shiftDate: Date | undefined, city: string | undefined) => {
  if (!shiftDate) {
    return moment(shiftDate);
  }

  if (!city) {
    return moment(shiftDate);
  }

  let timeZone = CITY_TIMEZONE.default;
  if (city && CITY_TIMEZONE[city.toLowerCase()]) {
    timeZone = CITY_TIMEZONE[city.toLowerCase()];
  }
  
  return moment.tz(shiftDate, timeZone);
}

export const getTimeToEdit = (shiftDate: Date | undefined, city: string | undefined) => {
  let newDate = shiftDate;
  const browserTimeZone = moment.tz.guess();

  let profileTimeZone = CITY_TIMEZONE.default;
  if (city && CITY_TIMEZONE[city.toLowerCase()]) {
    profileTimeZone = CITY_TIMEZONE[city.toLowerCase()];
  }

  if (browserTimeZone !== profileTimeZone) {
    const broserDate = moment(shiftDate).clone().tz(browserTimeZone);
    const targetDate = moment(shiftDate).clone().tz(profileTimeZone);
    const difference = targetDate.utcOffset() - broserDate.utcOffset();
    newDate = moment(shiftDate).add(difference/60, "hours").toDate();
  }

  return newDate;
};
