import React from "react";
import { H1 } from "../components/text";
import { MainContentWrapper } from "../components/layout";
import { Spacer3, Spacer5 } from "../components/spacers";
import styled from "styled-components";
import useScrollTop from "../hooks/use-scroll-to-top";
import { DISCLOSURE_APP_ROUTE, DISCLOSURE_ROUTE, PRIVACY_APP_ROUTE, PRIVACY_ROUTE } from "../constants/routes";
import { Link, useLocation } from "react-router-dom";

const TermsOfService: React.FC = () => {
  useScrollTop();
  const location = useLocation();
  const appMode = location.pathname === "/terms_app";

  const content = (
    <>
      <H1>TERMS OF SERVICE</H1>
      <Spacer3 />
      <TextWrapper>
        <p>Last Updated: 29th December 2020</p>
        <p>
          These Terms of Service (“<b>Terms</b>”) apply to your access to and use of (a) the website and mobile
          application (“<b>Online Services</b>”) and (b) the personal care services you select through the Online
          Services (“Care Services”, together with the Online Services, the “<b>Services</b>”) provided by Cassiel
          Portland, LLC dba CaregiverGO (“<b>CaregiverGO</b>” or “<b>we</b>”). By clicking “<b>I Accept</b>” or by using
          our Services, you agree to these Terms. If you do not agree to these Terms, do not use our Services.
        </p>
        <p>
          We may make changes to these Terms from time to time. If we make changes, we will provide you with notice of
          such changes, such as by sending an email, providing a notice through our Services or updating the date at the
          top of these Terms. Unless we say otherwise in our notice, the amended Terms will be effective immediately,
          and your continued use of our Services after we provide such notice will confirm your acceptance of the
          changes. If you do not agree to the amended Terms, you must stop using our Services.
        </p>
        <p>If you have any questions about these Terms or our Services, please contact us at info@CaregiverGO.net.</p>
        <p>
          <b>1. Privacy.</b>
        </p>
        <p>
          CaregiverGO collects certain information about you in order to provide the Services.{" "}
          <Link to={appMode ? PRIVACY_APP_ROUTE : PRIVACY_ROUTE}>The CaregiverGO Privacy Policy</Link> describes how
          that information is collected, used, processed and stored. If you do not agree to be bound by our Privacy
          Policy, you may not access, use or receive the Services.
        </p>
        <p>
          <b>2. Eligibility.</b>
        </p>
        <p>
          You must be at least 18 years of age to book the Care Services and use the Online Services. If you are under
          18 years of age (or the age of legal majority where you live), you may use our Online Services only under the
          supervision of a parent or legal guardian who agrees to be bound by these Terms. If you are a parent or legal
          guardian of a user/recipient under the age of 18 (or the age of legal majority), you agree to be fully
          responsible for the acts or omissions of such user/recipient in relation to our Services. If you use our
          Services on behalf of another person or entity, (a) all references to “you” throughout these Terms will
          include that person or entity, (b) you represent that you are authorized to accept these Terms on that
          person’s or entity’s behalf, and (c) in the event you or the person or entity violates these Terms, the person
          or entity agrees to be responsible to us.
        </p>
        <p>
          <b>3. Your Account.</b>
        </p>
        <p>
          You will need to register for an account to order and receive our Services. If you register for an account,
          you must provide accurate account information and promptly update this information if it changes. You must
          maintain the security of your account and promptly notify us if you discover or suspect that someone has
          accessed your account without your permission. If you permit others to use your account credentials, you are
          responsible for the activities of such users that occur in connection with your account.
        </p>
        <p>
          <b>4. Consent to Electronic Communications.</b>
        </p>
        <p>
          By registering with CaregiverGO or ordering the Services, you understand that CaregiverGO may send you
          information via email regarding the Services, including: (a) notices about the Services, (b) updates to the
          Services and new products or features, and (c) promotional information and materials regarding CaregiverGO’
          Services. Please review your settings in your account to control the messages you receive from CaregiverGO or
          unsubscribe by following the instructions in the message. Notices emailed to you will be considered given and
          received when the email is sent. If you don't consent to receive notices (other than promotional materials)
          electronically, you must stop using the Services.
        </p>
        <p>
          <b>5. Provision of Services.</b>
        </p>
        <p>
          5.1 <u>Services</u>. Subject to your continued compliance with these Terms and timely payment of applicable
          Fees, CaregiverGO will use commercially reasonable efforts to provide the Services you select to you.
          CaregiverGO reserves the right to change the Services at any time or suspend or stop a Service altogether, at
          any time upon notice to you, provided that CaregiverGO will perform the Services as described at the time of
          purchase of such Services. The Services are subject to the{" "}
          <Link to={appMode ? DISCLOSURE_APP_ROUTE : DISCLOSURE_ROUTE}>CaregiverGO Disclosure Statement</Link>, which
          includes information on how you can report any complaints.
        </p>
        <p>
          5.2 <u>Description of Care Services</u>. Care Services consist of: tasks related to one or more of the
          following: Nutrition/hydration; Light Housekeeping and Laundry; Shopping and Errands; Transportation (your
          vehicle), Appointment or General Reminders; Leisure/Companionship Activities; and any other non-medical
          services offered for purchase through the Online Services.
        </p>
        <p>
          5.2.1 “Light Housekeeping and Laundry” consists of: laundering your clothes, bed linens and towels used for
          personal use, tidying up rooms where you spend your time (e.g., bedroom, living room, kitchen), washing dishes
          after meals, wiping spills on sink or floor, "spot mopping", sweeping kitchen floor when needed, passing the
          vacuum in rooms used by you, occasional light dusting, tidying bathrooms after your use, and rinsing tub or
          shower after use. It is recommended that an independent cleaning service be hired for tasks such as: scrubbing
          floors in kitchen and bathrooms, moving furniture, heavy lifting, window or mirror washing, dusting behind and
          under furniture, drape cleaning and heavy laundry.
        </p>
        <p>
          5.3 <u>Unstable Medical or Heath Condition</u>. If your medical or health condition becomes unstable or
          unpredictable, CaregiverGO will notify you and/or your representative(s) of the need for a medical or health
          service referral. CaregiverGO may continue to provide Care Services as ordered and paid for by you to the
          extent such medical or health condition does not pose a risk to the care provider or CaregiverGO, as
          determined in CaregiverGO’s sole discretion.
        </p>
        <p>
          <b>6. Your Obligations.</b>
        </p>
        <p>
          6.1 <u>Schedule Changes</u>. You will notify CaregiverGO via the Online Services at least twenty-four (24)
          hours in advance of the start time for a scheduled Care Service of any change to (including any cancellation
          of) any scheduled of Care Services (“Change Notice”). Notice given to a caregiver assigned to you is not
          considered notice to CaregiverGO. You will be charged for, and will pay, full payment of the scheduled Care
          Services if your Change Notice is not provided at least twenty-four (24) hours in advance of the start time
          for a scheduled Care Service or is otherwise not provided through the Online Services.
        </p>
        <p>
          6.2 <u>Home Owner’s Insurance</u>. You are responsible for maintaining your property and keeping it in safe
          and good repair. You are responsible for obtaining and maintaining any home-owner’s insurance or other
          insurance coverage as may be necessary to provide protection for the caregiver in the event of an injury from
          the condition of the property.
        </p>
        <p>
          6.3 <u>No Exchange of Personal Property</u>. You will not sell, barter or otherwise trade any goods or
          services to the caregiver in exchange for the Services. CaregiverGO has no responsibility for and disclaims
          all liability for any such arrangements you make with any caregiver employed or contracted by CaregiverGO.
        </p>
        <p>
          6.4 <u>No Gifts</u>. You will not give any gifts to any caregiver providing Care Services.
        </p>
        <p>
          6.5 <u>Responsibility to Secure Valuables</u>. You will secure all valuables. Any loss or damage believed to
          be attributable to CaregiverGO must be reported to CaregiverGO in a timely manner by contacting CaregiverGO at
          info@CaregiverGO.net.{" "}
        </p>
        <p>
          <b>7. Fees and Payment.</b>
        </p>
        <p>
          7.1 <u>Fees</u>. You will pay the fees applicable for the Care Services you select and as quoted to you on the
          Online Services when you order the Care Services and any other applicable charges, such as cancellation fees
          (collectively, “<b>Fees</b>”). CaregiverGO may change its prices at any time; however, the Fee will remain in
          force for the duration of the Care Services for which you have paid. After that period ends, your use of the
          Care Services will be charged at the then-current price.
        </p>
        <p>
          7.2 <u>Payment</u>. If Fees are due, you will provide CaregiverGO with current, complete, accurate and
          authorized payment method information. You authorize CaregiverGO to charge your provided payment method for
          the Services you have selected. CaregiverGO may bill your payment card (a) at the time of purchase or (b)
          shortly after purchase. To the extent CaregiverGO has not received your payment, in order to bring your
          account up to date, CaregiverGO may bill you simultaneously for both past due and current Fees. You understand
          that failure to pay any applicable Fees may result in the suspension or cancellation of your access and use of
          the Services. Interest on any late payments will accrue at the rate of 1.5% per month, or the highest rate
          permitted by law, whichever is lower, from the date such amount is due until the date such amount is paid in
          full.
        </p>
        <p>
          7.3 <u>Cancellation Charges</u>. You may cancel Care Services at any time through the Online Services.
          However, all scheduled Care Services will be charged at the applicable Service rate if a cancellation is
          received less than 24 hours prior to the time scheduled for the caregiver to arrive at your residence. The
          foregoing charges do not apply to any unplanned hospitalization or death.
        </p>
        <p>
          7.4 <u>Mileage</u>. We prefer that your vehicle is use for incidental transportation required for the
          provision of the Care Services. You will pay all mileage at the then-current rates schedule posted on the
          Online Services for all uses of our employees’ vehicles to perform Care Services for you.
        </p>
        <p>
          <b>8. Non-Solicitation and Liquidated Damages.</b>
        </p>
        <p>
          You will not directly employ or hire any employee assigned by CaregiverGO to perform Care Services for you for
          6 months following the last day such employee rendered Care Services to you on behalf of CaregiverGO (“
          <b>Restricted Period</b>”). This prohibition includes but is not limited to: (a) paying the employee directly
          for services during the employee’s employment with CaregiverGO; or (b) during the Restricted Period, paying
          the employee directly for services after the employee’s employment with CaregiverGO ends.{" "}
          <b>
            You will pay CaregiverGO $10,000 for each breach of this Section as liquidated damages and any reasonable
            attorney’s fees and costs associated with collecting those liquidated damages
          </b>
          . This amount reflects the costs of recruiting, screening, and training the employee(s). In addition to this
          remedy, CaregiverGO shall be entitled to obtain injunctive relief against any violation of this provision,
          without notice to you, without the necessity of proving actual damages and without posting a bond. These
          remedies shall be cumulative and not mutually exclusive. CaregiverGO reserves the right to pursue any other or
          further remedies at law or in equity to enforce its rights under these Terms.
        </p>
        <p>
          <b>9. Warranty Disclaimer; Use Limitations.</b>
        </p>
        <p>
          9.1 <u>No Warranty</u>. THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”. TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW, CaregiverGO MAKE NO (AND SPECIFICALLY DISCLAIMS ALL) REPRESENTATIONS OR WARRANTIES OF ANY
          KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY
          OF ACCURACY, MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR
          NON-INFRINGEMENT, AND ANY WARRANTY ARISING OUT OF ANY COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OF
          TRADE. SOME JURISDICTIONS DO NOT ALLOW THE FOREGOING EXCLUSIONS. IN SUCH AN EVENT, SUCH EXCLUSION WILL NOT
          APPLY SOLELY TO THE EXTENT PROHIBITED BY APPLICABLE LAW. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE
          SERVICES OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICES.
        </p>
        <p>
          9.2 <u>Use Limitations</u>.
        </p>
        <p>
          9.2.1 <u>No Medical Treatment</u>. The Services will not be used as a substitute for professional medical or
          home health services, and such Services are no intended to be relied upon by any person for the purposes of
          medical treatment or health care. CaregiverGO's caregivers do not practice medicine or nursing, and they will
          not make recommendations concerning diagnosis, prognosis, treatment, medication, dosage, prescriptions or
          other medical or health related services.
        </p>
        <p>
          9.2.2 <u>No Emergency Response</u>. The Services are not an emergency care service. You acknowledge and agree
          that caregivers providing the Care Services do not provide emergency response. While the CaregiverGO caregiver
          providing the Care Services will make reasonable attempts to contact 911 in the event of an emergency, you
          understand CaregiverGO will not dispatch emergency authorities to your home in the event of an emergency. In
          the event of an emergency, the caregiver will contact CaregiverGO and then notify the designated contact you
          have identified in your account or, in the absence of such contact, your next of kin.
        </p>
        <p>
          <b>10. Limitation of Liability.</b>
        </p>
        <p>
          10.1 TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, CaregiverGO WILL NOT BE LIABLE TO YOU UNDER ANY THEORY
          OF LIABILITY—WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, WARRANTY, OR OTHERWISE—FOR ANY
          INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, PUNITIVE OR SPECIAL DAMAGES OR LOST PROFITS, EVEN IF
          CaregiverGO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          10.2 THE TOTAL AGGREGATE LIABILITY OF CaregiverGO FOR ANY CLAIM ARISING OUT OF OR RELATING TO THESE TERMS OR
          THE SERVICES, REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE FEES PAID BY YOU TO CaregiverGO FOR THE
          SPECIFIC SERVICES THAT DIRECTLY CAUSED THE DAMAGES.
        </p>
        <p>
          10.3 THE LIMITATIONS SET FORTH IN THIS SECTION 10 WILL NOT LIMIT OR EXCLUDE LIABILITY FOR THE GROSS
          NEGLIGENCE, FRAUD, OR INTENTIONAL MISCONDUCT OF CaregiverGO OR FOR ANY OTHER MATTERS IN WHICH LIABILITY CANNOT
          BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. ADDITIONALLY, SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO
          YOU.
        </p>
        <p>
          10.4 You acknowledge that the exclusions, disclaimers, and limitations of liability set forth in these Terms
          are essential components of these Terms and form the basis for determining the price charged for the Services,
          and that CaregiverGO would not enter into these Terms without these limitations on its liability. These
          limitations will apply notwithstanding any failure of essential purpose of any limited remedy.
        </p>
        <p>
          <b>11. General Provisions.</b>
        </p>
        <p>
          11.1 <u>Personnel</u>. CaregiverGO is responsible for the performance of its employees and contractors and
          their compliance with CaregiverGO’ obligations under these Terms. CaregiverGO may use non-employee contractors
          or agents for the purpose of providing Services hereunder.
        </p>
        <p>
          11.2 <u>Modifying or Terminating Our Services</u>. We reserve the right to modify our Services or to suspend
          or stop providing all or portions of our Services at any time. You also have the right to stop using our
          Services at any time. We are not responsible for any loss or harm related to your inability to access or use
          our Services.
        </p>
        <p>
          11.3 <u>Force Majeure</u>. In no event will CaregiverGO be liable or responsible to you, or be deemed to have
          defaulted under or breached the Agreement, for any failure or delay in fulfilling or performing any of its
          obligations under the Agreement, when and to the extent such failure or delay is caused by any circumstances
          beyond CaregiverGO’s reasonable control, including acts of God, flood, fire, earthquake or explosion, war,
          terrorism, invasion, riot or other civil unrest, embargoes or blockades in effect on or after the date of the
          Agreement, national or regional emergency, strikes, labor stoppages or slowdowns or other industrial
          disturbances, passage of law or any action taken by a governmental or public authority, including imposing an
          embargo, export or import restriction, quota or other restriction or prohibition or any complete or partial
          government shutdown, or national or regional shortage of adequate power or telecommunications or
          transportation.
        </p>
        <p>
          11.4 <u>Governing Law; Venue</u>. Any dispute arising from these Terms or the Services will be governed by and
          construed and enforced in accordance with the laws of Oregon, without regard to conflict of law rules or
          principles (whether of Oregon or any other jurisdiction) that would cause the application of the laws of any
          other jurisdiction. All disputes arising from or relating to these Terms and the Services will be within the
          exclusive jurisdiction of the state or federal courts located within the State of Oregon, Multnomah County and
          the parties hereby consent to such exclusive jurisdiction and waive objections to venue therein.
        </p>
        <p>
          11.5 <u>Proprietary Rights</u>. CaregiverGO and its licensors and service providers have and will retain all
          rights, title, and interest (including all intellectual property rights) in and to the Services. Any
          suggestions, information or feedback provided by you to CaregiverGO regarding the Services (including, without
          limitation, with respect to modifications, enhancements, improvements and other changes to the Services) (“
          <b>Feedback</b>”) is voluntary and you hereby grant to CaregiverGO a world-wide, royalty free, irrevocable,
          transferrable, perpetual license to use (and authorize others to use) any Feedback without restriction.
        </p>
        <p>
          11.6 <u>App Stores</u>. The availability of the mobile application (“<b>Mobile App</b>”) is dependent on the
          availability of the third-party mobile application store (e.g., Apple, Inc.’s App Store or Google, Inc.’s
          Google Play Store) where you download the Mobile Apps (each, an “<b>App Store</b>”). App Stores are
          third-party services. CaregiverGO has no responsibility or liability for third party services. You may be
          required to accept and abide by the terms and conditions of an App Store to download and use any Mobile App.
          You will comply with, and your license to use the Mobile Apps is conditioned upon your compliance with,
          applicable App Store terms and conditions. You acknowledge that an App Store is not responsible for any
          support and maintenance services with respect to the Mobile App. If any Mobile App fails to conform to any
          applicable warranty provided under these Terms or under applicable law, you acknowledge that no App Store has
          any warranty obligation except to the extent required by applicable law. You acknowledge that as between
          CaregiverGO and an App Store, CaregiverGO is responsible for addressing any third-party claims related to the
          Mobile Apps and your possession or use of the Mobile Apps. You acknowledge that the owners of the App Stores,
          including their affiliates, are third party beneficiaries to these Terms, and that upon your acceptance to
          these Terms, each App Store that you use to obtain the Mobile Apps will have the right (and will be deemed to
          have accepted such right) to enforce these Terms against you as a third party beneficiary of these Terms with
          respect to the Mobile Apps. To the extent such other terms and conditions from such App Store are less
          restrictive than, or otherwise conflict with, these Terms, the more restrictive or conflicting terms and
          conditions in these Terms apply.
        </p>
        <p>
          11.7 <u>Miscellaneous</u>. If any provision or part of a provision of these Terms is unlawful, void or
          unenforceable, that provision or part of the provision is deemed severable from these Terms and does not
          affect the validity and enforceability of any remaining provisions. The failure of a party to exercise or
          enforce any right or provision of these Terms will not operate as a waiver of such right or provision. These
          Terms reflect the entire agreement between the parties relating to the subject matter hereof and supersede all
          prior agreements, representations, statements and understandings of the parties. The section titles in these
          Terms are for convenience only and have no legal or contractual effect. Use of the word “including” will be
          interpreted to mean “including without limitation.” Except as otherwise provided herein, these Terms are
          intended solely for the benefit of the parties and are not intended to confer third-party beneficiary rights
          upon any other person or entity. You agree that communications and transactions between us may be conducted
          electronically.
        </p>
      </TextWrapper>
      <Spacer5 />
    </>
  );

  return appMode ? (
    <div className="p-3">{content}</div>
  ) : (
    <MainContentWrapper align="center">
      <Spacer5 />
      {content}
    </MainContentWrapper>
  );
};

const TextWrapper = styled.div`
  p {
    text-align: left;
  }

  a {
    text-decoration: underline;
  }
`;

export default TermsOfService;
