import React from "react";
import Container from "react-bootstrap/cjs/Container";
import { ReactComponent as LogoImage } from "../assets/logo.svg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Container className="d-flex justify-content-center pt-5">
            <LogoImage />
          </Container>
          <div className="d-flex flex-column align-items-center">
            <p className="mb-0 mt-4">Sorry, we could not load this page.</p>
            <p>Please try reloading the page or contact support.</p>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
