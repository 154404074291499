export const MIN_SHIFTS_TO_SHOW_REVIEWS = 5;

export type Practitioner = {
  id: string;
  shiftsWorked: number;
  rating: number;
};

export const fromFirebase = (doc: any) => {
  const practitioner: Practitioner = {
    id: doc.id,
    shiftsWorked: doc.data().shiftsWorked || 0,
    rating: doc.data().rating || 0,
  };

  return practitioner;
};
