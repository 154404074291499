import React, { useEffect, useState } from "react";
import useRequireAuth from "../hooks/use-require-auth";
import Row from "react-bootstrap/Row";
import { Loading, PageLoading } from "../components/loading";
import { MainContentWrapper } from "../components/layout";
import { Spacer1, Spacer2, Spacer3, Spacer4 } from "../components/spacers";
import { Tab, TabsWrapper } from "../components/tabs";
import { TabLabel, TextLink, TextSize, LinkTextSeparator, TextLinkDisabled, H1, Italic } from "../components/text";
import { useHistory } from "react-router-dom";
import { PATIENT_PROFILE_ROUTE, PATIENT_ACCOUNT_INFO_ROUTE } from "../constants/routes";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import moment from "moment";
import DatePicker from "react-datepicker";
import { DateWrapper } from "../components/dates";
import { FormControl, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../components/theme";
import { firebaseFunctions, firestore } from "../firebase";
import { JOBS_COLLECTION } from "../constants/collections";
// eslint-disable-next-line no-unused-vars
import { Job, JobApplicant, JobFields, JobStatus } from "../types/jobs";
import { usePatientAuth } from "../hooks/use-patient-auth";
import { fromFirebase } from "../hooks/use-jobs";
import { DATE, DATE_HISTORY, TIME } from "../constants/dates";
import GenericError from "../components/generic-error";
import { GET_CARD_FUNCTION, PAY_JOB_FUNCTION } from "../constants/callable-functions";
import { Button } from "../components/buttons";
// eslint-disable-next-line no-unused-vars
import { PaymentMethod } from "@stripe/stripe-js";
import CreditCardAndRetryPaymentModal from "../components/credit-card-and-retry-payment-modal";
import useScrollTop from "../hooks/use-scroll-to-top";
import ReportProblemModal from "../components/report-problem-modal";

const CGGO_EPOCH = new Date("2020-09-01");
const JOBS_PER_PAGE = 10;

const PatientAccountHistory: React.FC = () => {
  const requireAuth = useRequireAuth();
  const history = useHistory();
  const [fromDate, setFromDate] = useState<Date | null>();
  const [toDate, setToDate] = useState<Date | null>();
  const { user } = usePatientAuth();
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState<firebase.firestore.DocumentData[]>();
  const [error, setError] = useState<boolean>(false);
  const [showRetryModal, setShowRetryModal] = useState<boolean>(false);
  const [creditCard, setCreditCard] = useState<PaymentMethod>();
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [firstDoc, setFirstDoc] = useState<firebase.firestore.DocumentData>();
  const [lastDoc, setLastDoc] = useState<firebase.firestore.DocumentData>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);

  // moving problem modal outside the loop
  const [jobId, setJobId] =  useState<string>("");
  const [feedBackOption, setFeedBackOption] =  useState<string>("");
  const [feedBackInfo, setFeedBackInfo] =  useState<string>("");

  useScrollTop();

  const query = firestore
    .collection(JOBS_COLLECTION)
    .where(JobFields[JobFields.patientUID], "==", user && user.firebaseUser && user.firebaseUser.uid)
    .where(JobFields[JobFields.status], "in", [
      JobStatus.PAID,
      JobStatus.PAYMENT_ERROR,
      JobStatus.PAYMENT_ATTEMPTS_EXHAUSTED,
      JobStatus.CLIENT_REPORTED_PROBLEM,
      JobStatus.CAREGIVER_REPORTED_PROBLEM,
      JobStatus.COMPLETED,
      JobStatus.ARCHIVED,
    ])
    .where(JobFields[JobFields.date], ">=", fromDate ? new Date(moment(fromDate).format(DATE)) : CGGO_EPOCH)
    .where(
      JobFields[JobFields.date],
      "<=",
      toDate ? new Date(moment(toDate).format(DATE)) : new Date(moment().add(1, "day").format(DATE))
    )
    .orderBy(JobFields[JobFields.date], "desc")
    .limit(JOBS_PER_PAGE);

  useEffect(() => {
    if (!user || !user?.firebaseUser) return;

    setLoading(true);
    query
      .get()
      .then((documents: firebase.firestore.QuerySnapshot) => {
        setJobs(documents.docs);
        setFirstAndLastDocs(documents.docs);
        setLoading(false);
      })
      .catch((error) => handleError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fromDate, toDate]);

  useEffect(() => {
    getCard().then(() => setLoading(false));
  }, [user]);

  const setFirstAndLastDocs = (docs: any) => {
    setFirstDoc(docs[0]);
    setLastDoc(docs[docs.length - 1]);
  };

  const handleError = (error: any) => {
    console.error(error);
    setLoading(false);
    setError(true);
  };

  const nextPage = () => {
    query
      .startAfter(lastDoc)
      .get()
      .then((documents: firebase.firestore.QuerySnapshot) => {
        setCurrentPage(currentPage + 1);
        setJobs(documents.docs);
        setFirstAndLastDocs(documents.docs);
        setLoading(false);
      })
      .catch((error) => handleError(error));
  };

  const prevPage = () => {
    query
      .endBefore(firstDoc)
      .limitToLast(JOBS_PER_PAGE)
      .get()
      .then((documents: firebase.firestore.QuerySnapshot) => {
        setCurrentPage(currentPage - 1);
        setJobs(documents.docs);
        setFirstAndLastDocs(documents.docs);
        setLoading(false);
      })
      .catch((error) => handleError(error));
  };

  const getCard = async () => {
    try {
      const cardsCall = firebaseFunctions.httpsCallable(GET_CARD_FUNCTION);
      const result = await cardsCall();

      if (result && result.data) {
        setCreditCard(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!requireAuth.user)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <MainContentWrapper>
      <ReportProblemModal
        isVisible={isReportModalVisible}
        onConfirm={() => {}}
        onCancel={() => setIsReportModalVisible(false)}
        jobId={jobId}
        readonly={true}
        clientFeedbackOption={feedBackOption}
        clientFeedbackInfo={feedBackInfo}
      />
      <Spacer4 />
      <TabsWrapper>
        <Tab active={false} onClick={() => history.push(PATIENT_PROFILE_ROUTE)}>
          <TabLabel>Profile</TabLabel>
        </Tab>
        <Tab active={true}>
          <TabLabel>Account</TabLabel>
        </Tab>
      </TabsWrapper>
      <Spacer2 />
      <AccountWrapper>
        <Row>
          <TextLinkDisabled size={TextSize.large}>Account History</TextLinkDisabled>
          <Spacer2 />
          <LinkTextSeparator>|</LinkTextSeparator>
          <Spacer2 />
          <TextLink size={TextSize.large} to={PATIENT_ACCOUNT_INFO_ROUTE}>
            Account Info
          </TextLink>
        </Row>
        <Spacer1 />
        <H1 className="text-center">Account History</H1>
        <Spacer3 />
        <SortWrapper>
          <p>Sort by Date Range:</p>
          <Spacer1 />
          <DateWrapper width="auto" margin="0">
            <DatePicker
              selected={fromDate}
                // @ts-ignore
              onChange={(date) => setFromDate(date)}
              customInput={<DatePickerFrom />}
              maxDate={moment().toDate()}
              popperPlacement="bottom"
            />
          </DateWrapper>
          <Spacer1 />
          <p>to</p>
          <Spacer1 />
          <DateWrapper width="auto" margin="0">
            <DatePicker
              selected={toDate}
                // @ts-ignore
              onChange={(date) => setToDate(moment(date).add(1, "day").toDate())}
              customInput={<DatePickerTo />}
              maxDate={moment().toDate()}
              popperPlacement="bottom"
            />
          </DateWrapper>
        </SortWrapper>
        <Spacer3 />
        {error ? (
          <GenericError />
        ) : loading ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : jobs?.length ? (
          <JobsTableWrapper>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Hours</th>
                  <th scope="col">Provider</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {jobs?.map((job: any, index: number) => {
                  const nextJob = fromFirebase(job);
                  let practitioner;
                  nextJob.applicants?.forEach((applicant: JobApplicant) => {
                    if (applicant.confirmed) {
                      practitioner = applicant.displayName;
                    }
                  });

                  return (
                    <React.Fragment  key={index}>
                      <tr>
                        <td>{moment(nextJob.date).format(DATE_HISTORY)}</td>
                        <td>{moment(nextJob.date).format(TIME)}</td>
                        <td>{nextJob.shiftLength} Hours</td>
                        <td>{practitioner}</td>
                        <ActionColumn>
                          {nextJob.status === JobStatus.ARCHIVED && (
                            <ActionWrapper>
                              <Badge variant="dark">Archived</Badge>
                            </ActionWrapper>
                          )}
                          {nextJob.status === JobStatus.CAREGIVER_REPORTED_PROBLEM && (
                            <ActionWrapper>
                              <Badge variant="warning">Problem reported</Badge>
                            </ActionWrapper>
                          )}
                          {nextJob.status === JobStatus.CLIENT_REPORTED_PROBLEM && (
                            <ActionWrapper>
                              <Badge variant="warning">Problem reported</Badge>
                              <Spacer2 />
                              <Button onClick={() => {
                                setJobId(job.id);
                                setFeedBackOption(nextJob.clientFeedbackOption || "");
                                setFeedBackInfo(nextJob.clientFeedbackInfo  || "");
                                setIsReportModalVisible(true);
                              }}>View</Button>
                            </ActionWrapper>
                          )}
                          {nextJob.status === JobStatus.PAID && <Badge variant="success">Paid</Badge>}
                          {nextJob.status === JobStatus.PAYMENT_ATTEMPTS_EXHAUSTED && (
                            <Badge variant="danger">
                              <p className="m-0">Unpaid</p>
                            </Badge>
                          )}
                          {nextJob.status === JobStatus.PAYMENT_ERROR && (
                            <ActionWrapper>
                              <Badge variant="warning">Payment error</Badge>
                              <Spacer2 />
                              {creditCard ? (
                                <Button
                                  onClick={() => {
                                    setSelectedJob(job);
                                    setShowRetryModal(true);
                                  }}
                                >
                                  Retry
                                </Button>
                              ) : (
                                <ThreeDotsLoading>...</ThreeDotsLoading>
                              )}
                            </ActionWrapper>
                          )}
                        </ActionColumn>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            <PaginationWrapper>
              {currentPage !== 0 && <Button onClick={() => prevPage()}>Previous</Button>}
              <Spacer2 />
              {jobs.length === JOBS_PER_PAGE && <Button onClick={() => nextPage()}>Next</Button>}
            </PaginationWrapper>
          </JobsTableWrapper>
        ) : (
          <>
            <Spacer3 />
            <Italic className="d-flex justify-content-center">
              We could not find any jobs within the selected date range.
            </Italic>
          </>
        )}
      </AccountWrapper>
      <CreditCardAndRetryPaymentModal
        isVisible={showRetryModal}
        oldCardId={creditCard?.id}
        onCancel={() => setShowRetryModal(false)}
        onConfirm={async () => {
          if (!selectedJob) return;

          const payOneJobCall = firebaseFunctions.httpsCallable(PAY_JOB_FUNCTION);
          await payOneJobCall({ jobId: selectedJob.id });
          history.go(0);
        }}
        retryPayment={true}
      />
    </MainContentWrapper>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    padding: 10px 20px;
    font-size: ${TextSize.small};
  }
`;

const ThreeDotsLoading = styled.p`
  height: 8px;
  margin: 0;
`;

const ActionColumn = styled.td`
  max-width: 130px;
`;

const ActionWrapper = styled.div`
  display: flex;

  button {
    padding: 0 10px;
    font-size: ${TextSize.small};
  }
`;

const JobsTableWrapper = styled.div`
  th,
  thead th,
  td {
    border: none;
  }

  th {
    font-size: ${TextSize.small};
    padding-bottom: 4px;
  }

  td {
    font-size: ${TextSize.big};
    vertical-align: middle;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: ${theme.colors.wildSand};
  }
`;

const DatePickerFrom = React.forwardRef((value: any, onClick: any) => {
  return (
    <>
      <FormControl
        onClick={value.onClick}
        onChange={() => {}}
        value={value.value}
        placeholder={"Start Date"}
        plaintext={true}
      />
      <FontAwesomeIcon icon={faCalendarAlt} color={theme.colors.silver} />
    </>
  );
});

const DatePickerTo = React.forwardRef((value: any, onClick: any) => {
  return (
    <>
      <FormControl
        onClick={value.onClick}
        onChange={() => {}}
        value={value.value}
        placeholder={"End Date"}
        plaintext={true}
      />
      <FontAwesomeIcon icon={faCalendarAlt} color={theme.colors.silver} />
    </>
  );
});

const SortWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: ${TextSize.big};
    margin: 0;
  }
`;

export const AccountWrapper = styled(Container)`
  background-color: white;
  padding: 32px 50px;
`;

export default PatientAccountHistory;
