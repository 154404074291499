import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CheckboxButton } from "../components/checkbox-form-group";
// eslint-disable-next-line no-unused-vars
import { careNeedsLabelsArray, FormStepProps } from "./patient-add-edit-job";
import { dateFormatUsa } from "../constants/dates";
import { Spacer1, Spacer2, Spacer3, Spacer4 } from "../components/spacers";
import styled from "styled-components";
import { H3 } from "../components/text";
import moment from "moment";
import useScrollTop from "../hooks/use-scroll-to-top";
import { CareNeeds } from "../types/user";
// eslint-disable-next-line no-unused-vars
import { data_key } from "./patient-job";
import { shiftToPrices } from "../constants/prices";

const PatientAddEditJobStep3: React.FC<FormStepProps> = (props) => {
  const { currentJob, allNotes } = props;
  useScrollTop();

  return (
    <>
      <Spacer1 />
      <Row>
        <Col>
          <H3>If everything looks right, we'll post your job!</H3>
        </Col>
      </Row>
      <Spacer3 />
      <ConfirmationRow>
        <Col md="12">
          <Spacer2 />
        </Col>
        <Col md="4">
          <Col md="12">
            <ConfirmationLabel>DATE</ConfirmationLabel>
            <ConfirmationValue>{currentJob?.date && dateFormatUsa.format(currentJob.date)}</ConfirmationValue>
          </Col>
          <Col md="12">
            <Spacer4 />
          </Col>
          <Col md="12">
            <ConfirmationLabel>TIME (PT)</ConfirmationLabel>
            <ConfirmationValue>
              {moment(currentJob?.date).format("hh:mm A")}
              {" - "}
              {moment(currentJob?.date).add(currentJob?.shiftLength, "hour").format("hh:mm A")}
            </ConfirmationValue>
          </Col>
          {currentJob?.isRecurring && (
            <>
              <Col md="12">
                <Spacer4 />
              </Col>
              <Col md="12">
                <ConfirmationLabel>REPEAT</ConfirmationLabel>
                <ConfirmationValue>
                  {currentJob.recurringDays?.map(day => moment().day(day).format("ddd")).join(", ") + " "}
                  (until {moment(currentJob.recurringEndDate).format("MM/DD/YYYY")})
                </ConfirmationValue>
              </Col>
            </>
          )}
          { !currentJob?.isOwner && 
            <Col className="p-0">
              <Col md="12">
                <ConfirmationLabel>Name</ConfirmationLabel>
                <ConfirmationValue>{currentJob?.firstName} {currentJob?.lastName}</ConfirmationValue>
              </Col>
              <Col md="4">
                <ConfirmationLabel>Relationship</ConfirmationLabel>
                <ConfirmationValue>{currentJob?.relationship}</ConfirmationValue>
              </Col>
              <Col md="4">
                <ConfirmationLabel>Age</ConfirmationLabel>
                <ConfirmationValue>{currentJob?.age}</ConfirmationValue>
              </Col>
              <Col md="4">
                <ConfirmationLabel>Gender</ConfirmationLabel>
                <ConfirmationValue>{currentJob?.gender}</ConfirmationValue>
              </Col>
            </Col>
          }
          <Col md="12">
            <Spacer4 />
          </Col>
          <Col md="12">
            <ConfirmationLabel>COST {currentJob?.isRecurring && 'Per Job'}</ConfirmationLabel>
            <ConfirmationValue>${currentJob?.shiftLength && shiftToPrices[currentJob?.shiftLength]}</ConfirmationValue>
          </Col>
        </Col>
        <Col md="8">
          <Col md="12">
            <ConfirmationLabel>TASKS</ConfirmationLabel>
            {currentJob?.careNeeds && (
              <TasksWrapper>
                <Form.Row>
                  <ButtonsRow>
                    <Form.Group as={Col} md="12" className="text-left">
                      {currentJob.careNeeds.map((value, index) => {
                        const label = CareNeeds[value as data_key];
                        return (
                          <CheckboxButton key={`careNeeds-${label}`} type="button" className="confirm selected">
                            {label}
                          </CheckboxButton>
                        );
                      })}
                    </Form.Group>
                  </ButtonsRow>
                </Form.Row>
              </TasksWrapper>
            )}
            {!currentJob?.careNeeds && (
              <>
                <ConfirmationValue>N/A</ConfirmationValue>
                <Spacer4 />
              </>
            )}
          </Col>
          <Spacer2 />
          <Col md="12">
            <ConfirmationLabel>DESCRIPTION</ConfirmationLabel>
            <ConfirmationValue>{allNotes}</ConfirmationValue>
          </Col>
        </Col>
        <Spacer4 />
      </ConfirmationRow>
      <Spacer4 />
    </>
  );
};

const ButtonsRow = styled.div`
  button {
    margin: 0 8px 10px 0;
    cursor: default !important;
  }

  .form-group {
    padding-left: 0;
  }
`;

const ConfirmationRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.wildSand};
  margin: 0 auto;
`;

const ConfirmationLabel = styled.div.attrs((props) => ({
  className: "text-left",
}))`
  font-size: 18px;
  color: ${(props) => props.theme.colors.emperor};
  letter-spacing: 0;
  line-height: 32px;
`;

const ConfirmationValue = styled.div.attrs((props) => ({
  className: "text-left",
}))`
  font-size: 24px;
  color: ${(props) => props.theme.colors.emperor};
  letter-spacing: 0;
  line-height: 36px;
  white-space: pre-line;
`;

const TasksWrapper = styled.div`
  .form-group {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
  }
`;

export { PatientAddEditJobStep3 };
