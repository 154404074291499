import React, { useState } from "react";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Button, BackButton } from "./buttons";
import { Spacer2, Spacer4 } from "./spacers";
import { ModalCardTitle } from "./modal-popup";
import { H3 } from "./text";
import { firebaseAuth } from "../firebase";
import { PageLoading } from "./loading";
import { CloseIcon, LoadingWrapper, ModalCard, ModalCardBackdrop, ModalContentWrapper } from "./modal-parts";
import { FormControl } from "./forms";
import Form from "react-bootstrap/Form";
import PasswordRequirements from "./password-requirements";
import { PASSWORD_REGEX } from "../constants/users";

interface IProps {
  isVisible: boolean;
  onCancel: Function;
}

const PasswordModal: React.FC<IProps> = (props) => {
  const { isVisible, onCancel } = props;
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState<string | null>();

  const savePassword = async () => {
    setError(null);

    if (password === "" || repeatPassword === "") {
      setError("Please fill all the fields");
      return;
    }

    if (password !== repeatPassword) {
      setError("The passwords do not match.");
      return;
    }

    if (!password.match(PASSWORD_REGEX)) {
      setError("The password does not match the requirements.");
      return;
    }

    try {
      setLoading(true);
      let user = firebaseAuth.currentUser;
      await user?.updatePassword(password);
      onCancel();
    } catch (e) {
      setLoading(false);
      console.error(e);
      if (e?.code === "auth/requires-recent-login") {
        setError(
          "This operation is sensitive and requires recent authentication. Log out and Log in again before retrying this request."
        );
      } else {
        setError("There was an error saving your password. Please try again.");
      }
    }
  };

  return isVisible ? (
    <ModalCardBackdrop>
      <ModalCard>
        <ModalCardTitle className="d-flex justify-content-between align-items-center">
          <div style={{ width: "60px" }} />
          <span>Modify password</span>
          <CloseIcon icon={faTimes} color="white" size="1x" onClick={() => onCancel()} />
        </ModalCardTitle>
        <Spacer2 />
        <ModalContentWrapper>
          <H3>Enter your new password:</H3>
          <Spacer2 />
          <FormWrapper>
            <Form.Group controlId="formBasicPassword">
              <FormControl
                type="password"
                placeholder="Password"
                onChange={(event: any) => setPassword(event.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicRepeatPassword">
              <FormControl
                type="password"
                placeholder="Repeat password"
                onChange={(event: any) => setRepeatPassword(event.target.value)}
              />
            </Form.Group>
          </FormWrapper>
          {error && (
            <ErrorWrapper>
              <p className="text-danger text-center">{error}</p>
            </ErrorWrapper>
          )}
          <PasswordRequirements />
        </ModalContentWrapper>
        {loading ? (
          <LoadingWrapper>
            <PageLoading />
          </LoadingWrapper>
        ) : (
          <>
            <Row className="justify-content-md-center">
              <Button onClick={() => savePassword()}>Save</Button>
            </Row>
            <Row className="justify-content-md-center">
              <BackButton onClick={() => onCancel()}>Cancel</BackButton>
            </Row>
          </>
        )}
        <Spacer4 />
      </ModalCard>
    </ModalCardBackdrop>
  ) : (
    <></>
  );
};

const ErrorWrapper = styled.div`
  max-width: 430px;
`;

const FormWrapper = styled.div`
  .form-group {
    width: 350px;
  }
`;

export default PasswordModal;
