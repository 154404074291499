import styled from "styled-components";
import { FlexContainer } from "./layout";
import Form from "react-bootstrap/Form";

export const AuthFormWrapper = styled(FlexContainer)`
  background-color: white;
  width: 560px;
  align-items: center;
  flex-direction: column;
  padding: 80px 80px 0 80px;
`;

export const AuthForm = styled(Form)`
  display: block;
  width: 100%;
`;
