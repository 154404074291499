import React  from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { ModalCardTitle } from "./modal-popup";
import { CloseIcon,  ModalCard, ModalCardBackdrop, } from "./modal-parts";
import styled from "styled-components";
import JobChat from "./chat/job-chat";

interface IProps {
  isVisible: boolean;
  onConfirm: Function;
  onCancel: Function;
  jobId: string | undefined;
}

const AdminMessagesModal: React.FC<IProps> = (props) => {
  const { isVisible, onCancel, jobId } = props;
  return isVisible && jobId ? (
    <ModalCardBackdrop>
      <MessagesModalCard className="p-3">
        <ModalCardTitle className="d-flex justify-content-between align-items-center">
          <div style={{ width: "60px" }} />
          <span>Messages</span>
          <CloseIcon
            icon={faTimes}
            color="white"
            size="1x"
            onClick={() => onCancel()}
          />
        </ModalCardTitle>
          <JobChat jobId={jobId as string}  showBackLink={false}/>
      </MessagesModalCard>
    </ModalCardBackdrop>
  ) : (
    <></>
  );
};

const MessagesModalCard = styled(ModalCard)`
  width: 650px;
  margin-left: -320px;
`;
export default AdminMessagesModal;

