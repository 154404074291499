export const dateFormatUsa = new Intl.DateTimeFormat("en", {
  month: "long",
  day: "numeric",
  weekday: "long",
});

export const DAY = "dddd, MMMM D";
export const TIME = "hh:mma";
export const DATE = "YYYY/MM/DD";
export const DATE_HISTORY = "MM/DD/YYYY";
