import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faUserCircle, faPencilAlt, faComment } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { theme } from "./theme";
import { faArrowLeft, faPaperPlane } from "@fortawesome/pro-solid-svg-icons";

export const CalendarIcon = <FontAwesomeIcon icon={faCalendarAlt} color={theme.colors.jellyBean} size="3x" />;
export const AvatarIcon = <FontAwesomeIcon icon={faUserCircle} color={theme.colors.jellyBean} size="3x" />;
export const PencilIcon = <FontAwesomeIcon icon={faPencilAlt} color={theme.colors.jellyBean} size="3x" />;
export const CommentIcon: React.FC = () => <FontAwesomeIcon icon={faComment} color="#FFF" size="1x" />;
export const PaperIcon: React.FC = () => <FontAwesomeIcon icon={faPaperPlane} color="#FFF" size="1x" />;
export const ArrowLeftIcon: React.FC = () => (
  <FontAwesomeIcon icon={faArrowLeft} color={theme.colors.emperor} size="1x" />
);

// // TODO use proper spacing
// export const FontAwesomeIconInside = styled(FontAwesomeIcon)`
//
//   * svg {
//     height: 30px !important;
//   }
// `;
